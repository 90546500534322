const initialState = {
    orderCreation: [],
    isAddingOrderCreation: false,
    isFetchingOrderCreation: false,
    orderCreationList: [],
  };
  
  const OrderCreationReducer = (state = initialState, action) => {
    switch (action.type) {
  
      case "REQUEST_ADD_ORDER_CREATION":
        return { ...state, isAddingOrderCreation: true };
      case "SUCCESS_ADD_ORDER_CREATION":
        return { ...state, orderCreation: action.payload, isAddingOrderCreation: false };
      case "ERROR_ADD_ORDER_CREATION":
        return { ...state, isAddingOrderCreation: false };
  
      case "REQUEST_GET_ORDER_CREATION":
        return { ...state, isFetchingOrderCreation: true };
      case "SUCCESS_GET_ORDER_CREATION":
        return {
          ...state,
          orderCreationList: action.payload.data,
          isFetchingOrderCreation: false,
        };
      case "ERROR_GET_ORDER_CREATION":
        return { ...state, isFetchingOrderCreation: false };
      default:
        return state;
    }
  };
  export default OrderCreationReducer;
  