import API from "../../../../../global/api";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import jsPDF from "jspdf";
import moment from "moment";
import _ from "lodash";


export const requestAddPurchase = () => {
  return {
    type: "REQUEST_ADD_PURCHASE",
  };
};
export const successAddPurchase = (data) => {
  return {
    type: "SUCCESS_ADD_PURCHASE",
    payload: data,
  };
};
export const errorAddPurchase = () => {
  return {
    type: "ERROR_ADD_PURCHASE",
  };
};
export const addPurchaseApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPurchase());
    API.post(`/purchase/`, data).then((res) => {
      // dispatch(getPurchaseApi());
      dispatch(successAddPurchase(data));
      dispatch(getHybridAndCompleteData())
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddPurchase());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestGetPurchase = () => {
  return {
    type: "REQUEST_GET_PURCHASE",
  };
};
export const successGetPurchase = (data) => {
  return {
    type: "SUCCESS_GET_PURCHASE",
    payload: data,
  };
};
export const errorGetPurchase = () => {
  return {
    type: "ERROR_GET_PURCHASE",
  };
};
export const getPurchaseApi = () => {
  return (dispatch) => {
    dispatch(requestGetPurchase());
    API.get(`/purchase/`).then((res) => {
      let getData = res.data;
      dispatch(successGetPurchase(getData));
    }).catch((error) => {
      dispatch(errorGetPurchase());
    });
  };
};


export const DeleteRequestPurchase = () => {
  return {
    type: "DELETE_REQUEST_PURCHASE",
  };
};
export const DeleteSuccessPurchase = (id) => {
  return {
    type: "DELETE_SUCCESS_PURCHASE",
    _id: id,
  };
};
export const DeleteErrorPurchase = () => {
  return {
    type: "DELETE_ERROR_PURCHASE",
  };
};
export const deletePurchaseApi = (id) => {
  return dispatch => {
    dispatch(DeleteRequestPurchase());
    API.delete(`/purchase/${id}`).then(res => {
      dispatch(getPurchaseApi());
      dispatch(DeleteSuccessPurchase(id))
    }).catch((error) => {
      dispatch(DeleteErrorPurchase())
    });
  }
};


export const UpdateRequestPurchase = () => {
  return {
    type: "UPDATE_REQUEST_PURCHASE",
  };
};
export const UpdateSuccessPurchase = (data) => {
  return {
    type: "UPDATE_SUCCESS_PURCHASE",
    payload: data,
  };
};
export const UpdateErrorPurchase = () => {
  return {
    type: "UPDATE_ERROR_PURCHASE",
  };
};
export const updatePurchaseApi = (data, oldData) => {
  return dispatch => {
    dispatch(UpdateRequestPurchase());
    API.put(`/purchase/${oldData._id}`, {
      manualPurchaseNumber: data.manualPurchaseNumber,
      purchaseDate: data.purchaseDate,
      purchaserName: data.purchaserName,
      suplierName: data.suplierName,
      description: data.desc,
    }, {
    }).then(res => {
      dispatch(getPurchaseApi());
      dispatch(UpdateSuccessPurchase([data]))
    }).catch((error) =>
      dispatch(UpdateErrorPurchase()));
  }
};


export const requestGetPurchaseWithId = () => {
  return {
    type: "REQUEST_GET_PURCHASE_WITH_ID",
  };
};
export const successGetPurchaseWithId = (data) => {
  return {
    type: "SUCCESS_GET_PURCHASE_WITH_ID",
    payload: data,
  };
};
export const errorGetPurchaseWithId = () => {
  return {
    type: "ERROR_GET_PURCHASE_WITH_ID",
  };
};
export const getPurchaseWithIdApi = (id) => {
  return (dispatch) => {
    dispatch(requestGetPurchaseWithId());
    API.get(`/purchase/${id}`).then((res) => {
      let getData = res.data;
      dispatch(successGetPurchaseWithId(getData));
    }).catch((error) => {
      dispatch(errorGetPurchaseWithId());
    });
  };
};


export const requestUpdatePurchaseStatus = () => {
  return {
    type: "REQUEST_UPDATE_PURCHASE_STATUS",
  };
};
export const successUpdatePurchaseStatus = (purchaseId) => {
  return {
    type: "SUCCESS_UPDATE_PURCHASE_STATUS",
    payload: purchaseId,
  };
};
export const errorUpdatePurchaseStatus = () => {
  return {
    type: "ERROR_UPDATE_PURCHASE_STATUS",
  };
};
export const updatePurchaseStatusApi = (purchaseId) => {
  return (dispatch) => {
    dispatch(requestUpdatePurchaseStatus());
    API.post(`/purchase/update_purchase_status/${purchaseId}`).then((res) => {
      dispatch(getPurchaseApi());
      dispatch(successUpdatePurchaseStatus(purchaseId));
    }).catch((error) => {
      dispatch(errorUpdatePurchaseStatus());
    });
  };
};


export const individualPDF = (data) => {
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "Purchase Order Report";
    const companyName = `${data.retaurantId.restaurantName}`;
    const companyAddress = `${data.retaurantId.restaurantLocation}`;
    const companyNtn = `${data.retaurantId.ntn}`;

    const headers = [
      [
        "Serial No",
        "Product Name",
        "PO Demanded Quantity",
      ],
    ];
    const data1 = data.data.map((elt, index) => [
      index + 1,
      elt.inventoryLabel,
      elt.demandedQty,
    ]);
    let content = {
      startY: 250,
      startX: 10,
      head: headers,
      tableWidth: "wrap",
      body: data1,
    };
    // doc.addImage(logo, "PNG", 40, 15, 100, 100);
    // doc.addImage(logo2, "PNG", 440, 90, 120, 120);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(13);
    doc.setFont("times", "normal");
    doc.text(companyAddress, 150, 60, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(14);
    doc.setFont("times", "bold");
    doc.text(title, 240, 120);
    doc.setFont("times", "normal");
    doc.text(`Manual PO Number: ${data.manualPurchaseNumber}`, 50, 150, 0, 20);
    doc.text(`Purchase Order Date: ${data.purchaseDate}`, 50, 170, 0, 20);
    doc.text(`Purchaser Name: ${data.purchaserName}`, 50, 190, 0, 20);
    doc.text(`Suplier Name: ${data.suplierName}`, 50, 210, 0, 20);
    doc.text(`Purchase Order Status: ${data.status}`, 50, 230, 0, 20);
    doc.autoTable(content);
    // let finalY = doc.lastAutoTable.finalY;
    // doc.text(grandTotal, 420, finalY + 30);
    doc.setProperties({ title: `PurchaseOrder Report ${moment(new Date()).format("YYYY-MM-DD")}` });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    // doc.save(`Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
  };
};