const initialState = {
  hallList: [],
  hallDataList: [],
  isFetchingHall: false,
  isAddingHall: false,
};

const hallReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_HALL_GET_DATA":
      return { ...state, isFetchingHall: true, };
    case "SUCCESS_HALL_GET_DATA":
      return {
        ...state,
        hallDataList: action.payload.data,

        isFetchingHall: false,
      };
    case "ERROR_HALL_GET_DATA":
      return { ...state, };


    //addhall
    case "REQUEST_ADD_HALL":
      return { ...state, isAddingHall: true };
    case "SUCCESS_ADD_HALL":
      return {
        ...state,
        hallList: action.payload,
        isAddingHall: false,
      };
    case "ERROR_ADD_HALL":
      return { ...state, isAddingHall: false };

    default:
      return state;
  }
};
export default hallReducer;