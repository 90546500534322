import _ from "lodash";
const initialState = {
  categoryList: [],
  category: [],
  isAddingCategory: false,
  dataAddedCategory: false,
  dataNotAddedCategory: false,
  isFetchingcategoryList: false,
  isAddingCategoryImage: false,
  categoryImageURL: [],
  categoryListByWarehouseId: [],
  isFetchingCategoryByWarehouseId: false,
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_CATEGORY":
      return { ...state, isAddingCategory: true, dataAddedCategory: false, dataNotAddedCategory: false, };
    case "SUCCESS_ADD_CATEGORY":
      return {
        ...state,
        category: action.payload,
        isAddingCategory: false,
        dataAddedCategory: true,
        dataNotAddedCategory: false,
      };
    case "ERROR_ADD_CATEGORY":
      return { ...state, isAddingCategory: false, dataAddedCategory: false, dataNotAddedCategory: true };


    case "REQUEST_GET_ALL_CATEGORY_DATA":
      return { ...state, isFetchingcategoryList: true, dataNotAddedCategory: false, };
    case "SUCCESS_GET_ALL_CATEGORY_DATA":
      return {
        ...state,
        categoryList: action.payload,
        isFetchingcategoryList: false,
        dataNotAddedCategory: false,
      };
    case "ERROR_GET_ALL_CATEGORY_DATA":
      return { ...state, isFetchingcategoryList: false, };


    case "DELETE_REQUEST_USERS":
      return { ...state };
    case "DELETE_SUCCESS_USERS":
      return { ...state, categoryList: state.categoryList.filter(item => item.categoryId !== action.categoryId) };
    case "DELETE_ERROR_USERS":
      return { ...state };


    case "REQUEST_CATEGORY_BY_WAREHOUSE_ID":
      return { ...state, isFetchingCategoryByWarehouseId: true, };
    case "SUCCESS_CATEGORY_BY_WAREHOUSE_ID":
      return {
        ...state,
        categoryListByWarehouseId: action.payload,
        isFetchingCategoryByWarehouseId: false,
      };
    case "ERROR_CATEGORY_BY_WAREHOUSE_ID":
      return { ...state, isFetchingCategoryByWarehouseId: false };


    case "UPDATE_REQUEST_USERS":
      return { ...state };
    case "UPDATE_SUCCESS_USERS":
      return { ...state, category: state.category.map(x => action.payload.find(({ categoryId }) => categoryId === x.categoryId) || x) };
    case "UPDATE_ERROR_USERS":
      return { ...state };


    case "REQUEST_ADD_CATEGORY_IMAGE":
      return { ...state, isAddingCategoryImage: true };
    case "SUCCESS_ADD_CATEGORY_IMAGE":
      return {
        ...state,
        categoryImageURL: action.payload,
        isAddingCategoryImage: false,
      };
    case "ERROR_ADD_CATEGORY_IMAGE":
      return { ...state, isAddingCategoryImage: false };
    case "RESET_CATEGORY_URL":
      return { ...state, categoryImageURL: [] }
    default:
      return state;
  }
};
export default CategoryReducer;