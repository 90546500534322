import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, } from "react-bootstrap";
import Select from "react-select";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import { addHall } from "./Hall.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { setHeading } from "../../../accounts/account.actions";
import { getUserRes } from "../../AddUser/user.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddHall = (props) => {
  const [hall, setHall] = useState("");
  const [hallDesc, setHallDesc] = useState("");
  const [hallLocation, setHallLocation] = useState(0);
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [waiterId, setWaiterId] = useState("");
  const [waiterName, setWaiterName] = useState("");

  function uploadHall() {
    props.addHall(
      {
        hallName: hall,
        hallDesc: hallDesc,
        restaurant: restaurantId,
        supervisorId: waiterId
      },
      handleVisible,
      handleDangerVisible
    );
    setHallDesc("");
    setHall("");
    setRestaurantId("");
    setWaiterId("")
  }
  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRestaurantList = !isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  const WaiterList = !isEmpty(filterdUserList) && filterdUserList.map((x) => {
    let data = { value: x._id, label: x.userName };
    return data;
  });
  const handleChangeWaiter = (selectedOption) => {
    setWaiterName(selectedOption.label);
    setWaiterId(selectedOption.value);
  };

  const filterdUserList = !isEmpty(props.userList) && props.userList.filter(e => e.role == "supervisor")


  useEffect(() => {
    props.setHeading("Add Hall");
    props.getUser()
    props.getRestaurantData();
  }, []);

  return (
    <>
      {props.isAddingHall || props.isFetchingRestaurant || props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Restaurant *</b>
                      </Form.Label>
                      <Select
                        isDisabled={isEmpty(props.restaurantDataList)}
                        placeholder="Select Restaurant"
                        onChange={handleChangeRestaurant}
                        options={getRestaurantList}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b> Select Supervisor *</b>
                      </Form.Label>
                      <Select
                        value={{ label: waiterName }}
                        // isDisabled={isEmpty(props.seatSelected._id) ? false : true }
                        isDisabled={isEmpty(WaiterList)}
                        placeholder="Select Supervisor"
                        onChange={(e) => handleChangeWaiter(e)}
                        options={WaiterList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Hall Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Hall Name"
                        onChange={(e) => setHall(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Hall Description </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={hallDesc}
                        placeholder="Hall Description"
                        onChange={(e) => setHallDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {isEmpty(hall) || isEmpty(restaurantId) || isEmpty(waiterId) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadHall}>SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addHall: (data, handleVisible, handleDangerVisible) =>
      dispatch(addHall(data, handleVisible, handleDangerVisible)),
    getRestaurantData: () => dispatch(getRestaurantData()),
    setHeading: (data) => dispatch(setHeading(data)),
    getUser: () => dispatch(getUserRes()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
  hallList: state.hallReducer.hallList,
  isAddingHall: state.hallReducer.isAddingHall,
  restaurantList: state.ResturantReducer.restaurantList,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddHall);
