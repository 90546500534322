import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { addCoupons } from "./Coupons.action";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddCoupons = (props) => {
  const [Coupons, setCouponsName] = useState("");
  const [couponsCode, setCouponsCode] = useState();
  const [couponsType, setCouponsType] = useState();
  const [couponsAmount, setCouponsAmount] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [CouponTypeName, setCouponTypeName] = useState();

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadCoupons() {
    props.addCoupons(
      {
        code: couponsCode,
        name: Coupons,
        type: CouponTypeName,
        amount: couponsAmount,
        expiredAt: "2023-03-01T23:59:59.0000000Z",
      },
      handleVisible,
      handleDangerVisible
    );
  }
  let CouponTypeData = [{
    CouponType_id: 1,
    CouponType_name: "fixed",
  },
  {
    CouponType_id: 2,
    CouponType_name: "percentage",
  }];

  const handleChangeCouponType = (selectedOption) => {
    setCouponTypeName(selectedOption.label);
  };
  const CouponTypeList = !_.isEmpty(CouponTypeData) && CouponTypeData.map((x) => {
    let data = { value: x.CouponType_id, label: x.CouponType_name };
    return data;
  });

  useEffect(() => {
    props.setHeading("Add Coupon");
  }, []);

  return (
    <>
      {props.isAddingCoupons ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Coupon Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupons Name"
                    onChange={(e) => { setCouponsName(e.target.value); }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Coupon Code *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Coupon Code"
                    onChange={(e) => { setCouponsCode(e.target.value); }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12" md="12" xs="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Coupon Type *</b>
                  </Form.Label>
                  <Select
                    isDisabled={_.isEmpty(CouponTypeData)}
                    placeholder="Coupon Type"
                    onChange={handleChangeCouponType}
                    options={CouponTypeList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3 mt-3">
                  <Form.Label>
                    <b>Coupon Amount *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    max={CouponTypeName == "percentage" ? 100 : 99999999999999}
                    value={couponsAmount}
                    placeholder="Coupon Amount"
                    onChange={(e) => { setCouponsAmount(e.target.value); }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {_.isEmpty(Coupons) || _.isEmpty(CouponTypeName) || _.isEmpty(couponsCode) || _.isEmpty(couponsAmount) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadCoupons}> SAVE </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addCoupons: (data, handleVisible, handleDangerVisible) =>
      dispatch(addCoupons(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  couponsList: state.CouponReducer.couponsList,
  isAddingCoupons: state.CouponReducer.isAddingCoupons,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddCoupons);
