import React, { useEffect, useState, forwardRef } from "react";
import { Row, Col, Form, Container, Button, Modal, Alert } from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, isNull, isUndefined } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { getSubCategoryApi, deleteSubCategoryApi, updateSubCategoryApi } from "./SubCategory.action";
import { setHeading } from "../../../accounts/account.actions";
import { baseURL, headers } from "../../../../../global/api";
const tableRef = React.createRef()


const SubCategoryTableList = (props) => {
    const permissions = JSON.parse(window.localStorage.getItem("permissions"))
    const Checkpermissions = (permissionsType, permissionsName) => {
        return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
    }

    useEffect(() => {
        // props.getCategoryData();
        props.setHeading("Sub Category Details");
    }, []);

    return (
        <>
            {props.isFetchingSubCategory ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            tableRef={tableRef}
                            title="Sub Category Details"
                            columns={[
                                { title: 'WareHouse Name', field: 'retaurantId.restaurantName', editable: 'never' },
                                { title: 'Category Name', field: 'categoryId.name', editable: 'never' },
                                { title: 'Sub Category Name', field: 'subCategoryName' },
                            ]}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/sub_category?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: 'GET',
                                        headers: headers,
                                    }).then((response) => response.json())
                                        .then((result) => {
                                            resolve({
                                                data: result.data,
                                                page: result.page,
                                                totalCount: result.total_results,
                                            });
                                        });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                // filtering: true,
                                exportButton: true,
                                paging: true,
                                pageSize: 50,       // make initial page size
                                emptyRowsWhenPaging: false,   // To avoid of having empty rows
                                pageSizeOptions: [30, 50, 100, 150, 200],    // rows selection options
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            editable={{
                                isEditHidden: rowData => Checkpermissions("permissionsSubCatName", "Sub Category Edit"),
                                isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName", "Sub Category Delete"),
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                props.updateSubCategoryApi(newData, oldData)
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteSubCategoryApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                {
                                    icon: 'refresh',
                                    tooltip: 'Refresh Data',
                                    isFreeAction: true,
                                    onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                                }
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSubCategoryApi: () => dispatch(getSubCategoryApi()),
        deleteSubCategoryApi: (_Id) => dispatch(deleteSubCategoryApi(_Id)),
        updateSubCategoryApi: (newData, oldData) => dispatch(updateSubCategoryApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    subCategoryList: state.SubCategoryReducer.subCategoryList,
    isFetchingSubCategory: state.SubCategoryReducer.isFetchingSubCategory,
});

export default connect(mapStateToProps, mapDispatchToProps)(SubCategoryTableList);