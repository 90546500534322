import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import { setHeading } from "../../../accounts/account.actions";
import { addPurchaseApi } from "./PurchaseOrder.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddPurchaseOrder = (props) => {
  const [inputField, setInputField] = useState([{
    inventoryId: "",
    availableQuantity: 0,
    demandedQty: 0,
    remainingIgp: 0,
    stockPrice: 0,
  }]);

  const [manualPurchaseNumber, setManualPurchaseNumber] = useState();
  const [purchaseDate, setPurchaseDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [purchaserName, setPurchaserName] = useState();
  const [suplierName, setSuplierName] = useState("");
  const [desc, setDesc] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [retaurantId, setRestaurantId] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (msg) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: msg,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.addPurchaseApi({
      manualPurchaseNumber: manualPurchaseNumber,
      purchaseDate: purchaseDate,
      purchaserName: purchaserName,
      suplierName: suplierName,
      description: desc,
      data: inputField,
      retaurantId: retaurantId,
      // wareHouseId: wareHouseId,
    },
      handleVisible,
      handleDangerVisible
    );
    setManualPurchaseNumber("");
    setPurchaseDate("");
    setPurchaserName("");
    setSuplierName("");
    setDesc("");
    setRestaurantName("")
    setInputField([{
      inventoryId: "",
      availableQuantity: 0,
      demandedQty: 0,
      stockPrice: 0,
    }]);
  }

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == item);
    // if (Number(e.target.value) >= Number(z.productBalance)) {
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["demandedQty"] = Number(z.productBalance);
    if (type == "intname") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["demandedQty"] = Number(e.target.value);
    if (type == "intquantity") list[index]["remainingIgp"] = Number(e.target.value);
    setInputField(list);
    // } else {
    //   alert("value out of range");
    // }
  };
  const handleChange = (e, index, type) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["availableQuantity"] = Number(z.productBalance);
    if (type == "intname") list[index]["stockPrice"] = Number(z.purchasePrice);
    if (type == "intname") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["demandedQty"] = Number(e.target.value);
    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", availableQuantity: 0, demandedQty: 0, stockPrice: 0 },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const getHybridAndCompleteData = props.hybridAndCompleteData.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });

  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRetaurantList = !isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  useEffect(() => {
    props.getHybridAndCompleteData();
    props.getRestaurantData();
    props.setHeading("Add PurchaseOrder ");
  }, []);

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isAddingPurchase ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingPurchase ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Company *</b>
                  </Form.Label>
                  <Select
                    value={{ label: restaurantName }}
                    placeholder="Select Company"
                    onChange={handleChangeRestaurant}
                    options={getRetaurantList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="Date"
                    value={purchaseDate}
                    onChange={(e) => setPurchaseDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Purchaser Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Purchaser Name"
                    name="Purchaser Name"
                    onBlur={(e) => setPurchaserName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Manual Purchase Number </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Manual Purchase Number"
                    name="Manual Purchase Number"
                    onBlur={(e) => setManualPurchaseNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Suplier Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Suplier Name"
                    name="Suplier Name"
                    value={suplierName}
                    onChange={(e) => setSuplierName(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="Description"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>


            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="garana">
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Product"
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={getHybridAndCompleteData}
                          // isDisabled={isEmpty(inventory) || wareHouseId === ""}
                          isOptionDisabled={(option) => !isEmpty(
                            find(
                              inputField,
                              (x) => x.inventoryId == option.value
                            )
                          )}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group>
                        <Form.Label>
                          <b>Available Quantity </b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Available Quantity"
                          name="availableQuantity"
                          disabled
                          value={item.availableQuantity}
                        />
                      </Form.Group>
                    </Col>

                    {/* <Col xl="2" lg="2" md="2" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Stock Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Stock Price"
                          name="Stock Price"
                          disabled
                          value={item.stockPrice}
                        />
                      </Form.Group>
                    </Col> */}

                    <Col>
                      <Form.Group>
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity"
                          name="demandedQty"
                          min={0}
                          value={item.demandedQty}
                          onChange={(e) => handleChangeDemand(e, i, "intquantity", item.inventoryId)}
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <Button
                            title="Click to Add PO"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              // color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField.length !== 1 && (
                          <Button
                            title="Click to Remove PO"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              // color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <div className="sendDiv">
              {isEmpty(purchaseDate) || isEmpty(purchaserName) || isEmpty(suplierName) || isEmpty(retaurantId) || inputField[0].inventoryId == "" || inputField[0].demandedQty == 0 ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadPurchase}> SAVE </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHybridAndCompleteData: () => dispatch(getHybridAndCompleteData()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    setHeading: (data) => dispatch(setHeading(data)),
    addPurchaseApi: (data, handleVisible, handleDangerVisible) => dispatch(addPurchaseApi(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddPurchaseOrder);
