import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { deleteTable, getTableData, updateTable } from "./Table.action";
import { setHeading } from "../../../accounts/account.actions";


const ViewTable = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
  }
  useEffect(() => {
    props.setHeading("Table List");
  }, []);

  return (
    <>
      {props.isFetchingTableData ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="Table Details"
              columns={[
                // { title: 'Table Id', field: 'table_id', editable: 'never' },
                { title: "Table Name", field: "tableName" },
                { title: "Table Capacity", field: "tableSittingCapacity" },
                { title: "Table Description", field: "tableDesc" },
                { title: "Waiter Name", field: "waiterId.userName", editable: 'never' },
                { title: "Hall Name", field: "hall.hallName", editable: 'never' },
                { title: "Restaurant Name", field: "restaurant.restaurantName", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/table/table_list?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,

                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [30, 50, 100, 150, 200],
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isEditHidden: rowData => Checkpermissions("permissionsSubCatName", "Table Edit"),
                isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName", "Table Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateTable(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteTable(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTableData: () => dispatch(getTableData()),
    deleteTable: (tableId) => dispatch(deleteTable(tableId)),
    updateTable: (newData, oldData) => dispatch(updateTable(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  tableDataList: state.TableReducer.tableDataList,
  isFetchingTable: state.TableReducer.isFetchingTable,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewTable);
