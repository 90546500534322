import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { deleteProduct, getProductData, updateProduct, getAllProductPDFApi, updateProductStatusApi } from "./Product.action";
import { setHeading } from "../../../accounts/account.actions";
import _ from 'lodash'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import BlockIcon from '@mui/icons-material/Block';
import Button from '@mui/material/Button';
import jsPDF from "jspdf";
import Swal from "sweetalert";
// import logo from "../../../../../Images/logo.png"
import moment from "moment";
const tableRef = React.createRef()



const ViewProduct = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
  }
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const exportPDF = () => {
    props.getAllProductPDFApi(
      handleDangerVisible);
  };


  useEffect(() => {
    props.getProductData()
    props.setHeading("Product Details");
  }, []);

  return (
    <>
      {props.isFetchingProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Button style={{ marginBottom: '30px', textTransform: 'none', fontSize: '17px' }} variant="contained" endIcon={<SimCardDownloadIcon />} size="large" onClick={() => exportPDF()}> Download Product Report </Button>
            <MaterialTable
              tableRef={tableRef}
              title="Product Details"
              columns={[
                { title: 'Image', field: 'images', editable: 'never', render: rowData => <img src={`${baseURL}/assets/${rowData.images}`} style={{ width: 50, borderRadius: '50%' }} /> },
                { title: 'Product Code', field: 'code' },
                { title: "Barcode", field: "barcodeId" },
                {
                  title: "Product Name", field: "name",
                  render: rowData =>
                    <div style={{
                      backgroundColor: rowData.productTypeName == "Service Product" ? "transparent" : Number(rowData.productBalance) <= Number(rowData.minimumBalance) ? '#ff8d8d' : Number(rowData.productBalance) >= Number(rowData.maximumBalance) ? '#addfac' : 'transparent'
                    }}> {rowData.name}</div>
                },
                // { title: "Product Discount", field: "discount" },
                { title: "Opening Balance", field: "openingBalance", editable: 'never' },
                { title: "Closing Balance", field: "productBalance", editable: 'never' },
                { title: "Description", field: "desc" },
                // { title: "product Type Name", field: "productTypeName", editable: 'never' },
                { title: "Purchase Price", field: "purchasePrice" },
                { title: "Sale Price", field: "salePrice" },
                // { title: "Product Red Color Time", field: "redColorTime" },
                // { title: "Product Yellow Color Time", field: "yellowColorTime" },
                // { title: "Product Green Color Time", field: "greenColorTime" },
                { title: "Company", field: "retaurantId.restaurantName", editable: 'never' },
                { title: "Business Location", field: "wareHouseId.businessName", editable: 'never' },
                { title: "Category", field: "category.name", editable: 'never' },
                { title: "Sub Category", field: "subCategoryId.subCategoryName", editable: 'never' },
                { title: "Status", field: "status" },
                { title: "Date", field: "productDate", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/products?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      resImage: result.resImage,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              detailPanel={rowData => {
                return (
                  <MaterialTable
                    columns={[
                      { title: 'Color', field: 'colourLabel' },
                      { title: 'Size', field: 'sizeLabel' },
                      { title: 'Sale Price', field: 'sellingPrice' },
                      { title: 'Purchase Price', field: 'purchasePrice' },
                    ]}
                    data={rowData.variation}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: false,
                      sorting: false,
                      paging: false,
                      pageSize: 50,
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: 'sticky', top: 0,
                        color: '#00BBBB',
                        fontWeight: '550',
                        onRowAdd: 'none',
                      }
                    }}
                  />
                )
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],

                exportPdf: (columns, data, data12) => {
                  const doc = new jsPDF({ orientation: 'l', });
                  const columnTitles = [
                    { title: 'Product Code' },
                    { title: "Product Name" },
                    { title: "Opening Balance" },
                    { title: "Closing Balance" },
                    { title: "Description" },
                    { title: "Purchase Price", },
                    { title: "Total Purchase Price" },
                    { title: "Sale Price" },
                    { title: "Total Sale Price" },
                    { title: "Company" },
                    { title: "Business Location" },
                    { title: "Status" },
                    { title: "Date" },
                  ];
                  const pdfData = data.map((columnDef) => [
                    columnDef.code,
                    columnDef.name,
                    columnDef.openingBalance,
                    columnDef.productBalance,
                    columnDef.desc,
                    columnDef.purchasePrice,
                    columnDef.purchasePrice * columnDef.productBalance,
                    columnDef.salePrice,
                    columnDef.salePrice * columnDef.productBalance,
                    columnDef.retaurantId.restaurantName,
                    columnDef.wareHouseId.businessName,
                    columnDef.status == true ? 'Active' : 'Non-Active',
                    columnDef.productDate,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                  const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                  const companyNTN = `STRN#${process.env.REACT_APP_COMPANY_NTN}`;
                  // doc.addImage(logo, "PNG", 10, 11, 30, 30);
                  doc.setFont("Times New Roman", "bolditalic");
                  doc.text(companyName, 20, 15, 0, 20);
                  doc.setFontSize(11);
                  doc.setFont("Times New Roman", "normal");
                  doc.text(companyAddress, 20, 20, 0, 20);
                  doc.text(companyNTN, 20, 25, 0, 20);
                  doc.setFontSize(14);
                  doc.setFont("Times New Roman", "normal");
                  doc.text("Product Report", 20, 40, 0, 20);
                  doc.autoTable(content);
                  doc.setFontSize(12);
                  let finalY = doc.lastAutoTable.finalY;
                  doc.rect(13, finalY + 10, 270, 13);
                  doc.text(`Total`, 16, finalY + 18, 0, 20);
                  doc.text(`${_.sumBy(pdfData, "2")}`, 53, finalY + 18);
                  doc.text(`${_.sumBy(pdfData, "3")}`, 73, finalY + 18);
                  doc.text(`${_.sumBy(pdfData, "5")}`, 120, finalY + 18);
                  doc.text(`${_.sumBy(pdfData, "6")}`, 140, finalY + 18);
                  doc.text(`${_.sumBy(pdfData, "7")}`, 165, finalY + 18);
                  doc.text(`${_.sumBy(pdfData, "8")}`, 180, finalY + 18);
                  // doc.save(`Store Product.pdf`);
                  doc.setProperties({ title: `Product Report_${moment().format("YYYY-MM-DD")}` });
                  var blob = doc.output("blob");
                  window.open(URL.createObjectURL(blob));
                },
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                isEditHidden: rowData => Checkpermissions("permissionsSubCatName", "Product Edit"),
                isDeleteHidden: rowData => Checkpermissions("permissionsSubCatName", "Product Delete"),
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateProduct(newData, oldData);
                      }
                    }, 600);
                  }),
                isDeletable: false,
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteProduct(oldData._id);
                    }, 600);
                  }),
              }}
              actions={[
                {
                  icon: 'refresh',
                  tooltip: 'Refresh Data',
                  isFreeAction: true,
                  onClick: () => tableRef.current && tableRef.current.onQueryChange(),
                },
                (rowData) => ({
                  disabled: rowData.status == false ? false : true,
                  icon: () => <TaskAltIcon />,
                  tooltip: "Active Product",
                  onClick: (event, rowData) => {
                    const status = true
                    props.updateProductStatusApi(status, rowData._id);
                  },
                }),
                (rowData) => ({
                  disabled: rowData.status == true ? false : true,
                  icon: () => <BlockIcon />,
                  tooltip: "Block Product",
                  onClick: (event, rowData) => {
                    const status = false
                    props.updateProductStatusApi(status, rowData._id);
                  },
                }),
              ]}
            />
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getProductData: () => dispatch(getProductData()),
    deleteProduct: (productId) => dispatch(deleteProduct(productId)),
    updateProduct: (newData, oldData) => dispatch(updateProduct(newData, oldData)),
    getAllProductPDFApi: (handleDangerVisible) => dispatch(getAllProductPDFApi(handleDangerVisible)),
    updateProductStatusApi: (newData, oldData) => dispatch(updateProductStatusApi(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productDataList: state.ProductReducer.productDataList,
  isFetchingProduct: state.ProductReducer.isFetchingProduct,
});


export default connect(mapStateToProps, mapDispatchToProps)(ViewProduct);