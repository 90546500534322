import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  flavorsList: [],
  flavorsDataList: [],
  isFetchingFlavors: false,
  isAddingFlavors: false,
};

const ColourReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_FLAVORS_GET_DATA":
      return { ...state, isFetchingFlavors: true };
    case "SUCCESS_FLAVORS_GET_DATA":
      return {
        ...state,
        flavorsDataList: action.payload.data,
        isFetchingFlavors: false,
      };
    case "ERROR_FLAVORS_GET_DATA":
      return { ...state };
    //addflavors
    case "REQUEST_ADD_FLAVORS":
      return { ...state, isAddingFlavors: true };
    case "SUCCESS_ADD_FLAVORS":
      return {
        ...state,
        flavorsList: action.payload,
        isAddingFlavors: false,
      };
    case "ERROR_ADD_FLAVORS":
      return { ...state, isAddingFlavors: false };
    default:
      return state;
  }
};
export default ColourReducer;
