import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  expenseTypeList: [],
  expenseTypeDataList: [],
  isFetchingExpenseType: false,
  isAddingExpenseType: false,
};

const ExpenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_EXPENSE_TYPE_GET_DATA":
      return { ...state, isFetchingExpenseType: true, };
    case "SUCCESS_EXPENSE_TYPE_GET_DATA":
      return {
        ...state,
        expenseTypeDataList: action.payload.data,
        isFetchingExpenseType: false,
      };
    case "ERROR_EXPENSE_TYPE_GET_DATA":
      return { ...state, };


    case "REQUEST_ADD_EXPENSE_TYPE":
      return { ...state, isAddingExpenseType: true };
    case "SUCCESS_ADD_EXPENSE_TYPE":
      return {
        ...state,
        expenseTypeList: action.payload,
        isAddingExpenseType: false,
      };
    case "ERROR_ADD_EXPENSE_TYPE":
      return { ...state, isAddingExpenseType: false };

    default:
      return state;
  }
};
export default ExpenseReducer;