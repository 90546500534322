import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from 'lodash'
import Spinner from "react-bootstrap/Spinner";
import "jspdf-autotable";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Select from "react-select";
import ImageUploader from "react-images-upload";
// import useScanDetection from "use-scan-detection";
import { addProduct, addProductImage, getUOM, getProductType } from "./Product.action";
import { setHeading } from "../../../accounts/account.actions";
import { getSubCategoryWithCategoryIdApi } from "../subCategory/SubCategory.action";
import { getCategoryWithWarehouseApi } from "../categories/Catagory.action";
import { getWarehouseApi } from "../Warehouse/Warehouse.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getBrandApi } from "../Brand/Brand.action";
import { getSizeData } from "../AddSize/Size.action";
import { getFlavorsData } from "../AddColour/Colour.action";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";


const AddProduct = (props) => {
  const [product, setProduct] = useState("");
  const [uomId, setUomId] = useState();
  const [uomname, setUomName] = useState();
  const [salePrice, setsalePrice] = useState();
  const [code, setCode] = useState("");
  const [minimumBalance, setMinimumBalance] = useState("");
  const [maximumBalance, setMaximumBalance] = useState("");
  const [warranty, setWarranty] = useState();
  const [purchasePrice, setPurchasePrice] = useState("");
  const [productQuantity, setProductQuantity] = useState();
  const [productDesc, setProductDesc] = useState()
  // const [barcode, setBarcode] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [retaurantId, setRestaurantId] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [CategoryId, setCategoryId] = useState();
  const [Categoryname, setCategoryName] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryname, setSubCategoryName] = useState();
  const [brandId, setBrandId] = useState();
  const [brandName, setBrandName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [TypeId, setTypeId] = useState("");
  const [Typename, setTypeName] = useState("");
  const [inputField, setInputField] = useState([
    {
      colourLabel: "",
      sizeLabel: "",
      sellingPrice: 0,
      purchasePrice: 0,
    },
  ]);


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };
  function uploadSimpleProduct() {
    props.addProduct({
      wareHouseId: warehouseId,
      retaurantId: retaurantId,
      category: CategoryId,
      subCategoryId: subCategoryId,
      name: product,
      desc: productDesc,
      uom: uomId,
      uomLabel: uomname,
      code: code,
      salePrice: salePrice,
      maximumBalance: maximumBalance,
      minimumBalance: minimumBalance,
      purchasePrice: purchasePrice,
      productBalance: productQuantity,
      images: props.ProductImageURL.file,
      // barcodeId: barcode,
      warranty: warranty,
      brandId: brandId,
      productType: TypeId,
    },
      handleVisible,
      handleDangerVisible
    );
    setRestaurantName("")
    setCategoryName("")
    setSubCategoryName("")
    setCode("");
    setProduct("");
    setProductDesc("");
    setUomName("");
    setUomId("");
    setPurchasePrice("");
    setsalePrice("");
    setProductQuantity("");
    setMinimumBalance("");
    setMaximumBalance("");
    setWarranty("");
    // setBarcode("");
    setWarehouseName("")
    setBrandName("")
  }
  function uploadVariationProduct() {
    props.addProduct({
      wareHouseId: warehouseId,
      retaurantId: retaurantId,
      category: CategoryId,
      subCategoryId: subCategoryId,
      name: product,
      desc: productDesc,
      uom: uomId,
      uomLabel: uomname,
      code: code,
      maximumBalance: maximumBalance,
      minimumBalance: minimumBalance,
      productBalance: productQuantity,
      images: props.ProductImageURL.file,
      // barcodeId: barcode,
      warranty: warranty,
      brandId: brandId,
      variation: inputField,
      productType: TypeId,
    },
      handleVisible,
      handleDangerVisible
    );
    setRestaurantName("")
    setCategoryName("")
    setSubCategoryName("")
    setCode("");
    setProduct("");
    setProductDesc("");
    setUomName("");
    setUomId("");
    setPurchasePrice("");
    setsalePrice("");
    setProductQuantity("");
    setMinimumBalance("");
    setMaximumBalance("");
    setWarranty("");
    // setBarcode("");
    setWarehouseName("")
    setBrandName("")
    setInputField([
      {
        flavorLabel: "",
        sizeLabel: "",
        sellingPrice: 0,
        purchasePrice: 0,
      },
    ]);
  }

  const onDrop = (pictureFiles, pictureDataURLs) => {
    props.ProductImage({
      image: pictureFiles[0],
    });
    //setUploadImage(pictureFiles);
  };

  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? true : false
  }

  // useScanDetection(
  //   Checkpermissions("permissionsCatName", "Enable Barcode") && {
  //     onComplete: setBarcode,
  //     //  minLength: 13 // EAN13
  //   });

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
    // props.getCategoryWithWarehouseApi(selectedOption.value);
  };
  const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    props.getCategoryWithWarehouseApi(selectedOption.value);
  };
  const getRetaurantList = !_.isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  const handleChangeCategory = (selectedOption) => {
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
    props.getSubCategoryWithCategoryIdApi(selectedOption.value);
  };
  const getCategoryList = !_.isEmpty(props.categoryListByWarehouseId.data) && props.categoryListByWarehouseId.data.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });

  const handleChangeSubCategory = (selectedOption) => {
    setSubCategoryId(selectedOption.value);
    setSubCategoryName(selectedOption.label);
  };
  const getSubCategoryList = !_.isEmpty(props.subCategoryListByCategoryId) && props.subCategoryListByCategoryId.map((x) => {
    let data = { value: x._id, label: x.subCategoryName };
    return data;
  });

  const handleChangeUom = (selectedOption) => {
    setUomId(selectedOption.value);
    setUomName(selectedOption.label);
  };
  const getUomList = !_.isEmpty(props.productUomList) && props.productUomList.map((x) => {
    let data = { value: x._id, label: x.unitName };
    return data;
  });

  const handleChangeBrand = (selectedOption) => {
    setBrandId(selectedOption.value);
    setBrandName(selectedOption.label);
  };
  const getBrandList = !_.isEmpty(props.brandDataList) && props.brandDataList.map((x) => {
    let data = { value: x._id, label: x.brandName };
    return data;
  });

  const handleChangeType = (selectedOption) => {
    setTypeId(selectedOption.value);
    setTypeName(selectedOption.label);
  };
  const gettypeList = !_.isEmpty(props.productTypeList) && props.productTypeList.map((x) => {
    let data = { value: x._id, label: x.productTypeName };
    return data;
  });

  const inventorySize = props.sizeDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.sizeName,
    };
    return data;
  });

  const inventoryFlavor = props.flavorsDataList.map((x) => {
    let data = {
      value: x._id,
      label: x.colourName,
    };
    return data;
  });






  const handleChange = (e, index, type) => {
    // let z = find(props.allInventory, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "colour") list[index]["colourLabel"] = e.label;
    if (type == "size") list[index]["sizeLabel"] = e.label;
    if (type == "price") list[index]["sellingPrice"] = Number(e.target.value);
    if (type == "purchasePrice") list[index]["purchasePrice"] = Number(e.target.value);
    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { colourLabel: "", sizeLabel: "", sellingPrice: 0, purchasePrice: 0 },
    ]);
  };
  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  useEffect(() => {
    props.setHeading("New Product");
    props.getProductUom();
    props.getWarehouseApi();
    props.getRestaurantData();
    props.getBrandApi();
    props.getProductType();
    props.getSizeData();
    props.getFlavorsData();
    //props.resetState();
  }, []);

  return (
    <>
      {props.isFetchingCategoryByWarehouseId || props.isAddingProduct ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Form>
              <Row>
                <Col lg="12" md="12" xs="12">
                  <Form.Group className="mb-3" controlId="garana">
                    <Form.Label>
                      <b>Select Product Type *</b>
                    </Form.Label>
                    <Select
                      isDisabled={_.isEmpty(props.productTypeList)}
                      value={{ label: Typename }}
                      placeholder="Select Product Type"
                      onChange={handleChangeType}
                      options={gettypeList}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <div hidden={TypeId == "6433dd117aad3eb039c17451" ? false : true}>
                <Row>
                  <Col xl="4" lg="4" md="6" xs="12">
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Company *</b>
                      </Form.Label>
                      <Select
                        value={{ label: restaurantName }}
                        placeholder="Select Company"
                        onChange={handleChangeRestaurant}
                        options={getRetaurantList}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="6" xs="12">
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Category *</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Category"
                        isDisabled={_.isEmpty(props.categoryListByWarehouseId) || _.isEmpty(retaurantId)}
                        onChange={handleChangeCategory}
                        options={getCategoryList}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl="4" lg="4" md="6" xs="12">
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Sub Category *</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Sub Category"
                        value={{ label: subCategoryname }}
                        isDisabled={_.isEmpty(props.subCategoryListByCategoryId) || _.isEmpty(CategoryId)}
                        onChange={handleChangeSubCategory}
                        options={getSubCategoryList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Code </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Code"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Name *</b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Product Name"
                        onChange={(e) => setProduct(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Description </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Product Description"
                        onChange={(e) => setProductDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select UOM *</b>
                      </Form.Label>
                      <Select
                        isDisabled={_.isEmpty(props.productUomList) || _.isEmpty(subCategoryId)}
                        placeholder="Select UOM"
                        onChange={handleChangeUom}
                        options={getUomList}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Purchase Price *</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        placeholder="Purchase Price"
                        onChange={(e) => setPurchasePrice(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Sale Price *</b>
                      </Form.Label>
                      <Form.Control
                        type="Number"
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        placeholder="Sale Price"
                        onChange={(e) => setsalePrice(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                </Row>
                <Row >
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Quantity *</b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="Number"
                        placeholder="Product Quantity"
                        onChange={(e) => setProductQuantity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>

                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Minimum Balance </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="Number"
                        placeholder="Minimum Balance"
                        onChange={(e) => setMinimumBalance(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Maximum Balance </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="Number"
                        placeholder="Maximum Balance"
                        onChange={(e) => setMaximumBalance(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Warranty </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Warranty"
                        onChange={(e) => setWarranty(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {/* {Checkpermissions("permissionsCatName", "Enable Barcode") &&
                  <Col xl="4" lg="4" md="4" sm="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Bar Code Scaner</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Bar Code Number"
                        value={barcode}
                        disabled
                        onBlur={(e) => setBarcode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>} */}
                  <Col xl="4" lg="4" md="4" sm="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Brand </b>
                      </Form.Label>
                      <Select
                        // value={{ label: brandName }}
                        isDisabled={_.isEmpty(props.brandDataList) || _.isEmpty(subCategoryId)}
                        placeholder="Select Brand"
                        onChange={handleChangeBrand}
                        options={getBrandList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4" md="4" xs="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Business Location *</b>
                      </Form.Label>
                      <Select
                        isDisabled={_.isEmpty(props.productUomList) || _.isEmpty(subCategoryId)}
                        placeholder="Select Business Location"
                        onChange={handleChangeWarehouse}
                        options={getWarehouseList}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          Upload Product Image
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Product Image"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".jpeg", ".png"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {_.isEmpty(product) || _.isEmpty(productQuantity) || _.isEmpty(purchasePrice) || _.isEmpty(salePrice) || _.isEmpty(uomId) || _.isEmpty(retaurantId) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadSimpleProduct}> SAVE </Button>
                  )}
                </div>
              </div>


              <div hidden={TypeId == "6433dd117aad3eb039c17450" ? false : true}>
                <Row>
                  <Col>
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Company *</b>
                      </Form.Label>
                      <Select
                        value={{ label: restaurantName }}
                        placeholder="Select Company"
                        onChange={handleChangeRestaurant}
                        options={getRetaurantList}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Category *</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Category"
                        isDisabled={_.isEmpty(props.categoryListByWarehouseId) || _.isEmpty(retaurantId)}
                        onChange={handleChangeCategory}
                        options={getCategoryList}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3 mt-3" >
                      <Form.Label>
                        <b>Select Sub Category *</b>
                      </Form.Label>
                      <Select
                        placeholder="Select Sub Category"
                        value={{ label: subCategoryname }}
                        isDisabled={_.isEmpty(props.subCategoryListByCategoryId) || _.isEmpty(CategoryId)}
                        onChange={handleChangeSubCategory}
                        options={getSubCategoryList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Code </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Code"
                        onChange={(e) => setCode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Name *</b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Product Name"
                        onChange={(e) => setProduct(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Description </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Product Description"
                        onChange={(e) => setProductDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row >
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Product Quantity *</b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="Number"
                        placeholder="Product Quantity"
                        onChange={(e) => setProductQuantity(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Minimum Balance </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="Number"
                        placeholder="Minimum Balance"
                        onChange={(e) => setMinimumBalance(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Maximum Balance </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="Number"
                        placeholder="Maximum Balance"
                        onChange={(e) => setMaximumBalance(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Warranty </b>
                      </Form.Label>
                      <Form.Control
                        disabled={_.isEmpty(subCategoryId) ? true : false}
                        type="text"
                        placeholder="Warranty"
                        onChange={(e) => setWarranty(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  {/* {Checkpermissions("permissionsCatName", "Enable Barcode") &&
                  <Col xl="4" lg="4" md="4" sm="4">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Bar Code Scaner</b>
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Bar Code Number"
                        value={barcode}
                        disabled
                        onBlur={(e) => setBarcode(e.target.value)}
                      />
                    </Form.Group>
                  </Col>} */}
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Brand </b>
                      </Form.Label>
                      <Select
                        // value={{ label: brandName }}
                        isDisabled={_.isEmpty(props.brandDataList) || _.isEmpty(subCategoryId)}
                        placeholder="Select Brand"
                        onChange={handleChangeBrand}
                        options={getBrandList}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select Business Location *</b>
                      </Form.Label>
                      <Select
                        isDisabled={_.isEmpty(props.productUomList) || _.isEmpty(subCategoryId)}
                        placeholder="Select Business Location"
                        onChange={handleChangeWarehouse}
                        options={getWarehouseList}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Select UOM *</b>
                      </Form.Label>
                      <Select
                        isDisabled={_.isEmpty(props.productUomList) || _.isEmpty(subCategoryId)}
                        placeholder="Select UOM"
                        onChange={handleChangeUom}
                        options={getUomList}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12" md="12" sm="12">
                    <Form.Group>
                      <Form.Label><b>Select Variation *</b></Form.Label>
                      {inputField.map((item, i) => {
                        return (
                          <React.Fragment key={i}>
                            <Row >
                              <Col lg="3" md="3" sm="3">
                                <Form.Group>
                                  <Form.Label><b> Select Color *</b></Form.Label>
                                  <Select
                                    style={{ zIndex: 1 }}
                                    value={{ label: item.colourLabel }}
                                    placeholder="Select Color"
                                    name="colour"
                                    onChange={(e) => handleChange(e, i, "colour")}
                                    options={inventoryFlavor}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="3" md="3" sm="3">
                                <Form.Group>
                                  <Form.Label><b> Select Size *</b></Form.Label>
                                  <Select
                                    style={{ zIndex: 1 }}
                                    value={{ label: item.sizeLabel }}
                                    placeholder="Select Size"
                                    name="size"
                                    onChange={(e) => handleChange(e, i, "size")}
                                    options={inventorySize}
                                    isOptionDisabled={(option) => !_.isEmpty(_.find(inputField, (x) => x.sizeId == option.value))}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="2" md="2" sm="2">
                                <Form.Group className="mb-3">
                                  <Form.Label><b>Purchase Price *</b></Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Purchase Price"
                                    name="purchasePrice"
                                    value={item.purchasePrice}
                                    onChange={(e) => handleChange(e, i, "purchasePrice")}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg="2" md="2" sm="2">
                                <Form.Group className="mb-3">
                                  <Form.Label><b>Sale Price *</b></Form.Label>
                                  <Form.Control
                                    type="number"
                                    placeholder="Sale Price"
                                    name="sellingPrice"
                                    value={item.sellingPrice}
                                    onChange={(e) => handleChange(e, i, "price")}
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg="2" md="2" sm="2" style={{ marginTop: "2.5%" }}>
                                <Form.Group>
                                  {inputField.length - 1 == i && (
                                    <Button
                                      title="Click to Add Product Variation"
                                      onClick={handleAddInput}
                                      style={{ float: "right", backgroundColor: '#81abd3', color: 'white' }}
                                    >
                                      <AddIcon />
                                    </Button>
                                  )}

                                  {inputField.length !== 1 && (
                                    <Button
                                      title="Click to Remove Product Variation"
                                      onClick={() => handleRemoveInput(i)}
                                      style={{ float: "right", backgroundColor: '#81abd3', color: 'white', marginRight: '20px' }}
                                    >
                                      <RemoveIcon />
                                    </Button>
                                  )}
                                </Form.Group>
                              </Col>
                            </Row>
                          </React.Fragment>
                        );
                      })}
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col xl="12" lg="12" md="12" sm="12" xs="12">
                    <Form.Group className="mb-3">
                      <Form.Label>
                        <b style={{ fontSize: "large" }}>
                          Upload Product Image
                        </b>
                      </Form.Label>
                      <ImageUploader
                        withIcon={false}
                        buttonText="Upload Product Image"
                        onChange={onDrop}
                        // onChange = {(e) => setUploadCnicImage(e.target.files[0])}
                        imgExtension={[".jpg", ".jpeg", ".png"]}
                        maxFileSize={999999999999999999999}
                        singleImage={true}
                        withPreview={true}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {_.isEmpty(product) || _.isEmpty(productQuantity) || _.isEmpty(uomId) || _.isEmpty(retaurantId) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadVariationProduct}> SAVE </Button>
                  )}
                </div>
              </div>

            </Form>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addProduct: (data, handleVisible, handleDangerVisible) => dispatch(addProduct(data, handleVisible, handleDangerVisible)),
    getProductUom: () => dispatch(getUOM()),
    getBrandApi: () => dispatch(getBrandApi()),
    ProductImage: (data) => dispatch(addProductImage(data)),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getCategoryWithWarehouseApi: (id) => dispatch(getCategoryWithWarehouseApi(id)),
    getSubCategoryWithCategoryIdApi: (id) => dispatch(getSubCategoryWithCategoryIdApi(id)),
    setHeading: (data) => dispatch(setHeading(data)),
    getProductType: () => dispatch(getProductType()),
    getSizeData: () => dispatch(getSizeData()),
    getFlavorsData: () => dispatch(getFlavorsData()),
    // reset: () => dispatch(resetReducer()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  productTypeList: state.ProductReducer.productTypeList,
  productList: state.ProductReducer.productList,
  productUomList: state.ProductReducer.productUomList,
  ProductImageURL: state.ProductReducer.ProductImageURL,
  isAddingProduct: state.ProductReducer.isAddingProduct,
  subCategoryListByCategoryId: state.SubCategoryReducer.subCategoryListByCategoryId,
  isFetchingSubCategoryListByCategoryId: state.SubCategoryReducer.isFetchingSubCategoryListByCategoryId,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
  categoryListByWarehouseId: state.CategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId: state.CategoryReducer.isFetchingCategoryByWarehouseId,
  brandDataList: state.brandReducer.brandDataList,
  isFetchingBrand: state.brandReducer.isFetchingBrand,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  sizeDataList: state.sizeReducer.sizeDataList,
  isFetchingSize: state.sizeReducer.isFetchingSize,
  flavorsDataList: state.ColourReducer.flavorsDataList,
  isFetchingFlavors: state.ColourReducer.isFetchingFlavors,
});


export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);