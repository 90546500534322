import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { getDayOpenReport } from "./Report.action";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { setHeading } from "../../../accounts/account.actions";
import Button from '@mui/material/Button';


const DayOpenRecord = (props) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const exportPDF = () => {
    props.searchDayOpenRecord(
      {
        startDate: !_.isEmpty(startDate) ? startDate : "",
        endDate: !_.isEmpty(endDate) ? endDate : "",
      },
    );
    navigate('/setting/dayOpen_record_table')
  };

  useEffect(() => {
    props.setHeading("DayOpen Record")
  }, []);

  return (
    <>
      <Container fluid>
        <div className="main">
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>
                  <b> Start Date *</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>
                  <b> End Date *</b>
                </Form.Label>
                <Form.Control
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                ></Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            {_.isEmpty(startDate) || _.isEmpty(endDate) ? (
              <Button variant="contained" size="large" disabled> DayOpen Report </Button>
            ) : (
              <Button variant="contained" size="large" onClick={() => exportPDF()}> DayOpen Report </Button>
            )}
          </div>

        </div>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    searchDayOpenRecord: (data) => dispatch(getDayOpenReport(data)),
    setHeading: (data) => dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(DayOpenRecord);
