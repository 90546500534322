import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup, } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import { setHeading } from "../../../accounts/account.actions";
import { getWarehouseApi } from "../Warehouse/Warehouse.action";
import { addDemandApi } from "./Demand.action";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddDemand = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      availableQuantity: 0,
      issuanceQuantity: 0,
      stockPrice: 0,
    },
  ]);

  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [demandDate, setDemandDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [manualDemandNumber, setManualDemandNumber] = useState();
  const [referenceNumber, setReferenceNumber] = useState();
  const [description, setDesc] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadDemand() {
    props.addDemandApi({
      wareHouseId: warehouseId,
      demandDate: demandDate,
      manualDemandNumber: manualDemandNumber,
      referenceNumber: referenceNumber,
      description: description,
      data: inputField,
    },
      handleVisible,
      handleDangerVisible
    );
    setDemandDate("");
    setManualDemandNumber("");
    setReferenceNumber("");
    setDesc("");
    setInputField([{
      inventoryId: "",
      availableQuantity: 0,
      issuanceQuantity: 0,
      stockPrice: 0,
    }]);
  }

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == item);
    // if (Number(e.target.value) <= Number(z.productBalance)) {
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["issuanceQuantity"] = Number(z.productBalance);
    if (type == "intname") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["issuanceQuantity"] = Number(e.target.value);
    setInputField(list);
    // } else {
    //   alert("value out of range");
    // }
  };
  const handleChange = (e, index, type) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["availableQuantity"] = Number(z.productBalance);
    if (type == "intname") list[index]["stockPrice"] = Number(z.purchasePrice);
    if (type == "intname") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["issuanceQuantity"] = Number(e.target.value);
    setInputField(list);
  };

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", availableQuantity: 0, issuanceQuantity: 0, stockPrice: 0 },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };
  const getHybridAndCompleteData = props.hybridAndCompleteData.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });

  useEffect(() => {
    props.getHybridAndCompleteData();
    props.getWarehouseApi();
    props.setHeading("Add Demand");
  }, []);

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isAddingDemand ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingDemand ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Select Business Location *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Business Location"
                    onChange={handleChangeWarehouse}
                    options={getWarehouseList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="Date"
                    value={demandDate}
                    onChange={(e) => setDemandDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Manual Demand Number </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Manual Demand Number"
                    name="Manual Demand Number"
                    onBlur={(e) => setManualDemandNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Reference Number </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Reference Number"
                    name="Reference Number"
                    onBlur={(e) => setReferenceNumber(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Demand Description *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Demand Description"
                    name="Demand Description"
                    value={description}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>


            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="4" lg="4" md="4" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Product"
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={getHybridAndCompleteData}
                          // isDisabled={isEmpty(inventory) || wareHouseId === ""}
                          isOptionDisabled={(option) => !isEmpty(find(inputField, (x) => x.inventoryId == option.value))}
                        />
                      </Form.Group>
                    </Col>
                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Available Quantity </b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Available Quantity"
                          name="availableQuantity"
                          disabled
                          value={item.availableQuantity}
                        />
                      </Form.Group>
                    </Col>

                    {/* <Col xl="2" lg="2" md="2" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Stock Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Stock Price"
                          name="Stock Price"
                          disabled
                          value={item.stockPrice}
                        />
                      </Form.Group>
                    </Col> */}

                    <Col xl="4" lg="4" md="4" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity"
                          name="issuanceQuantity"
                          min={0}
                          value={item.issuanceQuantity}
                          onChange={(e) => handleChangeDemand(e, i, "intquantity", item.inventoryId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <Button
                            title="Click to Add Demand"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              // color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField.length !== 1 && (
                          <Button
                            title="Click to Remove Demand"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              // color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <div className="sendDiv">
              {isEmpty(demandDate) || isEmpty(description) || inputField[0].inventoryId == "" || inputField[0].issuanceQuantity == 0 ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>
                  SAVE
                </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadDemand}>
                  SAVE
                </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getHybridAndCompleteData: () => dispatch(getHybridAndCompleteData()),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
    setHeading: (data) => dispatch(setHeading(data)),
    addDemandApi: (data, handleVisible, handleDangerVisible) => dispatch(addDemandApi(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  warehouseList: state.WarehouseReducer.warehouseList,
  isAddingDemand: state.DemandReducer.isAddingDemand
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDemand);
