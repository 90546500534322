const initialState = {
  masterControlList: [],
  masterControlDataList: [],
  isFetchingMasterControl: false,
  isAddingMasterControl: false,
  isFetchingUOM: false,
  UOMList: [],
};

const MasterControlReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_MASTER_CONTROL_GET_DATA":
      return { ...state, isFetchingMasterControl: true, };
    case "SUCCESS_MASTER_CONTROL_GET_DATA":
      return {
        ...state,
        masterControlDataList: action.payload.data,
        isFetchingMasterControl: false,
      };
    case "ERROR_MASTER_CONTROL_GET_DATA":
      return { ...state, };
    //get uom
    case "REQUEST_UOM":
      return { ...state, isFetchingUOM: true, };
    case "SUCCESS_UOM":
      return {
        ...state,
        UOMList: action.payload.data,
        isFetchingUOM: false,
      };
    case "ERROR_UOM":
      return { ...state, };
    //addmasterControl
    case "REQUEST_ADD_MASTER_CONTROL":
      return { ...state, isAddingMasterControl: true };
    case "SUCCESS_ADD_MASTER_CONTROL":
      return {
        ...state,
        masterControlList: action.payload,
        isAddingMasterControl: false,
      };
    case "ERROR_ADD_MASTER_CONTROL":
      return { ...state, isAddingMasterControl: false };
    default:
      return state;
  }
};
export default MasterControlReducer;