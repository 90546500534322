import API from "../../../../global/api";


export const requestAddUserRes = () => {
    return {
        type: "REQUEST_ADD_USER_RES",
    };
};
export const successAddUserRes = (data) => {
    return {
        type: "SUCCESS_ADD_USER_RES",
        payload: data,
    };
};
export const errorAddUserRes = () => {
    return {
        type: "ERROR_ADD_USER_RES",
    };
};
export const addUserRes = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddUserRes());
        API.post(`/users/register`, data).then((res) => {
            handleVisible(res.data.message);
            let getData = res.data;
            dispatch(successAddUserRes(getData));
        }).catch((error) => {
            handleDangerVisible(error.response.data.message);
            dispatch(errorAddUserRes());
        })
    };
};


export const requestGetUserRes = () => {
    return {
        type: "REQUEST_GET_USER_RES",
    };
};
export const successGetUserRes = (data) => {
    return {
        type: "SUCCESS_GET_USER_RES",
        payload: data,
    };
};
export const errorGetUserRes = () => {
    return {
        type: "ERROR_GET_USER_RES",
    };
}
export const getUserRes = () => {
    return (dispatch) => {
        dispatch(requestGetUserRes());
        API.get(`/users`).then((res) => {
            let getData = res.data;
            dispatch(successGetUserRes(getData));
        }).catch((error) => {
            dispatch(errorGetUserRes());
        })
    };
};


export const requestDeleteUserRes = () => {
    return {
        type: "REQUEST_DELETE_USER_RES",
    };
};
export const successDeleteUserRes = (data) => {
    return {
        type: "SUCCESS_DELETE_USER_RES",
        payload: data,
    };
};
export const errorDeleteUserRes = () => {
    return {
        type: "ERROR_DELETE_USER_RES",
    };
};
export const deleteUserRes = (customer_id) => {
    return (dispatch) => {
        dispatch(requestDeleteUserRes());
        API.delete(`/users/${customer_id}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteUserRes());
            // dispatch(getUserRes());
        }).catch((error) => {
            dispatch(errorDeleteUserRes());
        })
    };
};


export const requestUpdateUserRes = () => {
    return {
        type: "REQUEST_UPDATE_USER_RES",
    };
};
export const successUpdateUserRes = (data) => {
    return {
        type: "SUCCESS_UPDATE_USER_RES",
        payload: data,
    };
};
export const errorUpdateUserRes = () => {
    return {
        type: "ERROR_UPDATE_USER_RES",
    };
};
export const UpdateUserRes = (newData, oldData) => {
    console.log('newData', newData);
    return (dispatch) => {
        dispatch(requestUpdateUserRes());
        API.put(`/users/${oldData._id}`, newData).then((res) => {
            let getData = res.data;
            dispatch(successUpdateUserRes([newData]));
        }).catch((error) => {
            dispatch(errorUpdateUserRes());
        })
    };
};