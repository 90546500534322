const initialState = {
  igp: [],
  isAddingIGP: false,
  isFetchingIGP: false,
  IGPList: [],
};

const IGPReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_IGP":
      return { ...state, isAddingIGP: true };
    case "SUCCESS_ADD_IGP":
      return { ...state, igp: action.payload, isAddingIGP: false };
    case "ERROR_ADD_IGP":
      return { ...state, isAddingIGP: false };

    case "REQUEST_GET_IGP":
      return { ...state, isFetchingIGP: true };
    case "SUCCESS_GET_IGP":
      return { ...state, IGPList: action.payload.data, isFetchingIGP: false, };
    case "ERROR_GET_IGP":
      return { ...state, isFetchingIGP: false };

      
    default:
      return state;
  }
};
export default IGPReducer;
