import API from "../../../../../global/api";

export const requestGetUsers = () => {
  return {
    type: "REQUEST_GET_Users",
  };
};
export const successGetUsers = (data) => {
  return {
    type: "SUCCESS_GET_Users",
    payload: data,
  };
};
export const errorGetUsers = () => {
  return {
    type: "ERROR_GET_Users",
  };
};
export const getUsers = () => {
  return (dispatch) => {
    dispatch(requestGetUsers());
    API.get(`/users?limit=1000&page=0`).then((res) => {
      let getData = res.data;
      dispatch(successGetUsers(getData));
    }).catch((error) => {
      dispatch(errorGetUsers());
    })
  };
};


export const requestGetPermissions = () => {
  return {
    type: "REQUEST_GET_Permissions",
  };
};
export const successGetPermissions = (data) => {
  return {
    type: "SUCCESS_GET_Permissions",
    payload: data,
  };
};
export const errorGetPermissions = () => {
  return {
    type: "ERROR_GET_Permissions",
  };
};
export const getPermissions = () => {
  return (dispatch) => {
    dispatch(requestGetPermissions());
    API.get(`/permission`).then((res) => {
      let getData = res.data;
      dispatch(successGetPermissions(getData));
    }).catch((error) => {
      dispatch(errorGetPermissions());
    })
  };
};


export const requestAddPermissions = () => {
  return {
    type: "REQUEST_ADD_Permissions",
  };
};
export const successAddPermissions = (data) => {
  return {
    type: "SUCCESS_ADD_Permissions",
    payload: data,
  };
};
export const errorAddPermissions = () => {
  return {
    type: "ERROR_ADD_Permissions",
  };
};
export const addPermissions = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddPermissions());
    API.post(`/permission/add_assigned_permission`, data).then((res) => {
      handleVisible();
      let getData = res.data;
      dispatch(successAddPermissions(getData));
    }).catch((error) => {
      handleDangerVisible();
      dispatch(errorAddPermissions());
    })
  };
};