import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { getOrderCreationApi } from "./OrderCreationAction";
import { setHeading } from "../../../accounts/account.actions";


const OrderCreationDetail = (props) => {
    const orderCreationData = props.orderCreationList;
    useEffect(() => {
        props.setHeading("Order Creation List");
        props.getOrderCreationApi();
    }, []);

    return (
        <>
            {props.isFetchingOrderCreation ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingOrderCreation ? 'Loading...' : 'Loading...'}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Order Creation Details"
                            columns={[
                                { title: 'Customer Name', field: 'customerId.name' },
                                { title: 'Manual Order No', field: 'manualOrderId' },
                                { title: 'Customer Order No', field: 'customerOrder' },
                                { title: 'Order Invoice', field: 'orderInvoice' },
                                { title: 'Description', field: 'orderDescription' },
                                { title: 'Date', field: 'orderDeliveryDate' },
                            ]}
                            data={orderCreationData}
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        columns={[
                                            // { title: 'Product Code', field: 'inventoryId.code' },
                                            { title: 'Product Name', field: 'inventoryLabel' },
                                            { title: 'Order Quantity', field: 'orderQuantity' },
                                        ]}
                                        data={rowData.data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [30, 50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [30, 50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getOrderCreationApi: () => dispatch(getOrderCreationApi()),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingOrderCreation: state.OrderCreationReducer.isFetchingOrderCreation,
    orderCreationList: state.OrderCreationReducer.orderCreationList
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderCreationDetail);