import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { updateDemandApi, deleteDemandApi, individualPDF } from "./Demand.action";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


const DemandList = (props) => {

    useEffect(() => {
        props.setHeading("Demand List");
    }, []);

    return (
        <>
            {props.isFetchingDemand ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="Demand Details"
                            columns={[
                                { title: "Seq Number", field: "seqNumber" },
                                { title: "Business Location", field: "wareHouseId.businessName", editable: false },
                                { title: "Date", field: "demandDate", editable: false },
                                { title: "Manual Demand Number", field: "manualDemandNumber" },
                                { title: "Reference", field: "referenceNumber" },
                                { title: "Description ", field: "description" },
                            ]}
                            detailPanel={rowData => {
                                return (
                                    <MaterialSize
                                        columns={[
                                            { title: 'Product Name', field: 'inventoryLabel' },
                                            { title: 'Demand Quantity', field: 'issuanceQuantity' },
                                        ]}
                                        data={rowData.data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [30, 50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}

                                    />
                                )
                            }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/demand?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                props.updateDemandApi(newData, oldData);
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deleteDemandApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                (rowData) => ({
                                    icon: () => <LocalPrintshopIcon />,
                                    tooltip: "Print Demand",
                                    onClick: (event, rowData) => {
                                        props.individualPDF(rowData);
                                    },
                                }),
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        deleteDemandApi: (id) => dispatch(deleteDemandApi(id)),
        updateDemandApi: (newData, oldData) => dispatch(updateDemandApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
        individualPDF: (data) => dispatch(individualPDF(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    demandList: state.DemandReducer.demandList,
    isFetchingDemand: state.DemandReducer.isFetchingDemand,
});

export default connect(mapStateToProps, mapDispatchToProps)(DemandList);