import API from "../../../../../global/api";


export const requestAddCoupons = () => {
  return {
    type: "REQUEST_ADD_COUPONS",
  };
};
export const successAddCoupons = (data) => {
  return {
    type: "SUCCESS_ADD_COUPONS",
    payload: data,
  };
};
export const errorAddCoupons = () => {
  return {
    type: "ERROR_ADD_COUPONS",
  };
};
export const addCoupons = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCoupons());
    API.post(`/coupons`, data).then((res) => {
      dispatch(successAddCoupons(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddCoupons());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestGetCoupons = () => {
  return {
    type: "REQUEST_GET_COUPONS",
  };
};
export const successGetCoupons = (data) => {
  return {
    type: "SUCCESS_GET_COUPONS",
    payload: data,
  };
};
export const errorGetAllCoupons = () => {
  return {
    type: "ERROR_GET_COUPONS",
  };
};
export const getCoupons = (data) => {
  return (dispatch) => {
    dispatch(requestGetCoupons());
    API.get(`/coupons?limit=1000&page=0&search`).then((res) => {
      let data1 = res.data;
      dispatch(successGetCoupons(data1));
    }).catch((error) => {
      dispatch(errorGetAllCoupons());
    });
  };
};


export const UpdateRequestCoupons = () => {
  return {
    type: "UPDATE_REQUEST_COUPONS",
  };
};
export const UpdateSuccessCoupons = (data) => {
  return {
    type: "UPDATE_SUCCESS_COUPONS",
    payload: data,
  };
};
export const UpdateErrorCoupons = () => {
  return {
    type: "UPDATE_ERROR_COUPONS",
  };
};
export const updateCoupons = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestCoupons());
    API.put(`/coupons/${oldData._id}`,
      {
        name: data.name,
        amount: data.amount,
        code: data.code,
      },
      {}
    ).then((res) => {
      dispatch(UpdateSuccessCoupons([data]));
    }).catch((error) => dispatch(UpdateErrorCoupons()));
  };
};


export const DeleteRequestCoupons = () => {
  return {
    type: "DELETE_REQUEST_COUPONS",
  };
};
export const DeleteSuccessCoupons = (user_id) => {
  return {
    type: "DELETE_SUCCESS_COUPONS",
    user_id: user_id,
  };
};
export const DeleteErrorCoupons = () => {
  return {
    type: "DELETE_ERROR_COUPONS",
  };
};
export const deleteCoupons = (categoryId) => {
  return (dispatch) => {
    dispatch(DeleteRequestCoupons());
    API.delete(`/coupons/${categoryId}`).then((res) => {
      dispatch(DeleteSuccessCoupons(categoryId));
    }).catch((error) => dispatch(DeleteErrorCoupons()));
  };
};
