import API from "../../../../../global/api";
import { getPurchaseApi } from "../PurchaseOrder/PurchaseOrder.action";
import jsPDF from "jspdf";
import moment from "moment";


export const requestAddIGP = () => {
  return {
    type: "REQUEST_ADD_IGP",
  };
};
export const successAddIGP = (data) => {
  return {
    type: "SUCCESS_ADD_IGP",
    payload: data,
  };
};
export const errorAddIGP = () => {
  return {
    type: "ERROR_ADD_IGP",
  };
};
export const addIGPApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddIGP());
    API.post(`/igp/`, data).then((res) => {
      dispatch(getPurchaseApi());
      dispatch(successAddIGP(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddIGP());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestGetIGP = () => {
  return {
    type: "REQUEST_GET_IGP",
  };
};
export const successGetIGP = (data) => {
  return {
    type: "SUCCESS_GET_IGP",
    payload: data,
  };
};
export const errorGetIGP = () => {
  return {
    type: "ERROR_GET_IGP",
  };
};
export const getIGPApi = () => {
  return (dispatch) => {
    dispatch(requestGetIGP());
    API.get(`/purchase/`).then((res) => {
      let getData = res.data;
      dispatch(successGetIGP(getData));
    }).catch((error) => {
      dispatch(errorGetIGP());
    });
  };
};


export const individualPDF = (data) => {
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "Inward Gate Pass Report";
    const companyName = `${data.retaurantId.restaurantName}`;
    const companyAddress = `${data.retaurantId.restaurantLocation}`;
    const companyNtn = `${data.retaurantId.ntn}`;

    const headers = [
      [
        "Serial No",
        "Product Name",
        "IGP Quantity",
      ],
    ];
    const data1 = data.data.map((elt, index) => [
      index + 1,
      elt.inventoryLabel,
      elt.productQuantity,
    ]);
    let content = {
      startY: 270,
      startX: 10,
      head: headers,
      tableWidth: "wrap",
      body: data1,
    };
    // doc.addImage(logo, "PNG", 40, 15, 100, 100);
    // doc.addImage(logo2, "PNG", 440, 90, 120, 120);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(13);
    doc.setFont("times", "normal");
    doc.text(companyAddress, 150, 60, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(14);
    doc.setFont("times", "bold");
    doc.text(title, 240, 120);
    doc.setFont("times", "normal");
    doc.text(`Purchase Order Details: ${data.purchaseOrderId.manualPurchaseNumber} / ${data.purchaseOrderId.purchaserName}`, 50, 150, 0, 20);
    doc.text(`IGP Date: ${data.igpDate}`, 50, 170, 0, 20);
    doc.text(`Manual GatePass Number: ${data.gatePass}`, 50, 190, 0, 20);
    doc.text(`Receiver Name: ${data.receivername}`, 50, 210, 0, 20);
    doc.text(`IGP Description: ${data.igpDescription}`, 50, 230, 0, 20);
    doc.text(`IGP Type: ${data.orderType}`, 50, 250, 0, 20);
    doc.autoTable(content);
    // let finalY = doc.lastAutoTable.finalY;
    // doc.text(grandTotal, 420, finalY + 30);
    doc.setProperties({ title: `PurchaseOrder Report ${moment(new Date()).format("YYYY-MM-DD")}` });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    // doc.save(`Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
  };
};