import API from "../../../../../global/api";

export const requestAddExpenseType = () => {
    return {
        type: "REQUEST_ADD_EXPENSE_TYPE",
    };
};
export const successAddExpenseType = (data) => {
    return {
        type: "SUCCESS_ADD_EXPENSE_TYPE",
        payload: data,
    };
};
export const errorAddExpenseType = () => {
    return {
        type: "ERROR_ADD_EXPENSE_TYPE",
    };
};
export const addExpenseTypesApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddExpenseType());
        API.post(`/expenseType`, data).then((res) => {
            // dispatch(getExpensetypeApi());
            dispatch(successAddExpenseType(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddExpenseType());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetExpenseType = () => {
    return {
        type: "REQUEST_EXPENSE_TYPE_GET_DATA",
    };
};
export const successGetExpenseType = (data) => {
    return {
        type: "SUCCESS_EXPENSE_TYPE_GET_DATA",
        payload: data,
    };
};
export const errorGetExpenseType = () => {
    return {
        type: "ERROR_EXPENSE_TYPE_GET_DATA",
    };
};
export const getExpensetypeApi = () => {
    return (dispatch) => {
        dispatch(requestGetExpenseType());
        API.get(`/expenseType`).then((res) => {
            let getData = res.data;
            dispatch(successGetExpenseType(getData));
        }).catch((error) => {
            dispatch(errorGetExpenseType());
        })
    };
};


export const DeleteRequestExpenseType = () => {
    return {
        type: "DELETE_REQUEST_EXPENSETYPE",
    };
};
export const DeleteSuccessExpenseType = (user_id) => {
    return {
        type: "DELETE_SUCCESS_EXPENSETYPE",
        user_id: user_id,
    };
};
export const DeleteErrorExpenseType = () => {
    return {
        type: "DELETE_ERROR_EXPENSETYPE",
    };
};
export const deleteExpenseTypeApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestExpenseType());
        API.delete(`/expenseType/${id}`).then(res => {
            dispatch(getExpensetypeApi());
            dispatch(DeleteSuccessExpenseType(id))
        }).catch((error) => {
            dispatch(DeleteErrorExpenseType())
        });
    }
};


export const UpdateRequestExpenseType = () => {
    return {
        type: "UPDATE_REQUEST_EXPENSETYPE",
    };
};
export const UpdateSuccessExpenseType = (data) => {
    return {
        type: "UPDATE_SUCCESS_EXPENSETYPE",
        payload: data,
    };
};
export const UpdateErrorExpenseType = () => {
    return {
        type: "UPDATE_ERROR_EXPENSETYPE",
    };
};
export const updateExpenseTypeApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestExpenseType());
        API.put(`/expenseType/${oldData._id}`, {
            expenseName: data.expenseName,
            expenseDesc: data.expenseDesc,
        }, {
        }).then(res => {
            dispatch(getExpensetypeApi());
            dispatch(UpdateSuccessExpenseType([data]))
        }).catch((error) =>
            dispatch(UpdateErrorExpenseType()));
    }
};