import { filter, isEmpty, toLower } from "lodash";
const initialState = {
  brandList: [],
  brandDataList: [],
  isFetchingBrand: false,
  isAddingBrand: false,
};

const brandReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_BRAND_GET_DATA":
      return { ...state, isFetchingBrand: true, };
    case "SUCCESS_BRAND_GET_DATA":
      return {
        ...state,
        brandDataList: action.payload.data,
        isFetchingBrand: false,
      };
    case "ERROR_BRAND_GET_DATA":
      return { ...state, };


    case "REQUEST_ADD_BRAND":
      return { ...state, isAddingBrand: true };
    case "SUCCESS_ADD_BRAND":
      return {
        ...state,
        brandList: action.payload,
        isAddingBrand: false,
      };
    case "ERROR_ADD_BRAND":
      return { ...state, isAddingBrand: false };

    default:
      return state;
  }
};
export default brandReducer;