import { Route, Routes } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import * as React from 'react';
import Login from "../views/user_views/accounts/Login";
import UserMainPage from "../views/user_views/accounts/UserMainPage";
import Dashboard from "../views/user_views/drawer/ResponsiveDrawer";
import MaterialLedgerTable from "../views/user_views/forms/materialLedger/MaterialLedgerTable";
import Ledger from "../views/user_views/forms/materialLedger/MaterialLedger";
import CategoryTableList from "../views/user_views/forms/AddDetails/categories/CategoryTableList";
import AddCategory from "../views/user_views/forms/AddDetails/categories/AddCategory";
import CashPayment from "../views/user_views/forms/Vouchers/CashPayment"
import CashReceipt from "../views/user_views/forms/Vouchers/CashReceipt";
import BankPayment from "../views/user_views/forms/Vouchers/BankPayment";
import BankReceipt from "../views/user_views/forms/Vouchers/BankReceipt";
import VoucherSearch from "../views/user_views/forms/Vouchers/VoucherSearch";
import VoucherSearchDetail from "../views/user_views/forms/Vouchers/VoucherSearchDetail";
import VoucherVerification from "../views/user_views/forms/Vouchers/VoucherVerification";
import AccountLedger from "../views/user_views/forms/ledger/Ledger";
import LedgerTable from "../views/user_views/forms/ledger/LedgerTable";
import JournalVoucher from "../views/user_views/forms/Vouchers/JournalVoucher";
import Level2 from "../views/user_views/forms/accountsLevels/Level2"
import Level2Tap from "../views/user_views/forms/accountsLevels/Level2Tap"
import Level2Upload from "../views/user_views/forms/accountsLevels/Level2Upload"
import Level2Report from "../views/user_views/forms/accountsLevels/Level2Report"
import TrailBalance from "../views/user_views/accounts/trialBalance"
import TransactionSummary from "../views/user_views/forms/accountsTransactionSummary/TransactionSummary"
import TransactionSummaryTable from "../views/user_views/forms/accountsTransactionSummary/TransactionSummaryTable"
import UserDetailView from "../views/admin_views/forms/users/UserDetailView"
import Level3 from "../views/user_views/forms/accountsLevels/Level3"
import Level3Tap from "../views/user_views/forms/accountsLevels/Level3Tap"
import Level3Upload from "../views/user_views/forms/accountsLevels/Level3Upload"
import Level3Report from "../views/user_views/forms/accountsLevels/Level3Report"
import Users from "../views/admin_views/forms/users/Users"
import Level4 from "../views/user_views/forms/accountsLevels/Level4"
import Level4Tap from "../views/user_views/forms/accountsLevels/Level4Tap"
import Level4Upload from "../views/user_views/forms/accountsLevels/Level4Upload"
import Level4Report from "../views/user_views/forms/accountsLevels/Level4Report"
import Level5 from "../views/user_views/forms/accountsLevels/Level5"
import Level5Tap from "../views/user_views/forms/accountsLevels/Level5Tap"
import Level5Upload from "../views/user_views/forms/accountsLevels/Level5Upload"
import Level5Report from "../views/user_views/forms/accountsLevels/Level5Report"
import AddCoupons from "../views/user_views/forms/AddDetails/AddCoupons/AddCoupons";
import POSView from "../views/user_views/forms/AddDetails/POS/POSView";
import ViewOrder from "../views/user_views/forms/AddDetails/POS/ViewOrder";
import KDSView from "../views/user_views/forms/AddDetails/KDS/KDSView";
import TableView from "../views/user_views/forms/AddDetails/POS/TableView";
import ViewCoupons from "../views/user_views/forms/AddDetails/AddCoupons/ViewCoupons";
import AddSize from "../views/user_views/forms/AddDetails/AddSize/AddSize";
import ViewSize from "../views/user_views/forms/AddDetails/AddSize/ViewSize";
import ViewColour from "../views/user_views/forms/AddDetails/AddColour/ViewColour";
import AddColour from "../views/user_views/forms/AddDetails/AddColour/AddColour";
import AddCustomer from "../views/user_views/forms/AddDetails/AddCustomer/AddCustomer";
import ViewCustomer from "../views/user_views/forms/AddDetails/AddCustomer/ViewCustomer";
import AddHall from "../views/user_views/forms/AddDetails/AddHall/AddHall";
import ViewHall from "../views/user_views/forms/AddDetails/AddHall/ViewHall";
import ViewResturant from "../views/user_views/forms/AddDetails/AddResturant/ViewResturant";
import AddResturant from "../views/user_views/forms/AddDetails/AddResturant/AddResturant";
import AddTable from "../views/user_views/forms/AddDetails/AddTable/AddTable";
import ViewTable from "../views/user_views/forms/AddDetails/AddTable/ViewTable";
import OpeningDay from "../views/user_views/forms/AddDetails/Day/OpeningDay";
import ClosingDay from "../views/user_views/forms/AddDetails/Day/ClosingDay";
import ProfitLossStatment from "../views/user_views/forms/ProfitLoss/ProfitLossStatement"
import AddMasterControl from "../views/user_views/forms/AddDetails/AddMasterControl/AddMasterControl";
import ViewMasterControl from "../views/user_views/forms/AddDetails/AddMasterControl/ViewMasterControl";
import ViewUser from "../views/user_views/forms/AddUser/ViewUser";
import AddUser from "../views/user_views/forms/AddUser/AddUser";
import ViewProduct from "../views/user_views/forms/AddDetails/AddProduct/ViewProduct";
import AddProduct from "../views/user_views/forms/AddDetails/AddProduct/AddProduct";
import AddRecipe from "../views/user_views/forms/AddDetails/Recipe/AddRecipe";
import ReportView from "../views/user_views/forms/AddDetails/Reports/ReportView";
import DayWiseSaleReport from "../views/user_views/forms/AddDetails/Reports/DailySaleReport";
import MonthWiseSaleReport from "../views/user_views/forms/AddDetails/Reports/MonthlySaleReport";
import DayWiseDiscountReport from "../views/user_views/forms/AddDetails/Reports/DailyDiscountReport";
import MonthlyDiscountReport from "../views/user_views/forms/AddDetails/Reports/MonthlyDiscountReport";
import DayWiseInvoiceReport from "../views/user_views/forms/AddDetails/Reports/DailyInvoiceReport";
import MonthlyInvoiceReport from "../views/user_views/forms/AddDetails/Reports/MonthlyInvoiceReport";
import DayWiseOrderBilledReport from "../views/user_views/forms/AddDetails/Reports/DailyOrderBilledReport";
import DayWiseOrderCancelReport from "../views/user_views/forms/AddDetails/Reports/DailyOrderCancelReport";
import MonthlyOrderBilledReport from "../views/user_views/forms/AddDetails/Reports/MonthlyOrderBilledReport";
import MonthlyOrderCancelReport from "../views/user_views/forms/AddDetails/Reports/MonthlyOrderCancelReport";
import DayWisePromoReport from "../views/user_views/forms/AddDetails/Reports/DailyPromoReport";
import MonthWisePromoReport from "../views/user_views/forms/AddDetails/Reports/MonthlyPromoReport";
import AddReciving from "../views/user_views/forms/AddDetails/AddReciving/AddReciving";
import RecivingList from "../views/user_views/forms/AddDetails/AddReciving/RecivingList";
import ReceivingReportById from "../views/user_views/forms/AddDetails/AddReciving/ReceivingReportById";
import ReceivingReportWithDate from "../views/user_views/forms/AddDetails/AddReciving/ReceivingReportWithDate";
import ReceivingTableView from "../views/user_views/forms/AddDetails/AddReciving/ReceivingTableView";
import InventoryLedger from "../views/user_views/forms/AddDetails/InventoryLedger/InventoryLedger";
import InventoryLegderDetail from "../views/user_views/forms/AddDetails/InventoryLedger/InventoryLedgerTable";
import SpecificProductSaleReport from "../views/user_views/forms/AddDetails/Reports/SpecificProductSaleReport";
import ProductSaleReport from "../views/user_views/forms/AddDetails/Reports/ProductSaleReport";
import HotSellingReport from "../views/user_views/forms/AddDetails/Reports/HotSellingReport";
import ViewRecipe from "../views/user_views/forms/AddDetails/Recipe/ViewRecipe";
import POSOrderEditView from "../views/user_views/forms/AddDetails/POS/POSOrderEditView ";
import AddVendor from "../views/user_views/forms/AddDetails/Vendor/AddVendor";
import ViewVendor from "../views/user_views/forms/AddDetails/Vendor/ViewVendor";
import BackUp from "../views/user_views/forms/BackUp/BackUp";
import UserPermissions from "../views/user_views/forms/AddDetails/Permissions/UserPermissions";
import AddAging from "../views/user_views/forms/accountsAging/AddAging";
import AccountCashBook from "../views/user_views/forms/accountsCashBook/AccountCashBook";
import TrialBalanceView from "../views/user_views/accounts/trialBalanceView";
import DayOpenRecord from "../views/user_views/forms/AddDetails/Reports/DayOpenRecord";
import DayOpenRecordTable from "../views/user_views/forms/AddDetails/Reports/DayOpenRecordTable";
import CreateDeal from "../views/user_views/forms/AddDetails/AddDeal/CreateDeal";
import ViewDeals from "../views/user_views/forms/AddDetails/AddDeal/ViewDeals";
import TableUpload from "../views/user_views/forms/AddDetails/AddTable/TableUpload";
import AddIssuance from "../views/user_views/forms/AddDetails/Issuance/AddIssuance";
import IssuanceDetail from "../views/user_views/forms/AddDetails/Issuance/IssuanceDetail";
import IssuanceReport from "../views/user_views/forms/AddDetails/Issuance/IssuanceReport";
import IssuanceReportByDate from "../views/user_views/forms/AddDetails/Issuance/IssuanceReportByDate";
import EditResturant from "../views/user_views/forms/AddDetails/AddResturant/EditResturant";
import UploadProduct from "../views/user_views/forms/AddDetails/AddProduct/UploadProduct";
import ViewStoreProduct from "../views/user_views/forms/AddDetails/AddProduct/ViewStoreProduct";
import ViewUom from "../views/user_views/forms/AddDetails/UOM/ViewUom";
import DayOpenReport from "../views/user_views/forms/AddDetails/Reports/DayOpenReport";
import ProductBalacneReport from "../views/user_views/forms/AddDetails/AddProduct/ProductBalacneReport";
import AddUom from "../views/user_views/forms/AddDetails/AddUOM/AddUom";
import POSOrderEditViewCooking from "../views/user_views/forms/AddDetails/POS/POSOrderEditViewCooking";
import ChartOfAccount from "../views/user_views/accounts/ChartOfAccount";
import GenerateBarcode from "../views/user_views/forms/AddDetails/Barcode/GenerateBarcode";
import WarehouseList from "../views/user_views/forms/AddDetails/Warehouse/WarehouseTableList";
import WarehouseAdd from "../views/user_views/forms/AddDetails/Warehouse/AddWarehouse";
import SubCategoryAdd from "../views/user_views/forms/AddDetails/subCategory/AddSubCategory";
import SubCategoryList from "../views/user_views/forms/AddDetails/subCategory/SubCategoryTableList";
import DemandAdd from "../views/user_views/forms/AddDetails/Demand/AddDemand";
import DemandList from "../views/user_views/forms/AddDetails/Demand/DemandList";
import AddBrand from "../views/user_views/forms/AddDetails/Brand/AddBrand";
import BrandList from "../views/user_views/forms/AddDetails/Brand/ViewBrand";
import PurchaseOrderAdd from "../views/user_views/forms/AddDetails/PurchaseOrder/AddPurchaseOrder";
import PurchaseOrderList from "../views/user_views/forms/AddDetails/PurchaseOrder/PurchaseOrderList";
import IgpAdd from "../views/user_views/forms/AddDetails/Igp/IGPAdd";
import IgpList from "../views/user_views/forms/AddDetails/Igp/IGPList";
import AddOrderCreation from "../views/user_views/forms/Production/orderCreation/AddOrderCreation";
import ListOrderCreation from "../views/user_views/forms/Production/orderCreation/ViewOrderCreation";
import ExpenseType from "../views/user_views/forms/AddDetails/expense/AddExpenseTypes";
import ExpenseTypeList from "../views/user_views/forms/AddDetails/expense/ViewExpenseType";


// import AdminInventory from "../views/admin_views/AdminDetails/AdminInventory";
// import AdminDemandDetails from "../views/admin_views/AdminDetails/AdminDemandDetails";
// import PurchaseOrderVerification from "../views/user_views/forms/verifications/PurchaseOrderVerification";
// import ReturnOrderVerification from "../views/user_views/forms/verifications/ReturnOrderVerification";
// import AccountLedger from "../views/user_views/forms/accountsLedger/AccountLedger"




export default function Routing() {
  const location = useLocation();

  React.useEffect(() => {
    console.log('Location changed');
  }, [location]);
  return (
    <Routes>

      {/* <Route path="/" component={Login} /> */}
      <Route path="/" element={<Login />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/home" element={<UserMainPage />}></Route>
      <Route path="/pos" element={<POSView />}></Route>
      <Route path="/report" element={<ReportView />}></Route>
      <Route path="/order" element={<ViewOrder />}></Route>
      <Route path="/table" element={<TableView />}></Route>
      <Route path="/kds" element={<KDSView />} />
      <Route path="/pos/Editpos" element={<POSOrderEditView />} />
      <Route path="/pos/Editposcooking" element={<POSOrderEditViewCooking />} />
      <Route path="/daliy/sale-report" element={< DayWiseSaleReport />} />
      <Route path="/daliy/dayOpen-sale-report" element={< DayOpenReport />} />
      <Route path="/Report/Specific-Product-Sale-report" element={<SpecificProductSaleReport />} />
      <Route path="/Report/Product-Sale-report" element={<ProductSaleReport />} />
      <Route path="/Report/hot-selling-report" element={<HotSellingReport />} />
      <Route path="/monthly/sale-report" element={< MonthWiseSaleReport />} />
      <Route path="/daliy/discount-report" element={<DayWiseDiscountReport />} />
      <Route path="/monthly/discount-report" element={<MonthlyDiscountReport />} />
      <Route path="/daliy/invoice-report" element={<DayWiseInvoiceReport />} />
      <Route path="/daliy/order-billed-report" element={<DayWiseOrderBilledReport />} />
      <Route path="/daliy/orderCancel-report" element={<DayWiseOrderCancelReport />} />
      <Route path="/monthly/orderCancel-report" element={<MonthlyOrderCancelReport />} />
      <Route path="/daliy/promo-report" element={<DayWisePromoReport />} />
      <Route path="/monthly/promo-report" element={<MonthWisePromoReport />} />
      {/* <Route path="/monthly/invoice-report" element={<MonthlyInvoiceReport />} /> */}
      {/* <Route path="/monthly/order-billed-report" element={<MonthlyOrderBilledReport />} /> */}



      {/* INVENTORY ROUTES */}
      {true && (
        <Route path="/inventory" element={<Dashboard />}>

          <Route path="/inventory/issuance/addIssuance" element={<AddIssuance />} />
          <Route path="/inventory/issuance/IssuanceDetail" element={<IssuanceDetail />} />
          <Route path="/inventory/issuance/IssuanceById" element={<IssuanceReport />} />
          <Route path="/inventory/issuance/IssuanceWithDate" element={<IssuanceReportByDate />} />
          <Route path="/inventory/Vendor/addVendor" element={<AddVendor />} />
          <Route path="/inventory/uomlist" element={<ViewUom />} />
          <Route path="/inventory/permission" element={<UserPermissions />} />
          <Route path="/inventory/Vendor/listVendor" element={<ViewVendor />} />
          <Route path="/inventory/deal/addDeal" element={<CreateDeal />} />
          <Route path="/inventory/deal/dealList" element={<ViewDeals />} />
          <Route path="/inventory/Coupon/AddCoupon" element={<AddCoupons />} />
          <Route path="/inventory/Coupon/listCoupon" element={<ViewCoupons />} />
          <Route path="/inventory/Size/addSize" element={<AddSize />} />
          <Route path="/inventory/Size/listsize" element={<ViewSize />} />
          <Route path="/inventory/barcode/generateBarcode" element={<GenerateBarcode />} />
          <Route path="/inventory/Colour/addColour" element={<AddColour />} />
          <Route path="/inventory/Colour/listColour" element={<ViewColour />} />
          <Route path="/inventory/uom/adduom" element={<AddUom />} />
          <Route path="/inventory/uom/listuom" element={<ViewUom />} />
          <Route path="/inventory/Customer/addCustomer" element={<AddCustomer />} />
          <Route path="/inventory/Customer/listCustomer" element={<ViewCustomer />} />
          <Route path="/inventory/Hall/addHall" element={<AddHall />} />
          <Route path="/inventory/Hall/listHall" element={<ViewHall />} />
          <Route path="/inventory/Restaurant/addRestaurant" element={<AddResturant />} />
          <Route path="/inventory/Restaurant/listRestaurant" element={<ViewResturant />} />
          <Route path="/inventory/Table/addTable" element={<AddTable />} />
          <Route path="/inventory/Table/uploadTable" element={<TableUpload />} />
          <Route path="/inventory/Table/listTable" element={<ViewTable />} />
          <Route path="/inventory/User/addUser" element={<AddUser />} />
          <Route path="/inventory/User/listUser" element={<ViewUser />} />
          <Route path="/inventory/Product/addProduct" element={<AddProduct />} />
          <Route path="/inventory/product/uploadProduct" element={<UploadProduct />} />
          <Route path="/inventory/Product/listProduct" element={<ViewProduct />} />
          <Route path="/inventory/Product/storeProductList" element={<ViewStoreProduct />} />
          <Route path="/inventory/product/ProductBalance" element={<ProductBalacneReport />} />
          <Route path="/inventory/Product/addReciving" element={<AddReciving />} />
          <Route path="/inventory/Product/listReciving" element={<RecivingList />} />
          <Route path="/inventory/Product/RecivingReport" element={<ReceivingReportById />} />
          <Route path="/inventory/Product/RecivingReportDate" element={<ReceivingReportWithDate />} />
          <Route path="/inventory/Product/RecivingReportDate/TableView" element={<ReceivingTableView />} />
          <Route path="/inventory/Recipe/addRecipe" element={<AddRecipe />} />
          <Route path="/inventory/Recipe/listRecipe" element={<ViewRecipe />} />
          <Route path="/inventory/ledger" element={<InventoryLedger />} />
          <Route path="/inventory/Ledger-List" element={<InventoryLegderDetail />} />
          <Route path="/inventory/catagory/addcatagory" element={<AddCategory />} />
          <Route path="/inventory/catagory/listcatagory" element={<CategoryTableList />} />
          <Route path="/inventory/materialLedger" element={<Ledger />} MaterialLedgerTable />
          <Route path="/inventory/material-ledger-details" element={<MaterialLedgerTable />} MaterialLedgerTable />
          <Route path="/inventory/warehouse/warehousAdd" element={<WarehouseAdd />} />
          <Route path="/inventory/warehouse/warehouseList" element={<WarehouseList />} />
          <Route path="/inventory/subCategory/subCategoryAdd" element={<SubCategoryAdd />} />
          <Route path="/inventory/subCategory/subCategoryList" element={<SubCategoryList />} />
          <Route path="/inventory/demand/DemandAdd" element={<DemandAdd />} />
          <Route path="/inventory/demand/DemandList" element={<DemandList />} />
          <Route path="/inventory/brand/addBrand" element={<AddBrand />} />
          <Route path="/inventory/brand/brandList" element={<BrandList />} />
          <Route path="/inventory/purchase/PurchaseOrderAdd" element={<PurchaseOrderAdd />} />
          <Route path="/inventory/purchase/PurchaseOrderList" element={<PurchaseOrderList />} />
          <Route path="/inventory/igp/IgpAdd" element={<IgpAdd />} />
          <Route path="/inventory/igp/IgpList" element={<IgpList />} />
          <Route path="/inventory/expense/expenseType" element={<ExpenseType />} />
          <Route path="/inventory/expense/expenseTypeList" element={<ExpenseTypeList />} />


        </Route>
      )}


      {/* ACCOUNTS ROUTES */}
      {true && (
        <Route path="/accounts" element={<Dashboard />}>
          <Route path="/accounts/vouchers/cashpayment" element={<CashPayment />} />
          <Route path="/accounts/trial-balance-report" element={<TrialBalanceView />} />
          <Route path="/accounts/vouchers/cashreceipt" element={<CashReceipt />} />
          <Route path="/accounts/vouchers/bankpayment" element={<BankPayment />} />
          <Route path="/accounts/vouchers/bankreceipt" element={<BankReceipt />} />
          <Route path="/accounts/vouchers/journal" element={<JournalVoucher />} />
          <Route path="/accounts/voucherverification" element={<VoucherVerification />} />
          <Route path="/accounts/vouchersearch" element={<VoucherSearch />} />
          <Route path="/accounts/voucher-search-detail" element={<VoucherSearchDetail />} />
          <Route path="/accounts/ledger" element={<AccountLedger />} />
          <Route path="/accounts/Ledger-List" element={<LedgerTable />} />
          <Route path="/accounts/level2/add" element={<Level2 />} />
          <Route path="/accounts/level2/details" element={<Level2Tap />} />
          <Route path="/accounts/level2/upload" element={<Level2Upload />} />
          <Route path="/accounts/level2/report" element={<Level2Report />} />
          <Route path="/accounts/level3/add" element={<Level3 />} />
          <Route path="/accounts/level3/details" element={<Level3Tap />} />
          <Route path="/accounts/level3/upload" element={<Level3Upload />} />
          <Route path="/accounts/level3/report" element={<Level3Report />} />
          <Route path="/accounts/level4/add" element={<Level4 />} />
          <Route path="/accounts/level4/details" element={<Level4Tap />} />
          <Route path="/accounts/level4/upload" element={<Level4Upload />} />
          <Route path="/accounts/level4/report" element={<Level4Report />} />
          <Route path="/accounts/level5/add" element={<Level5 />} />
          <Route path="/accounts/level5/details" element={<Level5Tap />} />
          <Route path="/accounts/level5/upload" element={<Level5Upload />} />
          <Route path="/accounts/level5/report" element={<Level5Report />} />
          <Route path="/accounts/trailbalance" element={<TrailBalance />} />
          <Route path="/accounts/chartofAccount" element={<ChartOfAccount />} />
          <Route path="/accounts/transaction-summary" element={<TransactionSummary />} />
          <Route path="/accounts/addaging" element={<AddAging />} />
          <Route path="/accounts/cash-book" element={<AccountCashBook />} />
          <Route path="/accounts/profit-loss" element={<ProfitLossStatment />} />
          <Route path="/accounts/transaction-summary-details" element={<TransactionSummaryTable />} />
        </Route>
      )}


      {/* SETTING ROUTES */}
      {true && (
        <Route path="/setting" element={<Dashboard />}>
          <Route path="/setting/opening" element={<OpeningDay />} />
          <Route path="/setting/closing" element={<ClosingDay />} />
          <Route path="/setting/addUser" element={<AddUser />} />
          <Route path="/setting/listUser" element={<ViewUser />} />
          <Route path="/setting/permission" element={<UserPermissions />} />
          <Route path="/setting/dayOpen_record" element={<DayOpenRecord />} />
          <Route path="/setting/dayOpen_record_table" element={<DayOpenRecordTable />} />
          <Route path="/setting/MasterControl/addMasterControl" element={<AddMasterControl />} />
          <Route path="/setting/MasterControl/listMasterControl" element={<ViewMasterControl />} />
          <Route path="/setting/Company/addCompany" element={<AddResturant />} />
          <Route path="/setting/Company/listCompany" element={<ViewResturant />} />
          <Route path="/setting/Company/editCompany" element={<EditResturant />} />
          <Route path="/setting/BackUp" element={<BackUp />} />
        </Route>
      )}


      {/* PRODUCTION ROUTES */}
      {true && (
        <Route path="/production" element={<Dashboard />}>
          <Route path="/production/OrderCreation/AddOrderCreation" element={<AddOrderCreation />} />
          <Route path="/production/OrderCreation/ListOrderCreation" element={<ListOrderCreation />} />
        </Route>
      )}


      {/* store */}
      {true && (
        <Route path="/store" element={<Dashboard />}>

        </Route>
      )}



      {/* EXTRA ROUTES */}
      {true && (
        <Route path="/admin" element={<Dashboard />}>
          <Route path="/admin/vendor" element={<Users />} />
          <Route path="/admin/userdetailview" element={<UserDetailView />} />
          {/* <Route path="/admin/inventory" element={<AdminInventory />} /> */}
          {/* <Route path="/admin/admindemanddetails" element={<AdminDemandDetails />} /> */}
          {/* <Route path="/admin/purchaseorderverification" element={<PurchaseOrderVerification />} /> */}
        </Route>
      )}
      {true && (
        <Route path="/admin" element={<Dashboard />}>
          <Route path="/admin/voucherverification" element={<VoucherVerification />} />
          <Route path="/admin/accountledger" element={<AccountLedger />} />
        </Route>
      )}




    </Routes>)
}