import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Button from '@mui/material/Button';
import _ from 'lodash'
import SendIcon from '@mui/icons-material/Send';
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import { getIssuance, searchIssuanceReport } from "./Issuance.action";
import { AsyncPaginate } from 'react-select-async-paginate';
import { baseURL, headers } from "../../../../../global/api";


const IssuanceReport = (props) => {
  const [value, setValue] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  const handleChangeGetIssuance = (selectedOption) => {
    setIssuanceNameValue(selectedOption.value);
    setIssuanceNameLabel(selectedOption.label);
  };
  const getIssuanceList = props.IssuanceList.map((x) => {
    let data = { value: x._id, label: x.issuanceDate + " " + x.issuedBy };
    return data;
  });

  const getIssuanceList1 = (data) => data.map((x) => {
    let data = { value: x._id, label: x.issuanceDate + "/" + x.issuanceDesc + "/" + x.department.department };
    return data;
  })

  const exportPDF = () => {
    props.searchIssuanceReport({
      issuanceId: issuanceNameValue,
    },
      issuanceNameLabel,
    );
  };

  useEffect(() => {
    // props.getIssuance();
  }, []);

  async function loadOptions(search, loadedOptions, { page }) {
    const response = await fetch(`${baseURL}/issuance/?page=${!_.is(search) ? "" : page}&limit=${!_.isEmpty(search) ? "" : 5}&search=${search}`, { headers: headers });
    const responseJSON = await response.json();
    let check = responseJSON.total_results / responseJSON.limit;
    let check1 = _.parseInt(check) != responseJSON.page ? true : false
    return {
      options: _.isEmpty(responseJSON.data) ? [] : getIssuanceList1(responseJSON.data),
      hasMore: _.isEmpty(responseJSON.data) ? false : check1,
      additional: {
        page: page + 1,
      },
    };
  }
  return (
    <>
      {props.isFetchingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Issuance *</b>
                  </Form.Label>
                  {/* <Select
                    placeholder="Select Issuance..."
                    onChange={(e) => handleChangeGetIssuance(e)}
                    options={getIssuanceList}
                  /> */}
                  <AsyncPaginate
                    value={value}
                    loadOptions={loadOptions}
                    onChange={(e) => { handleChangeGetIssuance(e); setValue(e) }}
                    additional={{ page: 0 }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {_._.isEmpty(issuanceNameValue) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>Generate Issuance Report </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={() => exportPDF()}> Generate Issuance Report </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getIssuance: () => dispatch(getIssuance()),
    searchIssuanceReport: (data, issuanceName) => dispatch(searchIssuanceReport(data, issuanceName)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingIssuance: state.IssuanceReducer.isFetchingIssuance,
  IssuanceList: state.IssuanceReducer.IssuanceList
});

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceReport);
