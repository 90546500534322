import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import jsPDF from "jspdf";
import logo from "../../../../Images/logo.png";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../global/api";
import { UpdateUserRes, deleteUserRes, getUserRes } from "./user.actions";
import { isEmpty } from "lodash";
import { setHeading } from "../../accounts/account.actions";


const ViewUser = (props) => {
  let rollData = [
    {
      roll_id: 1,
      roll_name: "manager",
    },
    {
      roll_id: 2,
      roll_name: "Staff",
    },
    {
      roll_id: 3,
      roll_name: "Admin",
    },
  ];
  let roleArry = ["manager", "staff", "admin"];
  const rollList =
    !isEmpty(rollData) &&
    rollData.map((x) => {
      let data = { value: x.roll_id, label: x.roll_name };
      return data;
    });
  useEffect(() => {
    props.setHeading("User List")
  }, []);
  return (
    <>
      {props.isFetchingUser ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>

          <div className="main">
            <MaterialTable
              style={{ zIndex: 0 }}
              title="User"
              columns={[
                { title: "Company", field: "restaurant.restaurantName", editable: 'never' },
                { title: "Business Locaton", field: "wareHouseId.businessName", editable: 'never' },
                {
                  title: "Role", field: "role",
                  editComponent: ({ value, onChange }) => (
                    <select onChange={(e) => onChange(e.target.value)} style={{ padding: '10px' }}>
                      <option selected value={value}>
                        {value}
                      </option>
                      {roleArry.map((item) => item !== value && (<option key={item} value={item}>{item}</option>))}
                    </select>
                  )
                },
                { title: "User Name", field: "userName", editable: 'never' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/users/?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      // data:rollData,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                filtering: false,
                exportButton: true,
                exportAllData: true,
                exportPdf: (columns, data) => {
                  const doc = new jsPDF();
                  //render:rowData=>rowData.hall_name
                  const columnTitles = [
                    { title: "Company", field: "restaurant.restaurantName", render: (rowData) => rowData.userName },
                    { title: "Business Locaton", field: "wareHouseId.businessName", render: (rowData) => rowData.wareHouseId.businessName },
                    { title: "Role", field: "role", render: (rowData) => rowData.role },
                    { title: "User Name", field: "userName", render: (rowData) => rowData.userName },

                  ].map((columnDef) => columnDef.title);
                  const companyName = `${process.env.REACT_APP_COMPANY_NAME}`;
                  const companyAddress = `${process.env.REACT_APP_COMPANY_ADDRESS}`;
                  const companyNtn = `${process.env.REACT_APP_COMPANY_NTN}`;
                  const pdfData = data.map((rowData) => [
                    rowData.userName,
                    rowData.wareHouseId.businessName,
                    rowData.role,
                    rowData.userName,
                  ]);
                  let content = {
                    startY: 50,
                    startX: 5,
                    head: [columnTitles],
                    body: pdfData,
                  };
                  doc.addImage(logo, "PNG", 10, 1, 50, 50);
                  doc.setFontSize(20);
                  doc.setFont(undefined, "bold");
                  doc.text(companyName, 50, 22, 0, 0);
                  doc.setFontSize(13);
                  doc.setFont("TimesNewRoman", "Normal");
                  doc.text(companyAddress, 50, 29, 0, 0);
                  doc.text(companyNtn, 50, 36, 0, 0);
                  doc.autoTable(content);
                  doc.save(`User Table.pdf`);
                },
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.UpdateUser(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteUser(oldData._id);
                      console.log(oldData, "old");
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(getUserRes()),
    deleteUser: (id) => dispatch(deleteUserRes(id)),
    UpdateUser: (newData, oldData) => dispatch(UpdateUserRes(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  isFetchingUser: state.UserReducer.isFetchingUser,
  userList: state.UserReducer.userList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewUser);
