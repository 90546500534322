import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import { addSubCategoryApi, resetReducerSubCategory } from "./SubCategory.action";
// import { getWarehouseApi } from "../Warehouse/Warehouse.action";
import { getCategoryWithWarehouseApi } from "../categories/Catagory.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { setHeading } from "../../../accounts/account.actions";


const AddSubCategory = (props) => {
  const [subCategory, setSubCategoryName] = useState("");
  // const [warehouseId, setWarehouseId] = useState();
  // const [warehouseName, setWarehouseName] = useState();
  const [retaurantId, setRestaurantId] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [CategoryId, setCategoryId] = useState();
  const [Categoryname, setCategoryName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadSubCategory() {
    props.addSubCategory({
      subCategoryName: subCategory,
      categoryId: CategoryId,
      // wareHouseId: warehouseId,
      retaurantId: retaurantId,
    },
      handleVisible,
      handleDangerVisible
    );
    setRestaurantName("");
    setCategoryName("");
    setSubCategoryName("");
    // props.reset();
  }

  // const handleChangeWarehouse = (selectedOption) => {
  //   setWarehouseId(selectedOption.value);
  //   setWarehouseName(selectedOption.label);
  //   props.getCategoryWithWarehouseApi(selectedOption.value);
  // };
  // const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
  //   let data = { value: x._id, label: x.businessName };
  //   return data;
  // });
  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    props.getCategoryWithWarehouseApi(selectedOption.value);
  };
  const getRetaurantList = !_.isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  const handleChangeCategory = (selectedOption) => {
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
  };
  const getCategoryList = !_.isEmpty(props.categoryListByWarehouseId.data) && props.categoryListByWarehouseId.data.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });

  useEffect(() => {
    // props.getWarehouseApi();
    props.getRestaurantData();
    props.setHeading("New Sub-Category");
  }, []);
  return (
    <>
      {props.isFetchingRestaurant || props.isAddingSubCategory ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingRestaurant ? 'Loading...' : 'Loading...'}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" xs="12">
                <Form.Group className="mb-1 mt-1" >
                  <Form.Label>
                    <b>Select Company *</b>
                  </Form.Label>
                  <Select
                    value={{ label: restaurantName }}
                    placeholder="Select Company"
                    onChange={handleChangeRestaurant}
                    options={getRetaurantList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" xs="12">
                <Form.Group className="mb-1 mt-1" >
                  <Form.Label>
                    <b>Select Category *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Category"
                    isDisabled={_.isEmpty(props.categoryListByWarehouseId) || _.isEmpty(retaurantId)}
                    onChange={handleChangeCategory}
                    options={getCategoryList}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-1 mt-1">
                  <Form.Label>
                    <b>Sub Category Name *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Sub Category Name"
                    onMouseLeave={(e) => setSubCategoryName(e.target.value)}
                    required
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {_.isEmpty(subCategory) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>
                  SAVE
                </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadSubCategory}>
                  SAVE
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    reset: () => dispatch(resetReducerSubCategory),
    addSubCategory: (data, handleVisible, handleDangerVisible) => dispatch(addSubCategoryApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
    // getWarehouseApi: () => dispatch(getWarehouseApi()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getCategoryWithWarehouseApi: (id) => dispatch(getCategoryWithWarehouseApi(id)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingSubCategory: state.SubCategoryReducer.isAddingSubCategory,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
  categoryListByWarehouseId: state.CategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId: state.CategoryReducer.isFetchingCategoryByWarehouseId,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddSubCategory);
