import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import "../add-details.css";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getProductTypeProduct } from "../Recipe/Recipe.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { addReceiving } from "./Receiving.action";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddReceving = (props) => {
  const [inputField1, setInputField1] = useState([
    {
      productId: 0,
      ProductName: "",
      availableProductQuantity: 0,
      requiredProductQuantity: 0,
      productPrice: 0,
      marketPrice: 0,
      // uom: "",
      // uomName: "",
      // uomPrice:0,
      // quantity: 0,
      // price: 0,
    },
  ]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [wareHouseId, setwareHouseId] = useState("");
  const [wareHouselabel, setwareHouseLabel] = useState("");
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [requistionLabel, setRequistionLabel] = useState();
  const [department, setdepartment] = useState();
  const [issuedBy, setIssuedBy] = useState();
  const [receivedBy, setReceivedBy] = useState("");
  const [requistionType, setRequistionType] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [restaurantId, setRestaurantId] = useState("");
  const [restaurantname, setRestaurantName] = useState("");
  const [dangerVisibleAlertProduct, setDangerVisibleAlertProduct] = useState(false);
  const [barcode, setBarocode] = useState("");


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.addReceiving(
      {
        receivingDate: date,
        // requsitionType: "abc",//requistion,
        // department: "abc",//department,
        //issuedBy:"abc", //issuedBy,
        receivedBy: receivedBy,
        recivingDescription: requistionType,
        receivingData: inputField1,
        restaurantId: restaurantId,
        // barcodeId:barcode,
      },
      handleVisible,
      handleDangerVisible
    );
    setRequistionType("");
    setReceivedBy("");
    setIssuedBy("");
    setRequistionLabel("");
    setdepartment("");
    setRequistion("-1");
    setInputField1([
      {
        productId: 0,
        ProductName: "",
        availableProductQuantity: 0,
        requiredProductQuantity: 0,
        productPrice: 0,
        // uom: "",
        // uomName: "",
        // uomPrice:0,
        // quantity: 0,
        marketPrice: 0,
      },
    ]);
  }

  const handleAddInput = () => {
    setInputField1([
      ...inputField1,
      {
        productId: 0,
        ProductName: "",
        availableProductQuantity: 0,
        requiredProductQuantity: 0,
        productPrice: 0,
        marketPrice: 0,
        // uom: "",
        // uomName: "",
        // uomPrice:0,
        // quantity: 0,
        // price: 0,
      },
    ]);
  };
  //remove for add fuctio
  const handleRemoveInput = (index) => {
    const list = [...inputField1];
    list.splice(index, 1);
    setInputField1(list);
  };
  const handleChange1 = (e, index, type, itemId) => {
    //  let z = find(props.allInventory, (x) => x._id == e.value);
    let z = find(props.hybridAndCompleteData, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField1];
    if (type == "master") list[index]["productId"] = e.value;
    if (type == "master") list[index]["ProductName"] = e.label;
    if (type == "master") list[index]["availableProductQuantity"] = e.productQuantityCount;
    if (type == "master") list[index]["productPrice"] = e.productPrice;
    if (type == "master") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["requiredProductQuantity"] = e.target.value < 0 ? alert("cannot added less than 0") : e.target.value;
    //     if (type == "master") list[index]["uomPrice"] = Number( e.labelPrice);
    //     if (type == "quantity") list[index]["quantity"] = Number(e.target.value);
    //     // if (type == "quantity") list[index]["quantityLabel"] = e.label;
    //     // if (type == 'intname') setMaximumQuantity(Number(z.currentBlnc));
    //    // if (type == "quantity") list[index]["quantity"]=let damyprice=Number(e.target.value)*Number( e.labelPrice);
    //     if (type == "quantity") list[index]["price"] = Number(e.target.value)*list[index]["uomPrice"];//(Number(list[index]["uomPrice"])*Number(list[index]["quantity"]));
    // if (type == "master")
    // list[index]["price"]==0? e.stockPrice:list[index]["price"];
    if (type == "intPrice") list[index]["marketPrice"] = e.target.value;
    setInputField1(list);
  };

  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
  };
  const getRestaurantList = !isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  const getMList = !isEmpty(props.hybridAndCompleteData) && props.hybridAndCompleteData.map((x) => {
    let data = {
      value: x._id,
      label: x.name,
      productQuantityCount: x.productBalance,
      productPrice: x.purchasePrice
    };
    return data;
  });

  // const barcod = (id) => {
  //   let i = find(props.hybridAndCompleteData, { barcodeId: id });
  //   let b;
  //   isEmpty(i)
  //     ? handleDangerVisibleProduct()
  //     : (b = find(getMList, { value: i._id }));
  //   handleChange1(b, inputField1.length - 1, "master");
  // };
  // useScanDetection({
  //   onComplete: barcod,
  //   //  minLength: 13 // EAN13
  // });
  useEffect(() => {
    props.getMatrial();
    props.getRestaurantData();
  }, []);

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isAddingReceiving ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingReceiving ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3" >
                  <Form.Label>
                    <b>Select Company *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.restaurantDataList)}
                    placeholder="Select Company"
                    onChange={handleChangeRestaurant}
                    options={getRestaurantList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date </b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="Date"
                    value={date}
                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By"
                    name="receivedBy"
                    value={receivedBy}
                    onChange={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="requistionType"
                    value={requistionType}
                    onChange={(e) => setRequistionType(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {inputField1.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col>
                      <Form.Group>
                        <Form.Label> <b> Select Product *</b> </Form.Label>
                        <Select
                          style={{ zIndex: 1 }}
                          value={{ label: item.ProductName, }}
                          placeholder="Select Product"
                          name="master"
                          onChange={(e) => handleChange1(e, i, "master")}
                          options={getMList}
                          isOptionDisabled={(option) => (!isEmpty(find(inputField1, (x) => x.productId == option.value)))}
                        />
                      </Form.Group>
                    </Col>

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label> <b>Available Quantity *</b> </Form.Label>
                        <Form.Control
                          placeholder="Available Quantity"
                          name="productQuantityCount"
                          disabled
                          value={item.availableProductQuantity}
                        />
                      </Form.Group>
                    </Col>
                    {/* <Col>
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Stock Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Stock Price"
                          name="productQuantityCount"
                          disabled
                          value={item.productPrice}
                        />
                      </Form.Group>
                    </Col> */}

                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label><b>Add Quantity *</b> </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity"
                          name="productQuantity"
                          value={item.requiredProductQuantity}
                          onChange={(e) => handleChange1(e, i, "intquantity", item.ProductId)}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-3">
                        <Form.Label> <b>Price *</b></Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Price"
                          name="Price"
                          value={item.marketPrice}
                          onChange={(e) => handleChange1(e, i, "intPrice", item.ProductId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField1.length - 1 == i && (
                          <Button
                            title="Click to Add Receving"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField1.length !== 1 && (
                          <Button
                            title="Click to Remove Receving"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <div className="sendDiv">
              {isEmpty(restaurantId) || isEmpty(receivedBy) || inputField1[0].productId == "" || inputField1[0].requiredProductQuantity == 0 ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadPurchase} > SAVE </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addReceiving: (data, handleVisible, handleDangerVisible) => dispatch(addReceiving(data, handleVisible, handleDangerVisible)),
    getMatrial: () => dispatch(getHybridAndCompleteData()),
    getRestaurantData: () => dispatch(getRestaurantData()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  isAddingReceiving: state.RecivingReducer.isAddingReceiving,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddReceving);
