import React, { useEffect } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import MaterialTable from "material-table";
import { setHeading } from "../../../accounts/account.actions";


const DayOpenRecordTable = (props) => {

  useEffect(() => {
    props.setHeading("DayOpen Record")
  }, []);

  return (
    <>
      {props.isFetchingDayOpenData ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isFetchingDayOpenData ? "Loading..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div className="sendDiv" style={{ marginTop: "15px" }}>
              <Link to="/setting/dayOpen_record">
                <Button >
                  Back
                </Button>
              </Link>
            </div>
            <div className="table">

              <MaterialTable
                title={"DayOpen Details"}
                columns={[
                  { title: "Date", field: "dayOpenDate" },
                  { title: "DayOpen Name", field: "dayOpenName" },
                  { title: "Status", field: "status" },
                  { title: "Cash In Hand", field: "cashInHand" },
                  { title: "Closing Cash", field: "closingCash" },
                  { title: "Order Amount", field: "todaysOrderAmount" },
                  { title: "Order Length", field: "todaysOrderLength" },
                  { title: "Discount Amount", field: "todaysDiscountAmount" },
                  { title: "Cancelled Amount", field: "todaysCancelledAmount" },
                  { title: "Cancelled Order Length", field: "todaysCancelledOrderLength" },
                  //   { title: "Voucher Date", field: "voucherDate", render: rowData => moment(rowData.voucherDate).format("L") }
                ]}
                data={props.dayOpenData.data}
                options={{
                  actionsColumnIndex: -1,
                  filtering: false,
                  exportButton: true,
                  paging: true,
                  pageSize: 200,
                  emptyRowsWhenPaging: false,
                  pageSizeOptions: [50, 100, 150, 200],
                  headerStyle: {
                    position: "sticky",
                    top: 0,
                    color: "#00BBBB",
                    fontWeight: "550",
                    onRowAdd: "none",
                  },
                }}
              />
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeading: (data) => dispatch(setHeading(data))
  };
};
const mapStateToProps = (state, ownProps) => ({
  dayOpenData: state.ReportReducer.dayOpenData,
  isFetchingDayOpenData: state.ReportReducer.isFetchingDayOpenData,
});

export default connect(mapStateToProps, mapDispatchToProps)(DayOpenRecordTable);
