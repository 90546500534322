import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { getBrandApi, deleteBrandApi, updateBrandApi } from "./Brand.action";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";


const ViewBrand = (props) => {

  useEffect(() => {
    props.setHeading("Brand List");
  }, []);

  return (
    <>
      {props.isFetchingBrand ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialSize
              title="Brand Details"
              columns={[
                { title: "Brand Name", field: "brandName" },
                { title: "Brand Description", field: "brandDetails" },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/brand?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: "GET",
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                // filtering: true,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50, 
                emptyRowsWhenPaging: false, 
                pageSizeOptions: [30, 50, 100, 150, 200], 
                headerStyle: {
                  position: "sticky",
                  top: 0,
                  color: "#00BBBB",
                  fontWeight: "550",
                  onRowAdd: "none",
                },
              }}
              editable={{
                onRowUpdate: (newData, oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      {
                        props.updateBrandApi(newData, oldData);
                      }
                    }, 600);
                  }),
                onRowDelete: (oldData) =>
                  new Promise((resolve) => {
                    setTimeout(() => {
                      resolve();
                      props.deleteBrandApi(oldData._id);
                    }, 600);
                  }),
              }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getBrandApi: () => dispatch(getBrandApi()),
    deleteBrandApi: (id) => dispatch(deleteBrandApi(id)),
    updateBrandApi: (newData, oldData) => dispatch(updateBrandApi(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  brandDataList: state.brandReducer.brandDataList,
  isFetchingBrand: state.brandReducer.isFetchingBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewBrand);
