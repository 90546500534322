import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
import { updatePurchaseApi, deletePurchaseApi, updatePurchaseStatusApi, individualPDF } from "./PurchaseOrder.action";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


const PurchaseOrderList = (props) => {
    const permissions = JSON.parse(localStorage.getItem("permissions"));
    const Checkpermissions = (permissionsType, permissionsName) => {
        return permissions.some((p) => p[`${permissionsType}`] === permissionsName) ? false : true;
    };
    useEffect(() => {
        props.setHeading("Purchase Order List");
    }, []);

    return (
        <>
            {props.isFetchingPurchase ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="PurchaseOrder Details"
                            columns={[
                                { title: "Seq Number", field: "seqNumber" },
                                { title: "Manual PO No.", field: "manualPurchaseNumber" },
                                { title: "Date", field: "purchaseDate" },
                                { title: "Purchaser Name", field: "purchaserName" },
                                { title: "Suplier Name", field: "suplierName" },
                                { title: "Description", field: "description" },
                                { title: "Company", field: "retaurantId.restaurantName", editable: false },
                                { title: "Status", field: "status", editable: false },
                            ]}
                            detailPanel={rowData => {
                                return (
                                    <MaterialSize
                                        columns={[
                                            { title: 'Product Name', field: 'inventoryLabel' },
                                            { title: 'PO Demand Quantity', field: 'demandedQty' },
                                            { title: 'Remain', field: 'remainingIgp' },
                                        ]}
                                        data={rowData.data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [30, 50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/purchase?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            editable={{
                                isEditHidden: rowData => rowData.status == 'Approved' ? true : rowData.status == 'Completed' ? true : false,
                                isDeleteHidden: rowData => rowData.status == 'Approved' ? true : rowData.status == 'Completed' ? true : false,
                                onRowUpdate: (newData, oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            {
                                                props.updatePurchaseApi(newData, oldData);
                                            }
                                        }, 600);
                                    }),
                                onRowDelete: (oldData) =>
                                    new Promise((resolve) => {
                                        setTimeout(() => {
                                            resolve();
                                            props.deletePurchaseApi(oldData._id);
                                        }, 600);
                                    }),
                            }}
                            actions={[
                                (rowData) => ({
                                    disabled: rowData.status == 'Pending' ? false : true,
                                    icon: () => <CheckCircleOutlineIcon />,
                                    hidden: Checkpermissions(
                                        "permissionsSubCatName",
                                        "Purchase Order Approve"
                                    ),
                                    tooltip: "Approve PurchaseOrder",
                                    onClick: (event, rowData) => {
                                        props.updatePurchaseStatusApi(rowData._id);
                                    },
                                }),
                                (rowData) => ({
                                    icon: () => <LocalPrintshopIcon />,
                                    tooltip: "Print PurchaseOrder",
                                    onClick: (event, rowData) => {
                                        props.individualPDF(rowData);
                                    },
                                }),
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};


const mapDispatchToProps = (dispatch) => {
    return {
        deletePurchaseApi: (id) => dispatch(deletePurchaseApi(id)),
        updatePurchaseApi: (newData, oldData) => dispatch(updatePurchaseApi(newData, oldData)),
        updatePurchaseStatusApi: (purchaseId) => dispatch(updatePurchaseStatusApi(purchaseId)),
        setHeading: (data) => dispatch(setHeading(data)),
        individualPDF: (data) => dispatch(individualPDF(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    purchaseList: state.PurchaseReducer.purchaseList,
    isFetchingPurchase: state.PurchaseReducer.isFetchingPurchase,
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderList);