import API from "../../../../../global/api";


export const requestCustomerData = () => {
  return {
    type: "REQUEST_CUSTOMER_GET_DATA",
  };
};
export const successCustomerGetData = (data) => {
  return {
    type: "SUCCESS_CUSTOMER_GET_DATA",
    payload: data,
  };
};
export const errorCustomerGetData = () => {
  return {
    type: "ERROR_CUSTOMER_GET_DATA",
  };
};
export const getCustomerData = () => {
  return (dispatch) => {
    dispatch(requestCustomerData());
    API.get(`/customers?limit=1000&page=0&search`).then((res) => {
      let getData = res.data;
      dispatch(successCustomerGetData(getData));
    }).catch((error) => {
      dispatch(errorCustomerGetData());
    });
  };
};


export const requestAddCustomer = () => {
  return {
    type: "REQUEST_ADD_CUSTOMER",
  };
};
export const successAddCustomer = (data) => {
  return {
    type: "SUCCESS_ADD_CUSTOMER",
    payload: data,
  };
};
export const errorAddCustomer = () => {
  return {
    type: "ERROR_ADD_CUSTOMER",
  };
};
export const addCustomer = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddCustomer());
    API.post(`/customers`, data).then((res) => {
      dispatch(successAddCustomer(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddCustomer());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const DeleteRequestCustomer = () => {
  return {
    type: "DELETE_REQUEST_CUSTOMER",
  };
};
export const DeleteSuccessCustomer = (user_id) => {
  return {
    type: "DELETE_SUCCESS_CUSTOMER",
    user_id: user_id,
  };
};
export const DeleteErrorCustomer = () => {
  return {
    type: "DELETE_ERROR_CUSTOMER",
  };
};
export const deleteCustomer = (vendorId) => {
  return (dispatch) => {
    dispatch(DeleteRequestCustomer());
    API.delete(`/customers/${vendorId}`).then((res) => {
      dispatch(DeleteSuccessCustomer(vendorId));
    }).catch((error) => {
      dispatch(DeleteErrorCustomer());
    });
  };
};


export const UpdateRequestCustomer = () => {
  return {
    type: "UPDATE_REQUEST_CUSTOMER",
  };
};
export const UpdateSuccessCustomer = (data) => {
  return {
    type: "UPDATE_SUCCESS_CUSTOMER",
    payload: data,
  };
};
export const UpdateErrorCustomer = () => {
  return {
    type: "UPDATE_ERROR_CUSTOMER",
  };
};
export const updateCustomer = (data, oldData) => {
  return (dispatch) => {
    dispatch(UpdateRequestCustomer());
    API.put(`/customers/${oldData._id}`, {
      name: data.name,
      email: data.email,
      phone: data.phone,
      address: data.address,
    },
      {}
    ).then((res) => {
      dispatch(UpdateSuccessCustomer([data]));
    }).catch((error) => dispatch(UpdateErrorCustomer()));
  };
};
