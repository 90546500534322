import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Select from "react-select";
import Swal from "sweetalert";
import { generateBarcodeApi } from "./Barcode.action";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const GenerateBarcode = (props) => {
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [productId, setProductId] = useState("");
  const [productname, setProductName] = useState("");
  const [productQuantity, setProductQuantity] = useState("");

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadGenrateBarcode() {
    props.generateBarcodeApi({
      productId: productId,
      quantity: productQuantity
    },
      handleVisible,
      handleDangerVisible
    );
  }

  const getMList = !isEmpty(props.hybridAndCompleteData) && props.hybridAndCompleteData.map((x) => {
    let data = {
      value: x._id,
      label: x.name,
    };
    return data;
  });
  const handleSelectProduct = (selectedOption) => {
    setProductId(selectedOption.value);
    setProductName(selectedOption.label);
  };

  useEffect(() => {
    props.getHybridAndCompleteData();
    props.setHeading("Genrate Barcode");
  }, []);

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isGeneratingBarcode ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isGeneratingBarcode ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Product *</b>
                  </Form.Label>
                  <Select
                    isDisabled={isEmpty(props.hybridAndCompleteData)}
                    placeholder="Select Product"
                    onChange={handleSelectProduct}
                    options={getMList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="12">
                <Form.Group className="mb-3" controlId="garana">
                  <Form.Label>
                    <b>Quantity *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    value={productQuantity}
                    placeholder="Quantity"
                    onChange={(e) => setProductQuantity(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <div className="sendDiv">
              {/* {isEmpty(productId) || isEmpty(productQuantity) ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadGenrateBarcode}> SAVE </Button>
              )} */}
              <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadGenrateBarcode}> SAVE </Button>
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    generateBarcodeApi: (data, handleVisible, handleDangerVisible) => dispatch(generateBarcodeApi(data, handleVisible, handleDangerVisible)),
    getHybridAndCompleteData: () => dispatch(getHybridAndCompleteData()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  isGeneratingBarcode: state.BarcodeReducer.isGeneratingBarcode,
});

export default connect(mapStateToProps, mapDispatchToProps)(GenerateBarcode);
