import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialFlavors from 'material-table';
import Spinner from "react-bootstrap/Spinner";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import { useEffect } from "react";
import { getReceiving, individualPDF } from "./Receiving.action";
import MaterialTable from "material-table";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


const ReceivingList = (props) => {
  useEffect(() => {
    props.setHeading("Receiving List");
  }, []);

  return (
    <>
      {props.isFetchingReceiving ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialFlavors
              title="Receiving Details"
              columns={[
                { title: "Seq Number", field: "seqNumber" },
                { title: 'Receiving Date', field: 'receivingDate' },
                { title: 'Company Name', field: 'restaurantId.restaurantName' },
                { title: 'Receivied By', field: 'receivedBy' },
                { title: 'Receiving Description', field: 'recivingDescription' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/receiving?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              detailPanel={rowData => {
                return (
                  <MaterialTable
                    columns={[
                      { title: 'Product Name ', field: 'ProductName' },
                      { title: 'Available Quantity', field: 'availableProductQuantity' },
                      { title: 'Add Quantity', field: 'requiredProductQuantity' },
                    ]}
                    data={rowData.receivingData}
                    options={{
                      actionsColumnIndex: -1,
                      toolbar: false,
                      exportButton: false,
                      sorting: false,
                      paging: false,
                      pageSize: 200,
                      emptyRowsWhenPaging: false,
                      pageSizeOptions: [50, 100, 150, 200],
                      headerStyle: {
                        position: 'sticky', top: 0,
                        color: '#00BBBB',
                        fontWeight: '550',
                        onRowAdd: 'none',
                      }
                    }}
                  />
                )
              }}
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageSize: 50,
                emptyRowsWhenPaging: false,
                pageSizeOptions: [50, 100, 150, 200],
                headerStyle: {
                  position: 'sticky',
                  top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                },
              }}
              // onRowClick={(event, rowData, togglePanel) => togglePanel()}
              // editable={{
              //    onRowUpdate: (newData, oldData) =>
              //      new Promise((resolve) => {
              //        setTimeout(() => {
              //          resolve();
              //          {
              //            props.updateFlavors(newData, oldData)
              //          }
              //        }, 600);
              //      }),
              //   onRowDelete: (oldData) =>
              //     new Promise((resolve) => {
              //       setTimeout(() => {
              //         resolve();
              //         props.deleteFlavors(oldData._id);
              //       }, 600);
              //     }),
              // }}
              actions={[
                (rowData) => ({
                  icon: () => <LocalPrintshopIcon />,
                  tooltip: "Print Receiving",
                  onClick: (event, rowData) => {
                    props.individualPDF(rowData);
                  },
                }),
              ]}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getReciving: () => dispatch(getReceiving()),
    setHeading: (data) => dispatch(setHeading(data)),
    individualPDF: (data) => dispatch(individualPDF(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  ReceivingList: state.RecivingReducer.ReceivingList,
  isFetchingReceiving: state.RecivingReducer.isFetchingReceiving,
});

export default connect(mapStateToProps, mapDispatchToProps)(ReceivingList);
