import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty, find } from "lodash";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import moment from "moment";
import { getHybridAndCompleteData } from "../InventoryLedger/InventoryLedger.action";
import { getWarehouseApi } from "../Warehouse/Warehouse.action";
import { setHeading } from "../../../accounts/account.actions";
import { addIssuance } from "./Issuance.action";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddIssuance = (props) => {
  const [inputField, setInputField] = useState([
    {
      inventoryId: "",
      availableQuantity: 0,
      issuanceQuantity: 0,
      stockPrice: 0,
    },
  ]);
  const getSectionDepartmentType = [
    { value: '64f08ad147352a7e3cd3356b', label: 'Pakistani' },
    { value: '64f08b1747352a7e3cd3356e', label: 'Turkish' },
    { value: '64f08b4847352a7e3cd33571', label: 'Chinese' },
    { value: '64f08b6747352a7e3cd33574', label: 'B.B.Q' },
    { value: '64f08b7547352a7e3cd33577', label: 'B.A.R' },
    { value: '64f08b9e47352a7e3cd3358a', label: 'Bakery' },
    { value: '64f08bb047352a7e3cd3358d', label: 'Continental' },
    { value: '64f08bb047352a7e3cd3358b', label: 'Tandoor' },
    { value: '64f08bb047352a7e3cd3358c', label: 'Cold Kitchen' },
    { value: '64f08bb047352a7e3cd3358e', label: 'Marquee Events' },
    { value: '64f08bb047352a7e3cd3358f', label: 'Marquee Office' },
    { value: '64f08bb047352a7e3cd33587', label: 'Staff' },
    { value: '64f08bb047352a7e3cd33583', label: 'Nouman Sb' },
    { value: '64f08bb047352a7e3cd33584', label: 'MD Office' },
    { value: '64f08bb047352a7e3cd33581', label: 'Complimentary' },
    { value: '64f08bb047352a7e3cd33582', label: 'Front' },
  ];

  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [departmentLabel, setDepartmentLabel] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [requistion, setRequistion] = useState();
  const [department, setdepartment] = useState("");
  const [productionOrder, setProductionOrder] = useState();
  const [supervisor, setSupervisor] = useState();
  const [issuedBy, setIssuedBy] = useState("");
  const [receivedBy, setReceivedBy] = useState();
  const [requistionType, setRequistionType] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [desc, setDesc] = useState("");
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [issuanceNameValue, setIssuanceNameValue] = useState();
  const [issuanceNameLabel, setIssuanceNameLabel] = useState();
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    setdepartment("");
    setSupervisor();
    setIssuedBy("");
    setReceivedBy();
    Swal({
      title: "Success",
      text: "Issuance Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };

  function uploadPurchase() {
    props.addIssuance({
      wareHouseId: warehouseId,
      date: date,
      // requsition: requistion,
      // productionOrder: productionOrder,
      department: department,
      supervisor: supervisor,
      issuedBy: issuedBy,
      receivedBy: receivedBy,
      issuanceDesc: desc,
      data: inputField,
    },
      handleVisible,
      handleDangerVisible
    );
    setRequistion("-1");
    setdepartment("");
    setProductionOrder("");
    setSupervisor("");
    setIssuedBy("");
    setReceivedBy("");
    setRequistionType("");
    setDesc("");
    setInputField([
      {
        inventoryId: "",
        availableQuantity: 0,
        issuanceQuantity: 0,
        stockPrice: 0,
      },
    ]);
  }

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const handleChangeDemand = (e, index, type, item) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == item);
    if (Number(e.target.value) <= Number(z.productBalance)) {
      const { name, value } = e;
      const list = [...inputField];
      if (type == "intname") list[index]["inventoryId"] = e.value;
      if (type == "intname") list[index]["inventoryLabel"] = e.label;
      if (type == "intname") list[index]["issuanceQuantity"] = Number(z.productBalance);
      // if (type == "intname") list[index]["stockPrice"] = Number(z.stockPrice);
      if (type == "intname") setMaximumQuantity(Number(z.productBalance));
      if (type == "intquantity") list[index]["issuanceQuantity"] = Number(e.target.value) < 0 ? alert("cannot added less than 0") : Number(e.target.value);
      // var holder = {};
      // list.forEach(function (d) {
      //     if (holder.hasOwnProperty(d.inventoryId)) {
      //         holder[d.inventoryId] = Number(d.productQuantity) + Number(d.productQuantity);
      //     } else {
      //         holder[d.inventoryId] = Number(d.productQuantity);
      //     }
      // });
      // var obj2 = [];
      // for (var prop in holder) {
      //     obj2.push({ inventoryId: prop, productQuantity: Number(holder[prop]) });
      // }
      setInputField(list);
    } else {
      alert("value out of range");
    }
  };

  const handleChange = (e, index, type) => {
    let z = find(props.hybridAndCompleteData, (x) => x._id == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") list[index]["availableQuantity"] = Number(z.productBalance);
    if (type == "intname") list[index]["stockPrice"] = Number(z.purchasePrice);
    if (type == "intname") setMaximumQuantity(Number(z.productBalance));
    if (type == "intquantity") list[index]["issuanceQuantity"] = Number(e.target.value);
    setInputField(list);
    // list[index]['productQuantity'] = "";
  };

  const handleAddInput = () => {
    setInputField([...inputField,
    { inventoryId: "", availableQuantity: 0, issuanceQuantity: 0, stockPrice: 0 },
    ]);
  };

  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  const getHybridAndCompleteData = props.hybridAndCompleteData.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });


  const exportPDF = () => {
    props.searchIssuanceReport(
      {
        startedDate: !isEmpty(startDate) ? startDate : "",
        endDate: !isEmpty(endDate) ? endDate : "",
        issuanceId: issuanceNameValue,
      },
      issuanceNameLabel,
      startDate,
      endDate
    );
  };
  const handleChangeProductType = (selectedOption) => {
    setdepartment(selectedOption.value);
    setDepartmentLabel(selectedOption.label);
  };
  const getRequistion = [
    { value: "Manual", label: "Manual" },
    { value: "After Production", label: "After Production" },
  ];
  const handleChangeRequistion = (selectedOption) => {
    setRequistion(selectedOption.value);
  };

  useEffect(() => {
    props.setHeading("Add Issuance");
    props.getHybridAndCompleteData();
    props.getWarehouseApi();
  }, []);

  return (
    <>
      {props.isFetchingHybridAndCompleteData || props.isAddingIssuance ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingIssuance ? "Saving..." : "Loading..."}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Select Business Location *</b>
                  </Form.Label>
                  <Select
                    placeholder="Select Business Location"
                    onChange={handleChangeWarehouse}
                    options={getWarehouseList}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="Date"
                    value={date}
                    onChange={(e) => setDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description *</b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="requistionType"
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Received By </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Received By"
                    name="receivedBy"
                    onBlur={(e) => setReceivedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Issued By </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Issued By"
                    name="issuedBy"
                    onBlur={(e) => setIssuedBy(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Supervisor </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Supervisor"
                    name="Supervisor"
                    onBlur={(e) => setSupervisor(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            {/* <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select Section/Department *</b>
                  </Form.Label>
                  <Select
                    value={{ label: departmentLabel }}
                    placeholder="Select Product Type..."
                    onChange={(e) => handleChangeProductType(e)}
                    options={getSectionDepartmentType}
                  />
                </Form.Group>
              </Col>
            </Row> */}

            {inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="5" lg="5" md="5" sm="5">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                          value={{ label: item.inventoryLabel }}
                          placeholder="Select Product"
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={getHybridAndCompleteData}
                          // isDisabled={isEmpty(inventory) || wareHouseId === ""}
                          isOptionDisabled={(option) => !isEmpty(find(inputField, (x) => x.inventoryId == option.value))}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="2" lg="2" md="2" sm="2">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Inventories Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Inventories Quantity"
                          name="availableQuantity"
                          disabled
                          value={item.availableQuantity}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="2" lg="2" md="2" sm="2">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Stock Price *</b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Stock Price"
                          name="Stock Price"
                          disabled
                          value={item.stockPrice}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="2" lg="2" md="2" sm="2">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity"
                          name="issuanceQuantity"
                          min={0}
                          value={item.issuanceQuantity}
                          onChange={(e) => handleChangeDemand(e, i, "intquantity", item.inventoryId)}
                        />
                      </Form.Group>
                    </Col>

                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <Button
                            title="Click to Add Issuance"
                            onClick={handleAddInput}
                            style={{
                              float: "right",
                              // color: "white",
                            }}
                          >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField.length !== 1 && (
                          <Button
                            title="Click to Remove Issuance"
                            onClick={() => handleRemoveInput(i)}
                            style={{
                              float: "right",
                              marginRight: "1%",
                              // color: "white",
                            }}
                          >
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <div className="sendDiv">
              {isEmpty(warehouseId) || isEmpty(desc) || isEmpty(date) || inputField[0].inventoryId == "" || date == "" || inputField[0].availableQuantity == 0 || inputField[0].issuanceQuantity == 0 ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled>
                  SAVE
                </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadPurchase}>
                  SAVE
                </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    addIssuance: (data, handleVisible, handleDangerVisible) => dispatch(addIssuance(data, handleVisible, handleDangerVisible)),
    getHybridAndCompleteData: () => dispatch(getHybridAndCompleteData()),
    setHeading: (data) => dispatch(setHeading(data)),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
  };
};
const mapStateToProps = (state, ownProps) => ({
  hybridAndCompleteData: state.InventoryLegderReducer.hybridAndCompleteData,
  isFetchingHybridAndCompleteData: state.InventoryLegderReducer.isFetchingHybridAndCompleteData,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIssuance);
