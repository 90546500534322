import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert';
import moment from "moment";
import { addDayClosing, getDayOpening } from "./Day.action";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const ClosingDay = (props) => {
    const [cash, setCash] = useState('');
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [opening, setOpening] = useState({
        id: '',
        label: '',
        openingCash: 0,
        sumCancelAmount: 0,
        sumDiscountAmount: 0,
        sumOrderAmount: 0,
        totalCash: 0,
    }
    );
    // const openingList = !isEmpty(props.dayOpeningList) ? props.dayOpeningList.data.map((x) => { return { value: x._id, label: `${x.dayOpenDate} ${x.dayOpenName}`,openingCash: x.cashInHand,sumCancelAmount:x.sumCancelAmount,sumDiscountAmount:x.sumDiscountAmount,sumOrderAmount:x.sumOrderAmount,totalCash:x.totalCash }; }) : [];


    const handleVisible = (msg) => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    }

    function uploadPurchase() {
        props.addDayClosing({
            cash: cash,
            dayOpenId: props.dayOpeningList.data._id
        },
            handleVisible,
            handleDangerVisible)
        setCash('');
        setOpening({
            id: '',
            label: '',
            openingCash: 0,
            sumCancelAmount: 0,
            sumDiscountAmount: 0,
            sumOrderAmount: 0,
            totalCash: 0,

        })
    }

    useEffect(() => {
        props.getDayOpening();
        props.setHeading("Day Close")
    }, []);

    return (
        <>
            {props.isGettingDayOpening ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isAddingIssuance ? 'Saving' : 'Loading'}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Date *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        name="Date"
                                        value={moment(new Date()).format("YYYY-MM-DD")} disabled={true} />
                                </Form.Group>
                            </Col>

                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Day Opening Detail *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Day Opening Detail"
                                        name="Cash"
                                        value={!isEmpty(props.dayOpeningList) ? props.dayOpeningList.data.dayOpenDate + props.dayOpeningList.data.dayOpenName : ""}
                                        disabled />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Opening Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Opening Cash"
                                        disabled
                                        value={!isEmpty(props.dayOpeningList) ? props.dayOpeningList.data.cashInHand : 0} />
                                </Form.Group>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Cancel Order Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Cancel Order Cash"
                                        disabled
                                        value={!isEmpty(props.dayOpeningList) ? props.dayOpeningList.sumCancelAmount : 0} />
                                </Form.Group>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Dicounted Order Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Dicounted Order Cash"
                                        disabled
                                        value={!isEmpty(props.dayOpeningList) ? props.dayOpeningList.sumDiscountAmount : 0} />
                                </Form.Group>
                            </Col>
                            <Col xl="3" lg="3" md="3" sm="3">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Order Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Order Cash"
                                        disabled
                                        value={!isEmpty(props.dayOpeningList) ? props.dayOpeningList.sumOrderAmount : 0} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Total Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Total Cash"
                                        disabled value={!isEmpty(props.dayOpeningList) ? props.dayOpeningList.totalCash : 0} />
                                </Form.Group>
                            </Col>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="Cash"
                                        name="Cash"
                                        value={cash} disabled={isEmpty(props.dayOpeningList) ? true : false} onChange={(e) => setCash(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            <Button disabled={cash == ""} variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadPurchase} > SAVE </Button>
                        </div>

                    </Form>
                </Container>
            )
            }
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getDayOpening: () => dispatch(getDayOpening()),
        addDayClosing: (data, handleVisible, handleDangerVisible) => dispatch(addDayClosing(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data))
    };
};
const mapStateToProps = (state, ownProps) => ({
    isGettingDayOpening: state.DayReducer.isGettingDayOpening,
    dayOpeningList: state.DayReducer.dayOpeningList,
});

export default connect(mapStateToProps, mapDispatchToProps)(ClosingDay);