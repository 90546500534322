import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Select from 'react-select';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { setHeading } from "../../../accounts/account.actions";
import { getCustomerData } from "../../AddDetails/AddCustomer/Customer.action";
import { getProductData } from "../../AddDetails/AddProduct/Product.action";
import { addOrderCreationApi } from "./OrderCreationAction";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";


const Parent = (props) => {
    const [manualOrderNum, setManualOrderNum] = useState("");
    const [customerName, setCustomerName] = useState("");
    const [customerOrderNum, setCustomerOrderNum] = useState();
    const [invoiceNum, setInvoiceNum] = useState("");
    const [description, setDescription] = useState("");
    const [orderDate, setOrderDate] = useState("");
    const [articleName, setProductName] = useState();
    const [maximumQuantity, setMaximumQuantity] = useState(0);
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [inputField, setInputField] = useState([{
        inventoryId: "",
        availableQuantity: 0,
        orderQuantity: 0,
        stockPrice: 0,
    }]);

    const handleVisible = (msg) => {
        setAlertVisible(true);
        setTimeout(() => {
            setAlertVisible(false);
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    };
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true);
        setTimeout(() => {
            setDangerVisibleAlert(false);
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    };

    const sendData = () => {
        props.addOrderCreationApi({
            manualOrderId: manualOrderNum,
            customerId: customerName,
            customerOrder: customerOrderNum,
            orderInvoice: invoiceNum,
            orderDescription: description,
            orderDeliveryDate: orderDate,
            data: inputField,
        },
            handleVisible,
            handleDangerVisible
        )
    }

    const getCustomer = props.customerDataList.map((x) => { let data = { label: x.name, value: x._id }; return data });
    const setCustomer = (selectedOption) => {
        setCustomerName(selectedOption.value);
    };

    const getProductList = props.productDataList.map((x) => { let data = { label: x.name, value: x._id }; return data });
    const setProduct = (selectedOption) => {
        setProductName(selectedOption.value);
    };

    const handleChangeDemand = (e, index, type, item) => {
        let z = _.find(props.productDataList, (x) => x._id == item);
        // if (Number(e.target.value) <= Number(z.productBalance)) {
        const { name, value } = e;
        const list = [...inputField];
        if (type == "intname") list[index]["inventoryId"] = e.value;
        if (type == "intname") list[index]["inventoryLabel"] = e.label;
        if (type == "intname") list[index]["orderQuantity"] = Number(z.productBalance);
        if (type == "intname") setMaximumQuantity(Number(z.productBalance));
        if (type == "intquantity") list[index]["orderQuantity"] = Number(e.target.value) < 0 ? alert("cannot added less than 0") : Number(e.target.value);
        setInputField(list);
        // } else {
        //     alert("value out of range");
        // }
    };
    const handleChange = (e, index, type) => {
        let z = _.find(props.productDataList, (x) => x._id == e.value);
        const { name, value } = e;
        const list = [...inputField];
        if (type == "intname") list[index]["inventoryId"] = e.value;
        if (type == "intname") list[index]["inventoryLabel"] = e.label;
        if (type == "intname") list[index]["availableQuantity"] = Number(z.productBalance);
        if (type == "intname") list[index]["stockPrice"] = Number(z.purchasePrice);
        if (type == "intname") setMaximumQuantity(Number(z.productBalance));
        if (type == "intquantity") list[index]["orderQuantity"] = Number(e.target.value);
        setInputField(list);
    };


    const handleAddInput = () => {
        setInputField([...inputField, { inventoryId: "", availableQuantity: 0, orderQuantity: 0 }]);
    };
    const handleRemoveInput = (index) => {
        const list = [...inputField];
        list.splice(index, 1);
        setInputField(list);
    };

    useEffect(() => {
        props.setHeading("Add Order Creation");
        props.getCustomerData()
        props.getProductData()
    }, []);


    return (
        <>
            {props.isFetchingCustomer ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {/* {props.isAddingDemand ? "Saving..." : "Loading..."} */}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <div>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Manual Order No *</b></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="manualOrder"
                                                placeholder="Manual Order No"
                                                onBlur={(e) => setManualOrderNum(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Select Customer *</b></Form.Label>
                                            <Select
                                                placeholder="Select Customer"
                                                onChange={setCustomer}
                                                options={getCustomer}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Customer Order No *</b></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="customerOrderNu"
                                                placeholder="Customer Order"
                                                onBlur={(e) => setCustomerOrderNum(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Invoice *</b></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="invoice"
                                                placeholder="Invoice"
                                                onBlur={(e) => setInvoiceNum(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Description *</b></Form.Label>
                                            <Form.Control
                                                type="text"
                                                id="description"
                                                placeholder="Description"
                                                onBlur={(e) => setDescription(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label><b>Order Delivery Date *</b></Form.Label>
                                            <Form.Control
                                                type="date"
                                                id="orderDate"
                                                placeholder="Order Delivery Date"
                                                onBlur={(e) => setOrderDate(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Row>

                                {inputField.map((item, i) => {
                                    return (
                                        <React.Fragment key={i}>
                                            <Row>
                                                <Col>
                                                    <Form.Group>
                                                        <Form.Label>
                                                            <b> Select Product *</b>
                                                        </Form.Label>
                                                        <Select
                                                            value={{ label: item.inventoryLabel }}
                                                            placeholder="Select Product"
                                                            name="inventoryId"
                                                            onChange={(e) => handleChange(e, i, "intname")}
                                                            options={getProductList}
                                                            isOptionDisabled={(option) => !_.isEmpty(_.find(inputField, (x) => x.inventoryId == option.value))}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            <b>Available Quantity *</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            placeholder="Available Quantity"
                                                            name="availableQuantity"
                                                            disabled
                                                            value={item.availableQuantity}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <Col>
                                                    <Form.Group className="mb-3">
                                                        <Form.Label>
                                                            <b>Add Quantity *</b>
                                                        </Form.Label>
                                                        <Form.Control
                                                            type="number"
                                                            placeholder="Add Quantity"
                                                            name="orderQuantity"
                                                            min={0}
                                                            value={item.orderQuantity}
                                                            onChange={(e) => handleChangeDemand(e, i, "intquantity", item.inventoryId)}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                                <FormGroup>
                                                    <Row>
                                                        <Col lg="12">
                                                            {inputField.length - 1 == i && (
                                                                <Button
                                                                    title="Click to Add"
                                                                    onClick={handleAddInput}
                                                                    style={{ float: "right" }}
                                                                >
                                                                    <AddIcon />
                                                                </Button>
                                                            )}

                                                            {inputField.length !== 1 && (
                                                                <Button
                                                                    title="Click to Remove"
                                                                    onClick={() => handleRemoveInput(i)}
                                                                    style={{ float: "right", marginRight: "1%", }}
                                                                >
                                                                    <RemoveIcon />
                                                                </Button>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </FormGroup>
                                            </Row>

                                        </React.Fragment>
                                    );
                                })}

                                <div className="sendDiv">
                                    {_.isEmpty(manualOrderNum) ? (
                                        <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                                    ) : (
                                        <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={sendData}> SAVE </Button>
                                    )}
                                </div>

                            </Form>
                        </div>
                    </div>
                </Container>
            )}
        </>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        addOrderCreationApi: (data, handleVisible, handleDangerVisible) => dispatch(addOrderCreationApi(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
        getCustomerData: () => dispatch(getCustomerData()),
        getProductData: () => dispatch(getProductData()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isAddingOrderCreation: state.OrderCreationReducer.isAddingOrderCreation,
    productDataList: state.ProductReducer.productDataList,
    isFetchingProduct: state.ProductReducer.isFetchingProduct,
    customerDataList: state.CustomerReducer.customerDataList,
    isFetchingCustomer: state.CustomerReducer.isFetchingCustomer,
});

export default connect(mapStateToProps, mapDispatchToProps)(Parent);