import API from "../../../../../global/api";
import jsPDF from "jspdf";
import moment from "moment";
import _ from "lodash";


export const requestAddDemand = () => {
  return {
    type: "REQUEST_ADD_DEMAND",
  };
};
export const successAddDemand = (data) => {
  return {
    type: "SUCCESS_ADD_DEMAND",
    payload: data,
  };
};
export const errorAddDemand = () => {
  return {
    type: "ERROR_ADD_DEMAND",
  };
};
export const addDemandApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddDemand());
    API.post(`/demand/`, data).then((res) => {
      dispatch(successAddDemand(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddDemand());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestGetDemand = () => {
  return {
    type: "REQUEST_GET_DEMAND",
  };
};
export const successGetDemand = (data) => {
  return {
    type: "SUCCESS_GET_DEMAND",
    payload: data,
  };
};
export const errorGetDemand = () => {
  return {
    type: "ERROR_GET_DEMAND",
  };
};
export const getDemandApi = () => {
  return (dispatch) => {
    dispatch(requestGetDemand());
    API.get(`/issuance/`).then((res) => {
      let getData = res.data;
      dispatch(successGetDemand(getData));
    }).catch((error) => {
      dispatch(errorGetDemand());
    });
  };
};


export const UpdateRequestDemand = () => {
  return {
    type: "UPDATE_REQUEST_DEMAND",
  };
};
export const UpdateSuccessDemand = (data) => {
  return {
    type: "UPDATE_SUCCESS_DEMAND",
    payload: data,
  };
};
export const UpdateErrorDemand = () => {
  return {
    type: "UPDATE_ERROR_DEMAND",
  };
};
export const updateDemandApi = (data, oldData) => {
  return dispatch => {
    dispatch(UpdateRequestDemand());
    API.put(`/demand/${oldData._id}`, {
      demandDate: data.demandDate,
      manualDemandNumber: data.manualDemandNumber,
      referenceNumber: data.referenceNumber,
      description: data.description,
    }, {
    }).then(res => {
      dispatch(UpdateSuccessDemand([data]))
    }).catch((error) =>
      dispatch(UpdateErrorDemand()));
  }
};


export const DeleteRequestDEMAND = () => {
  return {
    type: "DELETE_REQUEST_DEMAND",
  };
};
export const DeleteSuccessDEMAND = (id) => {
  return {
    type: "DELETE_SUCCESS_DEMAND",
    _id: id,
  };
};
export const DeleteErrorDEMAND = () => {
  return {
    type: "DELETE_ERROR_DEMAND",
  };
};
export const deleteDemandApi = (id) => {
  return dispatch => {
    dispatch(DeleteRequestDEMAND());
    API.delete(`/demand/${id}`).then(res => {
      dispatch(DeleteSuccessDEMAND(id))
    }).catch((error) => {
      dispatch(DeleteErrorDEMAND())
    });
  }
};


export const individualPDF = (data) => {
  return (dispatch) => {
    const unit = "pt";
    const size = "A4";
    const orientation = "potrait";
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(11);
    const title = "Demand Report";
    const companyName = `${data.restaurantId.restaurantName}`;
    const companyAddress = `${data.restaurantId.restaurantLocation}`;
    const companyNtn = `${data.restaurantId.ntn}`;

    const headers = [
      [
        "Serial No",
        "Product Name",
        "Demanded Quantity",
      ],
    ];
    const data1 = data.data.map((elt, index) => [
      index + 1,
      elt.inventoryLabel,
      elt.issuanceQuantity,
    ]);
    let content = {
      startY: 250,
      startX: 10,
      head: headers,
      // tableWidth: "wrap",
      body: data1,
    };
    // doc.addImage(logo, "PNG", 40, 15, 100, 100);
    // doc.addImage(logo2, "PNG", 440, 90, 120, 120);
    doc.text(companyName, 150, 40, 0, 20);
    doc.setFontSize(13);
    doc.setFont("times", "normal");
    doc.text(companyAddress, 150, 60, 0, 20);
    doc.text(companyNtn, 150, 80, 0, 20);
    doc.setFontSize(14);
    doc.setFont("times", "bold");
    doc.text(title, 240, 120);
    doc.setFont("times", "normal");
    doc.text(`Manual Demand Number: ${data.manualDemandNumber}`, 50, 150, 0, 20);
    doc.text(`Demand Date: ${data.demandDate}`, 50, 170, 0, 20);
    doc.text(`Demand Referance: ${data.referenceNumber}`, 50, 190, 0, 20);
    doc.text(`Demand Description: ${data.description}`, 50, 210, 0, 20);
    doc.text(`Business Location: ${data.wareHouseId.businessName}`, 50, 230, 0, 20);
    doc.autoTable(content);
    // let finalY = doc.lastAutoTable.finalY;
    // doc.text(grandTotal, 420, finalY + 30);
    doc.setProperties({ title: `Demand Report ${moment(new Date()).format("YYYY-MM-DD")}` });
    var blob = doc.output("blob");
    window.open(URL.createObjectURL(blob));
    // doc.save(`Invoice Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`);
  };
};