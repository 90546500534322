const initialState = {
  ReceivingList: [],
  isFetchingReceiving: false,
  receiving: [],
  isAddingReceiving: false,
  isFetchingSearchReceivingList: false,
  searchReceivingList: [],
  ReceivingListWithDate: [],
  isFetchingReceivingWithDate: false,

};

const RecivingReducer = (state = initialState, action) => {
  switch (action.type) {
    //Receiving 
    case "REQUEST_GET_RECEIVING":
      return { ...state, isFetchingReceiving: true, };
    case "SUCCESS_GET_RECEIVING":
      return { ...state, ReceivingList: action.payload.data, isFetchingReceiving: false };
    case "ERROR_GET_RECEIVING":
      return { ...state, isFetchingReceiving: false };

    //SUCCESS_SEARCH_Reciving_BY_DATE
    case "REQUEST_SEARCH_Reciving_BY_DATE":
      return { ...state, isFetchingReceivingWithDate: true, };
    case "SUCCESS_SEARCH_Reciving_BY_DATE":
      return { ...state, ReceivingListWithDate: action.payload.data, isFetchingReceivingWithDate: false };
    case "ERROR_SEARCH_Reciving_BY_DATE":
      return { ...state, isFetchingReceivingWithDate: false };
    ///
    case "REQUEST_ADD_RECEIVING":
      return { ...state, isAddingReceiving: true, };
    case "SUCCESS_ADD_RECEIVING":
      return { ...state, receiving: action.payload, isAddingReceiving: false };
    case "ERROR_ADD_RECEIVING":
      return { ...state, isAddingReceiving: false };

    case "REQUEST_SEARCH_RECEIVING":
      return { ...state, isFetchingSearchReceivingList: true };
    case "SUCCESS_SEARCH_RECEIVING":
      return { ...state, searchReceivingList: action.payload, isFetchingSearchReceivingList: false };
    case "ERROR_SEARCH_RECEIVING":
      return { ...state, isFetchingSearchReceivingList: false };


    default:
      return state;
  }
};
export default RecivingReducer;
