
// import * as React from 'react';
// import { connect } from 'react-redux';
// import { Row, Col, Form, Button, Image, } from "react-bootstrap";
// import logo from "../../../Images/report.jpg"
// import Typography from '@mui/material/Typography';
// import { useNavigate } from 'react-router-dom';
// import Swal from "sweetalert";
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import { isEmpty } from 'lodash';
// import { getLogin, changeIsWrong } from "../../user_views/accounts/account.actions"


// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {/* {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'} */}
//     </Typography>
//   );
// }

// const theme = createTheme();
// function Login(props) {
//   //const [state, dispatch] = useContext(Context);
//   const [open, setOpen] = React.useState(false);
//   const [email, setEmail] = React.useState("");
//   const [pass, setPassword] = React.useState("");
//   const [show, setShow] = React.useState(false);
//   const navigate = useNavigate();
//   const handleShow = () => setShow(true);
//   const handleClose = () => setShow(false);
//   const [dangerVisibleAlert, setDangerVisibleAlert] = React.useState(false);

//   const handleDangerVisible = (err) => {
//     setDangerVisibleAlert(true);
//     setTimeout(() => {
//       setDangerVisibleAlert(false);
//     }, 9000);
//     Swal({
//       title: "Error",
//       text: err,
//       icon: "error",
//       button: "Ok",
//     });
//   };

//   const handleSubmit = () => {
//     props.getLogin({
//       userName: email,
//       password: pass,
//     },
//       handleDangerVisible
//     )
//     setShow(true)
//   }


//   // const handleClose = () => {
//   //   props.closeSnack()
//   // };
//   const handleToggle = () => {
//     setOpen(!open);
//   };
//   const handleForgetPassword = () => {
//     navigate('/forgot-password')
//   }
//   const handleRegistration = () => {
//     navigate('/register')
//   }
//   // function handleCallbackResponse(response) {
//   //   GetGoogleLogin({
//   //     google_token: response.credential,
//   //   }, state, dispatch);
//   // }

//   return (
//     <>
//       <div style={{ overflowX: 'hidden', overflowY: 'hidden' }}>
//         <Row>
//           <Col xl="6" lg="6" md="6" sm="6" style={{ backgroundColor: '#3285a8', height: '100vh' }}>
//             <Row style={{ marginTop: "25%" }}>
//               <Col xl="3" lg="1" md="1" sm="1">
//               </Col>
//               <Col xl="6" lg="10" md="10" sm="10">
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   {/* <Form.Label><p style={{color:'white',fontSize:'22px',textAlign:'center',marginLeft:'20px'}}><b>WE REPORT , YOUR MIND</b></p></Form.Label> */}
//                   <Form.Label><p style={{ color: 'white', fontSize: '22px', textAlign: 'center', marginLeft: '20px' }}><b>Login</b></p></Form.Label>
//                 </Form.Group>
//               </Col>
//               <Col xl="3" lg="1" md="1" sm="1">
//               </Col>
//             </Row>
//             <Row>
//               <Col xl="2" lg="2" md="2" sm="2">
//               </Col>
//               <Col xl="8" lg="8" md="8" sm="8">
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   <Form.Label><p style={{ color: 'white' }}>Username</p></Form.Label>
//                   <Form.Control
//                     type="text"
//                     name="Username"
//                     placeholder="Username"
//                     value={email}
//                     onChange={(e) => setEmail(e.target.value)}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xl="2" lg="2" md="2" sm="2">
//               </Col>
//             </Row>
//             <Row>
//               <Col xl="2" lg="2" md="2" sm="2">
//               </Col>
//               <Col xl="8" lg="8" md="8" sm="8">
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   <Form.Label><p style={{ color: 'white' }}>Password</p></Form.Label>
//                   <Form.Control
//                     type="password"
//                     name="Password"
//                     placeholder="Password"
//                     value={pass}
//                     onChange={(e) => setPassword(e.target.value)}
//                   />
//                 </Form.Group>
//               </Col>
//               <Col xl="2" lg="2" md="2" sm="4">
//               </Col>
//             </Row>
//             {/* <Row> */}
//             <div className='sendDiv' >
//               {isEmpty(email) || isEmpty(pass) ? (
//                 <Button
//                   disabled
//                   style={{
//                     marginLeft: "2%",
//                     color: "black",
//                     width: "20%",
//                     backgroundColor: 'white',
//                     border: "1px solid white",
//                   }}
//                 >
//                   Sign In
//                 </Button>
//               ) : (
//                 <Button onClick={() => handleSubmit()}
//                   style={{
//                     marginLeft: "2%",
//                     color: "black",
//                     width: "20%",
//                     backgroundColor: "white",
//                     border: "1px solid white",
//                   }}>
//                   Sign In
//                 </Button>
//               )}

//             </div>
//             {/* <Row style={{ marginTop: '10%' }}>
//               <Col xl="3" lg="3" md="3" sm="0">
//               </Col>
//               <Col xl="6" lg="6" md="6" sm="12">
//                 <Form.Group className="mb-3" controlId="formBasicEmail">
//                   <Form.Label><p style={{ color: 'white', fontSize: '22px', textAlign: 'center', marginLeft: '20px' }}><b>COPY RIGHTS RESERVED</b></p></Form.Label>
//                 </Form.Group>
//               </Col>
//               <Col xl="3" lg="3" md="3" sm="0">
//               </Col>
//             </Row> */}
//           </Col>
//           <Col xl="6" lg="6" md="6" sm="6">
//             <Image src={logo} style={{ height: '99vh', width: '99%' }} />
//           </Col>
//           {/* <div className="modal">
//           {props.isWrongCredentials == true &&
//             <Modal isOpen={show}>
//               <ModalHeader><h4>Login Unsuccessful</h4></ModalHeader>
//               <ModalBody><h5>Something is Wrong</h5></ModalBody>
//               <ModalFooter>
//                 <Row className="close">
//                   <Button onClick={handleClose}>
//                     Close
//                   </Button>
//                 </Row>
//               </ModalFooter>
//             </Modal>}
//          </div> */}
//         </Row>
//       </div>
//     </>
//   );
// };

// const mapDispatchToProps = dispatch => {
//   return {
//     getLogin: (data, handleDangerVisible) => dispatch(getLogin(data, handleDangerVisible)),
//     closeSnack: () => dispatch(changeIsWrong())
//   }
// }

// const mapStateToProps = (state, ownProps, number) => ({
//   // isWrongCredentials: state.userReducer.isWrongCredentials,
//   isLogin: state.userReducer.isLogin,
//   isWrongCredentials: state.userReducer.isWrongCredentials,
//   message: state.userReducer.message,
// })

// export default connect(mapStateToProps, mapDispatchToProps)(Login);








import * as React from 'react';
import { Image } from "react-bootstrap";
import { useState } from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { alpha, useTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { forwardRef } from 'react';
import Swal from "sweetalert";
import { Icon } from '@iconify/react';
import PropTypes from 'prop-types';
import Logo from '../../../Images/logo.png';
import { connect } from 'react-redux';
import { getLogin, changeIsWrong } from "../../user_views/accounts/account.actions"
import _ from 'lodash';
import LoginIcon from '@mui/icons-material/Login';


export function bgGradient(props) {
  const direction = props?.direction || 'to bottom';
  const startColor = props?.startColor;
  const endColor = props?.endColor;
  const imgUrl = props?.imgUrl;
  const color = props?.color;

  if (imgUrl) {
    return {
      background: `linear-gradient(${direction}, ${startColor || color}, ${endColor || color
        }), url(${imgUrl})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
    };
  }

  return {
    background: `linear-gradient(${direction}, ${startColor}, ${endColor})`,
  };
}
const Iconify = forwardRef(({ icon, width = 20, sx, ...other }, ref) => (
  <Box
    ref={ref}
    component={Icon}
    className="component-iconify"
    icon={icon}
    sx={{ width, height: width, ...sx }}
    {...other}
  />
));
Iconify.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  sx: PropTypes.object,
  width: PropTypes.number,
};


function Login(props) {
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = React.useState("");
  const [pass, setPassword] = React.useState("");
  const [dangerVisibleAlert, setDangerVisibleAlert] = React.useState(false);

  const handleSubmit = () => {
    props.getLogin({
      userName: email,
      password: pass,
    },
      handleDangerVisible
    )
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  const renderForm = (
    <>
      <Stack spacing={3}>
        <TextField
          name="email"
          label="UserName *"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ color: '#212b36', fontSize: '1rem', fontWeight: 'bold' }}
        />
        <TextField
          name="password"
          label="Password *"
          type={showPassword ? 'text' : 'password'}
          value={pass}
          onChange={(e) => setPassword(e.target.value)}
          sx={{ color: '#212b36', fontSize: '1rem', fontWeight: 'bold' }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="flex-end" sx={{ my: 3, color: '#1877f2', fontWeight: '600', fontSize: '0.875rem' }}>
        <Link variant="subtitle2" underline="hover">
          <b>Forgot password?</b>
        </Link>
      </Stack>

      <div className="sendDiv">
        {_.isEmpty(email) || _.isEmpty(pass) ? (
          <Button variant="contained" style={{ width: '100%', borderRadius: '8px', fontFamily: '"Public Sans", sans-serif', fontSize: '0.9375rem' }} endIcon={<LoginIcon />} size="large" disabled> LogIn </Button>
        ) : (
          <Button variant="contained" style={{ width: '100%', color: '#ffffff', backgroundColor: '#212b36', borderRadius: '8px', fontFamily: '"Public Sans", sans-serif', fontSize: '0.9375rem' }} endIcon={<LoginIcon />} size="large" onClick={handleSubmit}> LogIn </Button>
        )}
      </div>

    </>
  );
  return (
    <div style={{ backgroundColor: '#f5f4f4', paddingTop: '20px', paddingBottom: '80px' }}>
      <Box
        sx={{
          ...bgGradient({
            color: '#f5f4f4',
          }),
          height: 1,
        }}
      >
        <Image src={Logo} style={{ height: '12vh', display: 'block', marginLeft: 'auto', marginRight: 'auto', marginBottom: '20px' }} />
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
          <Card
            sx={{
              p: 5,
              width: 1,
              maxWidth: 420,
              borderRadius: '16px',
            }}
          >
            <Typography variant="h4" style={{ fontSize: '1.5rem' }}><b> WelCome to <span style={{ color: '#089955' }}> Report ERP </span> </b> </Typography>
            <Typography variant="body2" sx={{ mt: 2, mb: 3 }}> <q> We Write Code & Decode Your Business Success </q></Typography>
            <Stack direction="row" spacing={2}>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                target="_blank"
                // href="https://www.facebook.com/reporterp"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16), padding: '13px', borderRadius: '8px', }}
              >
                <Iconify icon="eva:facebook-fill" color="#1877F2" />
              </Button>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16), padding: '13px', borderRadius: '8px', }}
              >
                <Iconify icon="entypo-social:youtube" color='#FF0000'></Iconify>
              </Button>

              <Button
                fullWidth
                size="large"
                color="inherit"
                variant="outlined"
                sx={{ borderColor: alpha(theme.palette.grey[500], 0.16), padding: '13px', borderRadius: '8px', }}
              >
                {/* <Iconify icon="entypo-social:instagram" color='#feda75'></Iconify> */}
                <Iconify icon="entypo-social:linkedin" color='#0077B5'></Iconify>
              </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                OR
              </Typography>
            </Divider>
            {renderForm}
          </Card>
        </Stack>
      </Box>
    </div>
  );
}

const mapDispatchToProps = dispatch => {
  return {
    getLogin: (data, handleDangerVisible) => dispatch(getLogin(data, handleDangerVisible)),
    closeSnack: () => dispatch(changeIsWrong())
  }
}
const mapStateToProps = (state, ownProps, number) => ({
  isLogin: state.userReducer.isLogin,
  isWrongCredentials: state.userReducer.isWrongCredentials,
  message: state.userReducer.message,
})
export default connect(mapStateToProps, mapDispatchToProps)(Login);