import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import Spinner from 'react-bootstrap/Spinner';
import Select from 'react-select';
import Swal from 'sweetalert';
import moment from "moment";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { addDayOpening } from "./Day.action";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const OpeningDay = (props) => {
    const [cash, setCash] = useState('');
    const [visibleAlert, setAlertVisible] = useState(false);
    const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
    const [resturant, setResturant] = useState({
        id: '',
        label: ''
    });

    const handleVisible = (msg) => {
        setAlertVisible(true)
        setTimeout(() => {
            setAlertVisible(false)
        }, 9000);
        Swal({
            title: "Success",
            text: msg,
            icon: "success",
            button: "Ok",
        });
    }
    const handleDangerVisible = (err) => {
        setDangerVisibleAlert(true)
        setTimeout(() => {
            setDangerVisibleAlert(false)
        }, 9000);
        Swal({
            title: "Error",
            text: err,
            icon: "error",
            button: "Ok",
        });
    }

    function uploadPurchase() {
        props.addDayOpening({
            cashInHand: cash,
            restaurant: resturant.id
        },
            handleVisible,
            handleDangerVisible
        );
        setCash('');
        setResturant({ id: '', label: '' })
    }

    const restaurantList = !isEmpty(props.restaurantDataList) ? props.restaurantDataList.map((x) => ({ value: x._id, label: x.restaurantName })) : [];

    useEffect(() => {
        props.getRestaurantData();
        props.setHeading("Day Open")
    }, []);

    return (
        <>
            {props.isFetchingRestaurant ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingRestaurant ? 'Loading...' : 'Loading...'}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <Form>
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Date *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="date"
                                        placeholder="Date"
                                        name="Date" value={moment(new Date()).format("YYYY-MM-DD")} disabled={true} />
                                </Form.Group>
                            </Col>

                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Select Company *</b>
                                    </Form.Label>
                                    <Select
                                        placeholder="Select Company"
                                        onChange={(e) => setResturant((prev) => ({ ...prev, id: e.value, label: e.label }))}
                                        options={restaurantList}
                                    />
                                </Form.Group>
                            </Col>

                        </Row>
                        <Row>
                            <Col xl="6" lg="6" md="12" sm="12">
                                <Form.Group>
                                    <Form.Label>
                                        <b>Cash *</b>
                                    </Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Cash"
                                        name="Cash"
                                        value={cash}
                                        onChange={(e) => setCash(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>

                        <div className="sendDiv">
                            <Button disabled={cash == "" || resturant.id == '' ? true : false} variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadPurchase} > SAVE </Button>
                        </div>

                    </Form>
                </Container>
            )}
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return {
        getRestaurantData: () => dispatch(getRestaurantData()),
        addDayOpening: (data, handleVisible, handleDangerVisible) => dispatch(addDayOpening(data, handleVisible, handleDangerVisible)),
        setHeading: (data) => dispatch(setHeading(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingRestaurant: state.ResturantReducer.isFetchingRestaurant,
    restaurantDataList: state.ResturantReducer.restaurantDataList,
});

export default connect(mapStateToProps, mapDispatchToProps)(OpeningDay);