import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import { addExpenseTypesApi } from "./Expense.action";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddExpenseTypes = (props) => {
  const [expenseName, setExpenseName] = useState();
  const [expenseDesc, setExpenseDesc] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadExpenseType() {
    props.addExpenseTypesApi(
      {
        expenseName: expenseName,
        expenseDesc: expenseDesc,
      },
      handleVisible,
      handleDangerVisible
    );
    setExpenseName("");
    setExpenseDesc("");
  }

  useEffect(() => {
    props.setHeading("Add Expense Types");
  }, []);

  return (
    <>
      {props.isAddingExpenseType ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Expense Name *</b>
                      </Form.Label>
                      <Form.Control
                        value={expenseName}
                        type="text"
                        placeholder="Expense Name"
                        onChange={(e) => setExpenseName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Expense Description </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={expenseDesc}
                        placeholder="Expense Description"
                        onChange={(e) => setExpenseDesc(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {_.isEmpty(expenseName) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadExpenseType}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addExpenseTypesApi: (data, handleVisible, handleDangerVisible) => dispatch(addExpenseTypesApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingExpenseType: state.ExpenseReducer.isAddingExpenseType,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddExpenseTypes);
