import API from "../../../../../global/api";

export const requestAddSubCategory = () => {
    return {
        type: "REQUEST_ADD_SUB_CATEGORY",
    };
};
export const successAddSubCategory = (data) => {
    return {
        type: "SUCCESS_ADD_SUB_CATEGORY",
        payload: data,
    };
};
export const errorAddSubCategory = () => {
    return {
        type: "ERROR_ADD_SUB_CATEGORY",
    };
};
export const addSubCategoryApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddSubCategory());
        API.post(`/sub_category`, data).then((res) => {
            // dispatch(getSubCategoryApi());
            dispatch(successAddSubCategory(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddSubCategory());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestGetSubCategory = () => {
    return {
        type: "REQUEST_GET_ALL_SUB_CATEGORY",
    };
};
export const successGetSubCategory = (data) => {
    return {
        type: "SUCCESS_GET_ALL_SUB_CATEGORY",
        payload: data,
    };
};
export const errorGetSubCategory = () => {
    return {
        type: "ERROR_GET_ALL_SUB_CATEGORY",
    };
};
export const getSubCategoryApi = () => {
    return (dispatch) => {
        dispatch(requestGetSubCategory());
        API.get(`/sub_category`).then((res) => {
            let data1 = res.data;
            dispatch(successGetSubCategory(data1));
        }).catch((error) => {
            dispatch(errorGetSubCategory());
        })
    };
};

export const DeleteRequestSubCategory = () => {
    return {
        type: "DELETE_REQUEST_SUB_CATEGORY",
    };
};
export const DeleteSuccessSubCategory = (user_id) => {
    return {
        type: "DELETE_SUCCESS_SUB_CATEGORY",
        user_id: user_id,
    };
};
export const DeleteErrorSubCategory = () => {
    return {
        type: "DELETE_ERROR_SUB_CATEGORY",
    };
};
export const deleteSubCategoryApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestSubCategory());
        API.delete(`/sub_category/${id}`).then(res => {
            dispatch(getSubCategoryApi());
            dispatch(DeleteSuccessSubCategory(id))
        }).catch((error) =>
            dispatch(DeleteErrorSubCategory()));
    }
};


export const UpdateRequestSubCategory = () => {
    return {
        type: "UPDATE_REQUEST_SUB_CATEGORY",
    };
};
export const UpdateSuccessSubCategory = (data) => {
    return {
        type: "UPDATE_SUCCESS_SUB_CATEGORY",
        payload: data,
    };
};
export const UpdateErrorSubCategory = () => {
    return {
        type: "UPDATE_ERROR_SUB_CATEGORY",
    };
};
export const updateSubCategoryApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestSubCategory());
        API.put(`/sub_category/${oldData._id}`,
            {
                subCategoryName: data.subCategoryName,
            }, {
        }).then(res => {
            dispatch(getSubCategoryApi());
            dispatch(UpdateSuccessSubCategory([data]))
        }).catch((error) =>
            dispatch(UpdateErrorSubCategory()));
    }
};


export const requestGetSubCategoryWithCategoryId = () => {
    return {
        type: "REQUEST_GET_ALL_SUB_CATEGORY_WITH_CATEGORY_ID",
    };
};
export const successGetSubCategoryWithCategoryId = (data) => {
    return {
        type: "SUCCESS_GET_ALL_SUB_CATEGORY_WITH_CATEGORY_ID",
        payload: data,
    };
};
export const errorGetSubCategoryWithCategoryId = () => {
    return {
        type: "ERROR_GET_ALL_SUB_CATEGORY_WITH_CATEGORY_ID",
    };
};
export const getSubCategoryWithCategoryIdApi = (id) => {
    return (dispatch) => {
        dispatch(requestGetSubCategoryWithCategoryId());
        API.get(`/sub_category/get_subCategory_with_category/${id}`).then((res) => {
            let data1 = res.data;
            dispatch(successGetSubCategoryWithCategoryId(data1));
        }).catch((error) => {
            dispatch(errorGetSubCategoryWithCategoryId());
        })
    };
};


export const resetReducerSubCategory = () => {
    return {
        type: "RESET_SUB_CATEGORY_URL",
    };
};