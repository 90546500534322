import API from "../../../../../global/api";
export const requestAddCategory = () => {
    return {
        type: "REQUEST_ADD_CATEGORY",
    };
};
export const successAddCategory = (data) => {
    return {
        type: "SUCCESS_ADD_CATEGORY",
        payload: data,
    };
};
export const errorAddCategory = () => {
    return {
        type: "ERROR_ADD_CATEGORY",
    };
};
export const addCategoryName = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddCategory());
        API.post(`/categories`, data).then((res) => {
            // dispatch(getCategoryData());
            dispatch(successAddCategory(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddCategory());
            handleDangerVisible(error.response.data.message);
        })
    };
};

export const DeleteRequestCategory = () => {
    return {
        type: "DELETE_REQUEST_CATEGORY",
    };
};
export const DeleteSuccessCategory = (user_id) => {
    return {
        type: "DELETE_SUCCESS_CATEGORY",
        user_id: user_id,
    };
};
export const DeleteErrorCategory = () => {
    return {
        type: "DELETE_ERROR_CATEGORY",
    };
};
export const deleteCategory = (categoryId) => {
    return dispatch => {
        dispatch(DeleteRequestCategory());
        API.delete(`/categories/${categoryId}`)
            .then(res => {
                dispatch(getCategoryData());
                dispatch(DeleteSuccessCategory(categoryId))
            }).catch((error) =>
                dispatch(DeleteErrorCategory()));
    }
};


export const UpdateRequestCategory = () => {
    return {
        type: "UPDATE_REQUEST_CATEGORY",
    };
};
export const UpdateSuccessCategory = (data) => {
    return {
        type: "UPDATE_SUCCESS_CATEGORY",
        payload: data,
    };
};
export const UpdateErrorCategory = () => {
    return {
        type: "UPDATE_ERROR_CATEGORY",
    };
};
export const updateCategory = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestCategory());
        console.log(data, 'hmhm')
        API.put(`/categories/${oldData._id}`,
            {
                name: data.name,
                // image: "https://www.chanchao.com.tw/vietnamwood/images/default.jpg",      
            }, {
        })
            .then(res => {
                dispatch(getCategoryData());
                dispatch(UpdateSuccessCategory([data]))
            }).catch((error) =>
                dispatch(UpdateErrorCategory()));
    }
};


export const requestGetAllCategoryData = () => {
    return {
        type: "REQUEST_GET_ALL_CATEGORY_DATA",
    };
};
export const successGetAllCategoryData = (data) => {
    return {
        type: "SUCCESS_GET_ALL_CATEGORY_DATA",
        payload: data,
    };
};
export const errorGetAllCategoryData = () => {
    return {
        type: "ERROR_GET_ALL_CATEGORY_DATA",
    };
};
export const getCategoryData = () => {
    return (dispatch) => {
        dispatch(requestGetAllCategoryData());
        API.get(`/categories?limit=1000&page=0&search=`).then((res) => {
            let data1 = res.data;
            dispatch(successGetAllCategoryData(data1));
        }).catch((error) => {
            dispatch(errorGetAllCategoryData());
        })
    };
};


export const requestAddCategoryImage = () => {
    return {
        type: "REQUEST_ADD_CATEGORY_IMAGE",
    };
};
export const successAddCategoryImage = (data) => {
    return {
        type: "SUCCESS_ADD_CATEGORY_IMAGE",
        payload: data,
    };
};
export const errorAddCategoryImage = () => {
    return {
        type: "ERROR_ADD_CATEGORY_IMAGE",
    };
};
export const addCategoryImage = (data) => {
    return (dispatch) => {
        const formData = new FormData();
        // formData.append("image", get(data, 'image'));
        formData.append("image", data.image)
        // .then(x => {
        //     console.log(formData, "dt")
        // })
        dispatch(requestAddCategoryImage());
        API.post(`/files/image`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            let getData = res.data;
            dispatch(successAddCategoryImage(getData));
        }).catch((error) => {
            dispatch(errorAddCategoryImage());
        })
    }
};
export const resetReducerCategory = () => {
    return {
        type: "RESET_CATEGORY_URL",
    };
};


export const requestCategoryByWarehouseId = () => {
    return {
        type: "REQUEST_CATEGORY_BY_WAREHOUSE_ID",
    };
};
export const successCategoryByWarehouseId = (data) => {
    return {
        type: "SUCCESS_CATEGORY_BY_WAREHOUSE_ID",
        payload: data,
    };
};
export const errorCategoryByWarehouseId = () => {
    return {
        type: "ERROR_CATEGORY_BY_WAREHOUSE_ID",
    };
};
export const getCategoryWithWarehouseApi = (id) => {
    return (dispatch) => {
        dispatch(requestCategoryByWarehouseId());
        API.get(`/categories/get_category_with_warehouse/${id}`)
            .then((res) => {
                let getData = res.data;
                dispatch(successCategoryByWarehouseId(getData));
            })
            .catch((error) => {
                dispatch(errorCategoryByWarehouseId());
            });
    };
};