import API from "../../../../../global/api";


export const requestAddOrderCreation = () => {
  return {
    type: "REQUEST_ADD_ORDER_CREATION",
  };
};
export const successAddOrderCreation = (data) => {
  return {
    type: "SUCCESS_ADD_ORDER_CREATION",
    payload: data,
  };
};
export const errorAddOrderCreation = () => {
  return {
    type: "ERROR_ADD_ORDER_CREATION",
  };
};

export const addOrderCreationApi = (data, handleVisible, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestAddOrderCreation());
    API.post(`/orderCreation/`, data).then((res) => {
      dispatch(successAddOrderCreation(data));
      handleVisible(res.data.message);
    }).catch((error) => {
      dispatch(errorAddOrderCreation());
      handleDangerVisible(error.response.data.message);
    });
  };
};


export const requestGetOrderCreation = () => {
  return {
    type: "REQUEST_GET_ORDER_CREATION",
  };
};
export const successGetOrderCreation = (data) => {
  return {
    type: "SUCCESS_GET_ORDER_CREATION",
    payload: data,
  };
};
export const errorGetOrderCreation = () => {
  return {
    type: "ERROR_GET_ORDER_CREATION",
  };
};
export const getOrderCreationApi = () => {
  return (dispatch) => {
    dispatch(requestGetOrderCreation());
    API.get(`/orderCreation/`).then((res) => {
      let getData = res.data;
      dispatch(successGetOrderCreation(getData));
    }).catch((error) => {
      dispatch(errorGetOrderCreation());
    });
  };
};