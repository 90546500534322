import React, { useEffect, useState } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import MaterialTable from "material-table";
import { baseURL, headers } from "../../../../../global/api";
import { deleteMasterControl, getMasterControlData, updateMasterControl } from "./MasterControl.action";
import { setHeading } from "../../../accounts/account.actions";


const ViewMasterControl = (props) => {
  const permissions = JSON.parse(window.localStorage.getItem("permissions"))
  const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? false : true
  }

  useEffect(() => {
    props.setHeading("Master Control List");
  }, []);

  return (
    <>
      {props.isFetchingMasterControl ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <MaterialTable
              title="MasterControl Details"
              columns={[
                // { title: 'MasterControl Id', field: 'table_id', editable: 'never' },
                { title: 'Order Voucher Account Name', field: 'orderVoucherAccount.levelFourName' },
                { title: 'Order Voucher Cash Account Name', field: 'orderVoucherCashAccount.levelFourName' },
              ]}
              data={(query) =>
                new Promise((resolve, reject) => {
                  let url = `${baseURL}/masterControl?`;
                  url += "limit=" + query.pageSize;
                  url += "&page=" + (query.page);
                  url += "&search=" + query.search;
                  fetch(url, {
                    method: 'GET',
                    headers: headers,
                  }).then((response) => response.json()).then((result) => {
                    resolve({
                      data: result.data,
                      page: result.page,
                      totalCount: result.total_results,
                    });
                  });
                })
              }
              options={{
                actionsColumnIndex: -1,
                exportButton: true,
                exportAllData: true,
                paging: true,
                pageMasterControl: 10,
                emptyRowsWhenPaging: false,
                pageMasterControlOptions: [10, 20, 50],
                headerStyle: {
                  position: 'sticky', top: 0,
                  color: '#00BBBB',
                  fontWeight: '550',
                  onRowAdd: 'none',
                },

              }}
            // editable={{
            //    onRowUpdate: (newData, oldData) =>
            //      new Promise((resolve) => {
            //        setTimeout(() => {
            //          resolve();
            //          {
            //            props.updateMasterControl(newData, oldData)
            //          }
            //        }, 600);
            //      }),
            //   onRowDelete: (oldData) =>
            //     new Promise((resolve) => {
            //       setTimeout(() => {
            //         resolve();
            //         props.deleteMasterControl(oldData._id);
            //       }, 600);
            //     }),
            // }}
            />
          </div>
        </Container>
      )}
    </>
  );
};
const mapDispatchToProps = (dispatch) => {
  return {
    getMasterControlData: () => dispatch(getMasterControlData()),
    deleteMasterControl: (tableId) => dispatch(deleteMasterControl(tableId)),
    updateMasterControl: (newData, oldData) => dispatch(updateMasterControl(newData, oldData)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  masterControlDataList: state.MasterControlReducer.masterControlDataList,
  isFetchingMasterControl: state.MasterControlReducer.isFetchingMasterControl,
});

export default connect(mapStateToProps, mapDispatchToProps)(ViewMasterControl);
