import React, { useEffect } from "react";
import { Container, } from "react-bootstrap";
import { connect } from "react-redux";
import MaterialSize from "material-table";
import Spinner from "react-bootstrap/Spinner";
// import { updatePurchaseApi, deletePurchaseApi } from "./PurchaseOrder.action";
import { individualPDF } from "./IGP.action";
import { baseURL, headers } from "../../../../../global/api";
import { setHeading } from "../../../accounts/account.actions";
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';


const PurchaseOrderList = (props) => {

    useEffect(() => {
        props.setHeading("IGP List");
    }, []);

    return (
        <>
            {props.isFetchingIGP ? (
                <div className="loader-div">
                    <Spinner
                        variant="primary"
                        animation="grow"
                        size="xl"
                        role="status"
                        aria-hidden="true"
                    />
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialSize
                            title="IGP Details"
                            columns={[
                                { title: "Purchase Order", field: "purchaseOrderId.manualPurchaseNumber" },
                                { title: "Gatepass", field: "gatePass" },
                                { title: "Reciver Name ", field: "receivername" },
                                { title: "Order Type ", field: "orderType" },
                                { title: "Description ", field: "igpDescription" },
                                { title: "Date ", field: "igpDate" },
                            ]}
                            detailPanel={rowData => {
                                return (
                                    <MaterialSize
                                        columns={[
                                            { title: 'Product Name', field: 'inventoryLabel' },
                                            { title: 'IGP Quantity', field: 'productQuantity' },
                                        ]}
                                        data={rowData.data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [30, 50, 100, 150, 200],
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            data={(query) =>
                                new Promise((resolve, reject) => {
                                    let url = `${baseURL}/igp?`;
                                    url += "limit=" + query.pageSize;
                                    url += "&page=" + (query.page);
                                    url += "&search=" + query.search;
                                    fetch(url, {
                                        method: "GET",
                                        headers: headers,
                                    }).then((response) => response.json()).then((result) => {
                                        resolve({
                                            data: result.data,
                                            page: result.page,
                                            totalCount: result.total_results,
                                        });
                                    });
                                })
                            }
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                exportAllData: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [30, 50, 100, 150, 200],
                                headerStyle: {
                                    position: "sticky",
                                    top: 0,
                                    color: "#00BBBB",
                                    fontWeight: "550",
                                    onRowAdd: "none",
                                },
                            }}
                            // editable={{
                            //     onRowUpdate: (newData, oldData) =>
                            //         new Promise((resolve) => {
                            //             setTimeout(() => {
                            //                 resolve();
                            //                 {
                            //                     props.updatePurchaseApi(newData, oldData);
                            //                 }
                            //             }, 600);
                            //         }),
                            //     onRowDelete: (oldData) =>
                            //         new Promise((resolve) => {
                            //             setTimeout(() => {
                            //                 resolve();
                            //                 props.deletePurchaseApi(oldData._id);
                            //             }, 600);
                            //         }),
                            // }}
                            actions={[
                                (rowData) => ({
                                    icon: () => <LocalPrintshopIcon />,
                                    tooltip: "Print IGP",
                                    onClick: (event, rowData) => {
                                        props.individualPDF(rowData);
                                    },
                                }),
                            ]}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};


const mapDispatchToProps = (dispatch) => {
    return {
        // deletePurchaseApi: (id) => dispatch(deletePurchaseApi(id)),
        // updatePurchaseApi: (newData, oldData) => dispatch(updatePurchaseApi(newData, oldData)),
        setHeading: (data) => dispatch(setHeading(data)),
        individualPDF: (data) => dispatch(individualPDF(data)),
    };
};
const mapStateToProps = (state, ownProps) => ({
    IGPList: state.IGPReducer.IGPList,
    isFetchingIGP: state.IGPReducer.isFetchingIGP,
});

export default connect(mapStateToProps, mapDispatchToProps)(PurchaseOrderList);