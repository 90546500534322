import API from "../../../../../global/api";
import { get, isEmpty } from 'lodash';
import jsPDF from "jspdf";
const permissions = JSON.parse(window.localStorage.getItem("permissions"))
const Checkpermissions = (permissionsType, permissionsName) => {
    return permissions.some(p => p[`${permissionsType}`] === permissionsName) ? true : false
}

export const requestAddOrders = () => {
    return {
        type: "REQUEST_ADD_ORDERS",
    };
};
export const successAddOrders = (data) => {
    return {
        type: "SUCCESS_ADD_ORDERS",
        payload: data,
    };
};
export const errorAddOrders = () => {
    return {
        type: "ERROR_ADD_ORDERS",
    };
};
export const AddOrders = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddOrders());
        API.post(`/orders`, data).then((res) => {
            const resData = res.data
            dispatch(successAddOrders(data));
            handleVisible(res.data.message);
            if (data.isPrint == 1) {
                { Checkpermissions("permissionsCatName", "Enable PDF Recipt Print") && OrderRecipt(resData) };
            }
        }).catch((error) => {
            dispatch(errorAddOrders());
            handleDangerVisible(isEmpty(error.response.data) ? "Server Error" : error.response.data.message);
        })
    };
};
export const OrderRecipt = (resData) => {
    const pdfData = resData.item;
    // const pdfDataCus = resData.item.customer;
    const unit = "pt";
    const size = 'A4';
    const orientation = "p";
    const marginLeft = 0;
    const doc = new jsPDF(orientation, unit, size);
    var pageWidth = doc.internal.pageSize.width || doc.internal.pageSize.getWidth();
    var pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();


    // const title = `Order ${pdfData.number}`;
    const companyName = `${resData.resImage.restaurantName}`;
    const companyAddress = `${resData.resImage.restaurantLocation}`;
    const companyNtn = `${resData.resImage.ntn}`;
    // const logo1 = resData.restaurantImage;
    // doc.addImage(logo1, "PNG", 1, 1, 70, 20);
    doc.setFontSize(14);
    doc.setFont("TimesNewRoman", "Normal");
    doc.setFont(undefined, "bold");
    doc.text(companyName, pageWidth / 2, 40, { align: 'center' });
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(companyAddress, pageWidth / 2, 60, { align: 'center' });
    doc.text(companyNtn, pageWidth / 2, 80, { align: 'center' });
    // doc.setFontSize(12);
    // doc.setFont("TimesNewRoman", "Normal");
    // doc.text(title, 260, 70, 0, 0);
    doc.text(`Order Date : ${pdfData.orderDate}`, 44, 100)
    doc.text(`Order No : ${pdfData.number}`, 44, 120)

    const columnTitles = [
        { title: 'Sr' },
        { title: "Product Name" },
        { title: "Product Price" },
        { title: "Product Quantity" },
        { title: "Product Total" },
    ];
    const itemData = pdfData.items.map((columnDef, index) => [
        index + 1,
        columnDef.productLabel,
        columnDef.productSellingPrice,
        columnDef.quantity,
        columnDef.productTotalAmount,
    ]);
    let content = {
        startY: 140,
        startX: 5,
        head: [columnTitles],
        body: itemData,
    };
    doc.setFont("TimesNewRoman", "Normal");
    doc.autoTable(content)
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(`Sub Total : ${pdfData.subTotal}`, 400, doc.lastAutoTable.finalY + 20)
    doc.text(`Discount : ${pdfData.discount}`, 400, doc.lastAutoTable.finalY + 40)
    doc.text(`Tax : ${pdfData.tax}`, 400, doc.lastAutoTable.finalY + 60)
    doc.text(`Grand Total : ${pdfData.grandTotal}`, 400, doc.lastAutoTable.finalY + 80)
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.setFont("TimesNewRoman", "Normal");
    doc.text(`Thanks For Visiting At "${companyName}"`, 44, doc.lastAutoTable.finalY + 60)
    doc.text(`Powerd By ReportERP`, 44, doc.lastAutoTable.finalY + 80)


    doc.setProperties({ title: `Recipt Order ${pdfData.number}` });
    // doc.autoPrint();
    doc.output('dataurlnewwindow');
};


export const requestGetOrders = () => {
    return {
        type: "REQUEST_GET_ORDERS",
    };
};
export const successGetOrders = (data) => {
    return {
        type: "SUCCESS_GET_ORDERS",
        payload: data,
    };
};
export const errorGetAllOrders = () => {
    return {
        type: "ERROR_GET_ORDERS",
    };
};
export const getOrders = () => {
    return (dispatch) => {
        dispatch(requestGetOrders());
        API.get(`/orders?limit=1000&page=0&search=`).then((res) => {
            let data1 = res.data;
            dispatch(successGetOrders(data1));
        }).catch((error) => {
            dispatch(errorGetAllOrders());
        })
    };
};


export const requestGetOrderById = () => {
    return {
        type: "REQUEST_ORDER_EDIT_DATA",
    };
};
export const successGetOrderById = (data) => {
    return {
        type: "SUCCESS_ORDER_EDIT_DATA",
        payload: data,
    };
};
export const errorGetOrderById = () => {
    return {
        type: "ERROR_ORDER_EDIT_DATA",
    };
}
export const GetOrderById = (orderId) => {
    return dispatch => {
        dispatch(requestGetOrderById());
        API.get(`/orders/get_order_by_id/${orderId}`).then(res => {
            let Data = res.data;
            //  dispatch(getOrders());
            dispatch(successGetOrderById(Data))
        }).catch((error) =>
            dispatch(errorGetOrderById()));
    }
};


export const UpdateRequestOrders = () => {
    return {
        type: "UPDATE_REQUEST_ORDERS",
    };
};
export const UpdateSuccessOrders = (data) => {
    return {
        type: "UPDATE_SUCCESS_ORDERS",
        payload: data,
    };
};
export const UpdateErrorOrders = () => {
    return {
        type: "UPDATE_ERROR_ORDERS",
    };
};
export const updateOrders = (data, oldData, handleVisible, handleDangerVisible) => {
    return dispatch => {
        dispatch(UpdateRequestOrders());
        console.log(data, oldData, 'hmhm')
        API.put(`/orders/update_order/${oldData._id}`, data).then(res => {
            // dispatch(getOrders());
            dispatch(UpdateSuccessOrders([data]))
            handleVisible()
        }).catch((error) => {
            dispatch(UpdateErrorOrders());
            handleDangerVisible()
        })
    }
};


export const DeleteRequestOrders = () => {
    return {
        type: "DELETE_REQUEST_ORDERS",
    };
};
export const DeleteSuccessOrders = (user_id) => {
    return {
        type: "DELETE_SUCCESS_ORDERS",
        user_id: user_id,
    };
};
export const DeleteErrorOrders = () => {
    return {
        type: "DELETE_ERROR_ORDERS",
    };
};
export const deleteOrders = (categoryId) => {
    return dispatch => {
        dispatch(DeleteRequestOrders());
        API.delete(`/orders/${categoryId}`).then(res => {
            // dispatch(getOrders());
            dispatch(DeleteSuccessOrders(categoryId))
        }).catch((error) =>
            dispatch(DeleteErrorOrders()));
    }
};


export const requestOrderCancel = () => {
    return {
        type: "REQUEST_ORDER_CANCEL",
    };
};
export const successOrderCancel = (data) => {
    return {
        type: "SUCCESS_ORDER_CANCEL",
        payload: data,
    };
};
export const errorOrderCancel = () => {
    return {
        type: "ERROR_ORDER_CANCEL",
    };
}
export const OrderCancel = (orderId, data) => {
    return dispatch => {
        dispatch(requestOrderCancel());
        API.post(`/orders/order_cancelled/${orderId}`, data).then(res => {
            dispatch(getTodayOrders());
            dispatch(successOrderCancel(orderId))
        }).catch((error) =>
            dispatch(errorOrderCancel()));
    }
};


export const requestOrderEdit = () => {
    return {
        type: "REQUEST_ORDER_EDIT",
    };
};
export const successOrderEdit = (data) => {
    return {
        type: "SUCCESS_ORDER_EDIT",
        payload: data,
    };
};
export const errorOrderEdit = () => {
    return {
        type: "ERROR_ORDER_EDIT",
    };
}
export const OrderEdit = (orderId) => {
    return dispatch => {
        dispatch(requestOrderEdit());
        API.get(`/orders/order_edit/${orderId}`)
            .then(res => {
                //  dispatch(getOrders());
                dispatch(successOrderEdit(orderId))
            }).catch((error) =>
                dispatch(errorOrderEdit()));
    }
};


export const requestAddCustomer = () => {
    return {
        type: "REQUEST_ADD_CUSTOMER",
    };
};
export const successAddCustomer = (data) => {
    return {
        type: "SUCCESS_ADD_CUSTOMER",
        payload: data,
    };
};
export const errorAddCustomer = () => {
    return {
        type: "ERROR_ADD_CUSTOMER",
    };
};
export const addCustomer = (data, handleVisible, handleDangerVisible, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestAddCustomer());
        API.post(`/customer/add_customer`, data).then((res) => {
            dispatch(getCustomersData());
            dispatch(successAddCustomer(data));
            handleVisible();
            updateTableQuery();
        }).catch((error) => {
            dispatch(errorAddCustomer());
            handleDangerVisible();
        })
    };
};


export const requestCustomersData = () => {
    return {
        type: "REQUEST_CUSTOMERS_GET_DATA",
    };
};
export const successCustomersGetData = (data) => {
    return {
        type: "SUCCESS_CUSTOMERS_GET_DATA",
        payload: data,
    };
};
export const errorCustomersGetData = () => {
    return {
        type: "ERROR_CUSTOMERS_GET_DATA",
    };
};
export const getCustomersData = (limit, page, search) => {
    return (dispatch) => {
        dispatch(requestCustomersData());
        API.get(`/customer/get_all_customer?size=${limit}&page=${page}&search=${search}`).then((res) => {
            let getData = res.data;
            dispatch(successCustomersGetData(getData));
        }).catch((error) => {
            dispatch(errorCustomersGetData());
        })
    };
};


export const requestOrderTypes = () => {
    return {
        type: "REQUEST_ORDER_TYPES",
    };
};
export const successOrderTypes = (data) => {
    console.log(data, " order data");
    return {
        type: "SUCCESS_ORDER_TYPES",
        payload: data,
    };
};
export const errorOrderTypes = () => {
    return {
        type: "ERROR_ORDER_TYPES",
    };
};
export const getOrderTypes = () => {
    return (dispatch) => {
        dispatch(requestOrderTypes());
        API.get(`/restaurant_table/get_order_types`).then((res) => {
            let getData = res.data;
            dispatch(successOrderTypes(getData));
        }).catch((error) => {
            dispatch(errorOrderTypes());
        })
    };
};


export const requestAddTables = () => {
    return {
        type: "REQUEST_ADD_TABLES",
    };
};
export const successAddTables = (data) => {
    return {
        type: "SUCCESS_ADD_TABLES",
        payload: data,
    };
};
export const errorAddTables = () => {
    return {
        type: "ERROR_ADD_TABLES",
    };
}
export const addTables = (table, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddTables());
        API.post(`/restaurant_table/add_restaurant_table`, table).then((res) => {
            let getData = res.data;
            dispatch(successAddTables(getData.data));
            handleVisible();
        }).catch((error) => {
            dispatch(errorAddTables());
            handleDangerVisible();
        })
    };
};


export const requestTables = () => {
    return {
        type: "REQUEST_TABLES",
    };
};
export const successTables = (data) => {
    return {
        type: "SUCCESS_TABLES",
        payload: data,
    };
};
export const errorTables = () => {
    return {
        type: "ERROR_TABLES",
    };
}
export const getTables = () => {
    return (dispatch) => {
        dispatch(requestTables());
        API.get(`/restaurant_table/get_restaurant_table`).then((res) => {
            let getData = res.data;
            dispatch(successTables(getData));
        }).catch((error) => {
            dispatch(errorTables());
        })
    };
};


export const requestDeleteTables = () => {
    return {
        type: "REQUEST_DELETE_TABLES",
    };
};
export const successDeleteTables = (data) => {
    return {
        type: "SUCCESS_DELETE_TABLES",
        payload: data,
    };
};
export const errorDeleteTables = () => {
    return {
        type: "ERROR_DELETE_TABLES",
    };
}
export const deleteTables = (table, handleVisible, handleDangerVisible, updateTableQuery) => {
    return (dispatch) => {
        dispatch(requestDeleteTables());
        API.get(`/restaurant_table/add_restaurant_table/${table}`).then((res) => {
            let getData = res.data;
            dispatch(successDeleteTables(getData.data));
        }).catch((error) => {
            dispatch(errorDeleteTables());
        })
    };
};

















//Reset state to default
export const resetStateCustomers = () => {
    return {
        type: "RESET_STATE_Customers",
    };
};
//sET ACTIOS FOR SEATS
export const setSeatAvaliable = (data) => {
    return {
        type: "SET_SEAT_AVAILABLE",
        payload: data,
    };
};
export const setSeatSelected = (data) => {
    console.log(data, "dddddddddddddddddddddddddddddddd222")
    return {
        type: "SET_SEAT_SELECTED",
        payload: data,
    };
};
export const setSeatReserved = (data) => {
    console.log(data, "dddddddddddddddddddddddddddddddd")
    return {
        type: "SET_SEAT_RESERVEDX",
        payload: data,
    };
};


export const getCustomersDataWithoutPagination = (limit, page, search) => {
    return (dispatch) => {
        dispatch(requestCustomersData());
        API.get(`/customer/get_all_customer_menu`).then((res) => {
            let getData = res.data;
            dispatch(successCustomersGetData(getData.data));
        }).catch((error) => {
            dispatch(errorCustomersGetData());
        })
    };
};


export const deleteCustomer = (customerId) => {
    return dispatch => {
        //  dispatch(requestDeleteInventory());
        API.delete(`/customer/delete_customer/${customerId}`)
            .then(res => {
                dispatch(getCustomersData());
                //  dispatch(successDeleteCustomer(wareHouseId))
            }).catch((error) => { }
                //    dispatch(errorDeleteCustomer())
            );
    }
};


export const requestGetTodayOrders = () => {
    return {
        type: "REQUEST_GET_TODAY_ORDERS",
    };
};
export const successGetTodayOrders = (data) => {
    return {
        type: "SUCCESS_GET_TODAY_ORDERS",
        payload: data,
    };
};
export const errorGetTodayOrders = () => {
    return {
        type: "ERROR_GET_TODAY_ORDERS",
    };
};
export const getTodayOrders = () => {
    return (dispatch) => {
        dispatch(requestGetTodayOrders());
        API.get(`/orders/get_dayopen_orders`).then((res) => {
            let data1 = res.data;
            console.log(data1, 'list1');
            dispatch(successGetTodayOrders(data1));
        }).catch((error) => {
            dispatch(errorGetTodayOrders());
        })
    };
};
//update order

//delete order


export const setTest = (h) => {
    return (dispatch) => {
        dispatch(requestCustomersData());
    };
};
//order cancel

// edit order

//get order by id

//edit addons
export const editOrder = (data) => {
    console.log(data, 'abcd1111')
    return {
        type: "EDIT_ORDER",
        payload: data
    };
};
export const editOrderCooking = (data) => {
    console.log(data, 'abcd1111')
    return {
        type: "EDIT_ORDER_COOKING",
        payload: data
    };
};
export const editOrderCustomer = (data) => {
    console.log(data, 'customer edit')
    return {
        type: "EDIT_ORDER_CUSTOMER",
        payload: data
    };
};
export const editOrderCoupon = (data) => {
    console.log(data, 'COUPON')
    return {
        type: "EDIT_ORDER_COUPON",
        payload: data
    };
};
export const editOrderTotal = (data) => {
    console.log(data, 'Total bill')
    return {
        type: "EDIT_ORDER_TOTAL",
        payload: data
    };
};
export const editOrderDiscount = (data) => {
    console.log(data, 'Total bill')
    return {
        type: "EDIT_ORDER_DISCOUNT",
        payload: data
    };
};
export const editOrderSubTotal = (data) => {
    console.log(data, 'subtotalbill')
    return {
        type: "EDIT_ORDER_SUB_TOTAL",
        payload: data
    };
};
//get pending order
export const requestGetPendingOrder = () => {
    return {
        type: "REQUEST_GET_PENDING_ORDER",
    };
};
export const successGetPendingOrder = (data) => {
    return {
        type: "SUCCESS_GET_PENDING_ORDER",
        payload: data,
    };
};
export const errorGetPendingOrder = () => {
    return {
        type: "ERROR_GET_PENDING_ORDER",
    };
};
export const getPendingOrder = () => {
    return (dispatch) => {
        dispatch(requestGetPendingOrder());
        API.get(`/orders/get_pending_orders?page=0&limit=1000`)
            .then((res) => {
                let data = res.data;
                dispatch(successGetPendingOrder(data));
                console.log("i am in success");
            })
            .catch((error) => {
                dispatch(errorGetPendingOrder());
            });
    };
};
//get complete order
export const requestGetCompleteOrders = () => {
    return {
        type: "REQUEST_GET_COMPLETE_ORDERS",
    };
};
export const successGetCompleteOrders = (data) => {
    return {
        type: "SUCCESS_GET_COMPLETE_ORDERS",
        payload: data,
    };
};
export const errorGetCompleteOrders = () => {
    return {
        type: "ERROR_GET_COMPLETE_ORDERS",
    };
};
export const getCompleteOrders = () => {
    return (dispatch) => {
        dispatch(requestGetCompleteOrders());
        API.get(`/orders/get_complete_orders?limit=1000&page=0&search=`).then((res) => {
            let data1 = res.data;
            console.log(data1, 'list1');
            dispatch(successGetCompleteOrders(data1));
        }).catch((error) => {
            dispatch(errorGetCompleteOrders());
        })
    };
};
//filter product
export const filterType = (data) => {
    console.log(data, "jjjjjjjjjj")
    return {
        type: "FILTER_TYPE",
        payload: data
    };
};
export const filterOrder = (data) => {
    console.log(data, "jjjjjjjjjj")
    return {
        type: "FILTER_ORDER",
        payload: data
    };
};
export const filterOrderByTable = (data) => {
    console.log(data, "jjjjjjjjjj")
    return {
        type: "FILTER_ORDER_BY_TABLE",
        payload: data
    };
};

// //filter by menu
// export const filterItemByMenu = (data) => {
//     console.log(data,"jjjjjjjjjj")
//     return {
//         type: "FILTER_ITEM_BY_MENU",
//         payload: data
//     };
// };