import API from "../../../../../global/api";

export const requestAddBrand = () => {
    return {
        type: "REQUEST_ADD_BRAND",
    };
};
export const successAddBrand = (data) => {
    return {
        type: "SUCCESS_ADD_BRAND",
        payload: data,
    };
};
export const errorAddBrand = () => {
    return {
        type: "ERROR_ADD_BRAND",
    };
};
export const addBrandApi = (data, handleVisible, handleDangerVisible) => {
    return (dispatch) => {
        dispatch(requestAddBrand());
        API.post(`/brand`, data).then((res) => {
            // dispatch(getBrandApi());
            dispatch(successAddBrand(data));
            handleVisible(res.data.message);
        }).catch((error) => {
            dispatch(errorAddBrand());
            handleDangerVisible(error.response.data.message);
        })
    };
};


export const requestBrandData = () => {
    return {
        type: "REQUEST_BRAND_GET_DATA",
    };
};
export const successBrandGetData = (data) => {
    return {
        type: "SUCCESS_BRAND_GET_DATA",
        payload: data,
    };
};
export const errorBrandGetData = () => {
    return {
        type: "ERROR_BRAND_GET_DATA",
    };
};
export const getBrandApi = () => {
    return (dispatch) => {
        dispatch(requestBrandData());
        API.get(`/brand`).then((res) => {
            let getData = res.data;
            dispatch(successBrandGetData(getData));
        }).catch((error) => {
            dispatch(errorBrandGetData());
        })
    };
};


export const DeleteRequestBrand = () => {
    return {
        type: "DELETE_REQUEST_BRAND",
    };
};
export const DeleteSuccessBrand = (user_id) => {
    return {
        type: "DELETE_SUCCESS_BRAND",
        user_id: user_id,
    };
};
export const DeleteErrorBrand = () => {
    return {
        type: "DELETE_ERROR_BRAND",
    };
};
export const deleteBrandApi = (id) => {
    return dispatch => {
        dispatch(DeleteRequestBrand());
        API.delete(`/brand/${id}`).then(res => {
            dispatch(getBrandApi());
            dispatch(DeleteSuccessBrand(id))
        }).catch((error) => {
            dispatch(DeleteErrorBrand())
        });
    }
};


export const UpdateRequestBrand = () => {
    return {
        type: "UPDATE_REQUEST_SIZE",
    };
};
export const UpdateSuccessBrand = (data) => {
    return {
        type: "UPDATE_SUCCESS_SIZE",
        payload: data,
    };
};
export const UpdateErrorBrand = () => {
    return {
        type: "UPDATE_ERROR_SIZE",
    };
};
export const updateBrandApi = (data, oldData) => {
    return dispatch => {
        dispatch(UpdateRequestBrand());
        API.put(`/size/${oldData._id}`, {
            brandName: data.brandName,
            brandDetails: data.brandDetails,
        }, {
        }).then(res => {
            dispatch(getBrandApi());
            dispatch(UpdateSuccessBrand([data]))
        }).catch((error) =>
            dispatch(UpdateErrorBrand()));
    }
};