const initialState = {
  demand: [],
  isAddingDemand: false,
  isFetchingDemand: false,
  demandList: [],
};

const DemandReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_DEMAND":
      return { ...state, isAddingDemand: true };
    case "SUCCESS_ADD_DEMAND":
      return { ...state, demand: action.payload, isAddingDemand: false };
    case "ERROR_ADD_DEMAND":
      return { ...state, isAddingDemand: false };

    case "REQUEST_GET_DEMAND":
      return { ...state, isFetchingDemand: true };
    case "SUCCESS_GET_DEMAND":
      return { ...state, demandList: action.payload.data, isFetchingDemand: false, };
    case "ERROR_GET_DEMAND":
      return { ...state, isFetchingDemand: false };

    default:
      return state;
  }
};
export default DemandReducer;
