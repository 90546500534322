import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container } from "react-bootstrap";
import { connect } from "react-redux";
import _ from "lodash";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert";
import { addBrandApi } from "./Brand.action";
import { setHeading } from "../../../accounts/account.actions";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddBrand = (props) => {
  const [brandName, setBrandName] = useState();
  const [brandDetails, setBrandDetails] = useState();
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);


  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  };

  function uploadBrand() {
    props.addBrandApi(
      {
        brandName: brandName,
        brandDetails: brandDetails,
      },
      handleVisible,
      handleDangerVisible
    );
    setBrandName("");
    setBrandDetails("");
  }

  useEffect(() => {
    props.setHeading("Add Brand");
  }, []);

  return (
    <>
      {props.isAddingBrand || props.isFetchingBrand ? (
        <div className="loader-div">
          <Spinner
            variant="primary"
            animation="grow"
            size="xl"
            role="status"
            aria-hidden="true"
          />
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <div>
              <Form>
                <Row>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Brand Name *</b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Brand Name"
                        onChange={(e) => setBrandName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg="6" md="6" xs="6">
                    <Form.Group className="mb-3" controlId="garana">
                      <Form.Label>
                        <b>Brand Details </b>
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={brandDetails}
                        placeholder="Brand Details"
                        onChange={(e) => setBrandDetails(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="sendDiv">
                  {_.isEmpty(brandName) ? (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
                  ) : (
                    <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadBrand}> SAVE </Button>
                  )}
                </div>

              </Form>
            </div>
          </div>
        </Container>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    addBrandApi: (data, handleVisible, handleDangerVisible) => dispatch(addBrandApi(data, handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingBrand: state.brandReducer.isAddingBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(AddBrand);
