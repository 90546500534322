import React, { useEffect, useState } from "react";
import { Container, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Spinner from 'react-bootstrap/Spinner';
import MaterialTable from 'material-table';
import { getIssuance } from "./Issuance.action";

const IssuanceDetail = (props) => {

    const wareHouseData = props.IssuanceList;
    useEffect(() => {
        props.getIssuance();
    }, []);

    return (
        <>
            {props.isFetchingIssuance ? (
                <div className="loader-div">
                    <Button variant="info" disabled>
                        <Spinner
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            variant="info"
                        />
                        {props.isFetchingIssuance ? 'Loading...' : 'Loading...'}
                    </Button>
                </div>
            ) : (
                <Container fluid>
                    <div className="main">
                        <MaterialTable
                            title="Issuance Details"
                            columns={[
                                { title: 'Issuance Date', field: 'issuanceDate' },
                                { title: 'Business Location', field: 'wareHouseId.businessName' },
                                { title: 'Supervisor', field: 'supervisor' },
                                { title: 'Issued By', field: 'issuedBy' },
                                { title: 'Received By', field: 'receivedBy' },
                            ]}
                            data={wareHouseData}
                            detailPanel={rowData => {
                                return (
                                    <MaterialTable
                                        columns={[
                                            { title: 'Product Code', field: 'inventoryId.code' },
                                            { title: 'Product Name', field: 'inventoryLabel' },
                                            { title: 'Issuance Quantity', field: 'issuanceQuantity' },
                                        ]}
                                        data={rowData.data}
                                        options={{
                                            actionsColumnIndex: -1,
                                            toolbar: false,
                                            exportButton: false,
                                            sorting: false,
                                            paging: false,
                                            pageSize: 50,
                                            emptyRowsWhenPaging: false,
                                            pageSizeOptions: [30, 50, 100, 150, 200], 
                                            headerStyle: {
                                                position: 'sticky', top: 0,
                                                color: '#00BBBB',
                                                fontWeight: '550',
                                                onRowAdd: 'none',
                                            }
                                        }}
                                    />
                                )
                            }}
                            options={{
                                actionsColumnIndex: -1,
                                filtering: false,
                                exportButton: true,
                                paging: true,
                                pageSize: 50,
                                emptyRowsWhenPaging: false,
                                pageSizeOptions: [30, 50, 100, 150, 200],
                                headerStyle: {
                                    position: 'sticky', top: 0,
                                    color: '#00BBBB',
                                    fontWeight: '550',
                                    onRowAdd: 'none',
                                }
                            }}
                            onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};
const mapDispatchToProps = (dispatch) => {
    return {
        getIssuance: () => dispatch(getIssuance()),
    };
};
const mapStateToProps = (state, ownProps) => ({
    isFetchingIssuance: state.IssuanceReducer.isFetchingIssuance,
    IssuanceList: state.IssuanceReducer.IssuanceList
});

export default connect(mapStateToProps, mapDispatchToProps)(IssuanceDetail);