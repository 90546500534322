import React, { useEffect, useState } from "react";
import { Row, Col, Form, Container, FormGroup } from "react-bootstrap";
import { addIGPApi } from "./IGP.action";
import { getPurchaseApi, getPurchaseWithIdApi } from "../PurchaseOrder/PurchaseOrder.action";
import { connect } from "react-redux";
import { isEmpty, find, filter } from "lodash";
import moment from "moment";
import Spinner from "react-bootstrap/Spinner";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import Select from "react-select";
import Swal from "sweetalert";
import SendIcon from '@mui/icons-material/Send';
import Button from '@mui/material/Button';


const AddIGP = (props) => {
  const [orders, setOrders] = useState();
  const [igpDate, setIgpDate] = useState(moment(new Date()).format("YYYY-MM-DD"));
  const [ordersLabel, setOrdersLabel] = useState();
  const [gatePass, setGatePass] = useState();
  const [receiverName, setReceiverName] = useState();
  const [igpDescription, setIGPDescription] = useState();
  const [purchaseNameValue, setPurchaseNameValue] = useState();
  const [purchaseNameLabel, setPurchaseNameLabel] = useState();
  const [maximumQuantity, setMaximumQuantity] = useState(0);
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [inputField, setInputField] = useState([{
    inventoryId: "",
    productQuantityCount: "",
    productQuantity: "",
    inventoryLabel: "",
  }]);

  function uploadIGP() {
    props.addIGPApi(
      {
        gatePass: gatePass,
        igpDate: igpDate,
        receivername: receiverName,
        orderType: orders,
        purchaseOrderId: purchaseNameValue,
        igpDescription: igpDescription,
        data:
          orders === "Full" ? props.purchaseWithIdList[0].data.map((x) => {
            let data = {
              inventoryId: x.inventoryId,
              productQuantity: x.remainingIgp,
              inventoryLabel: x.inventoryLabel,
            };
            return data;
          }) : inputField,
      },
      handleVisible,
      handleDangerVisible
    );
    setOrders();
    setOrdersLabel("");
    setGatePass("");
    setReceiverName("");
    setIGPDescription("");
    setPurchaseNameValue("")
    setPurchaseNameLabel("");
    setInputField([{
      inventoryId: "",
      productQuantityCount: "",
      productQuantity: "",
      inventoryLabel: "",
    }]);
  }
  const handleVisible = () => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: "IGP Added Successfully",
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Something went Wrong!",
      icon: "error",
      button: "Ok",
    });
  };


  const handleChangeOrderType = (selectedOption) => {
    setOrders(selectedOption.value);
    setOrdersLabel(selectedOption.label);
  };
  const getOrderType = [
    { value: "Full", label: "Full" },
    { value: "Partial", label: "Partial" },
  ];
  const handleChangeGetPurchase = (selectedOption) => {
    setPurchaseNameValue(selectedOption.value);
    setPurchaseNameLabel(selectedOption.label);
    props.getPurchaseWithIdApi(selectedOption.value);
  };
  const getPurchaseListFilter = !isEmpty(props.purchaseList) && filter(props.purchaseList, { 'status': 'Approved' }).map((x) => {
    let data = {
      value: x._id,
      label: x.seqNumber + " / " + x.manualPurchaseNumber,
    };
    return data;
  });

  const inventory = isEmpty(props.purchaseWithIdList) ? [] : props.purchaseWithIdList[0].data.map((x) => {
    let data = {
      value: x.inventoryId,
      label: x.inventoryLabel,
    };
    return data;
  });

  const handleChange = (e, index, type) => {
    let z = find(props.purchaseWithIdList, (x) => x.data.map((a) => {
      return a.inventoryId == e.value;
    }));
    let y = z.data.find((x) => x.inventoryId == e.value);
    const { name, value } = e;
    const list = [...inputField];
    if (type == "intname") list[index]["inventoryId"] = e.value;
    if (type == "intname") list[index]["productQuantityCount"] = Number(y.remainingIgp);
    if (type == "intname") list[index]["inventoryLabel"] = e.label;
    if (type == "intname") setMaximumQuantity(Number(y.remainingIgp));
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value;
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value < 0 ? alert("Cannot be Add Less Than 0") : e.target.value;
    if (type == "intquantity") list[index]["productQuantity"] = e.target.value > maximumQuantity ? alert("Cannot be Add Greater than Remain") : e.target.value;
    setInputField(list);
  };

  const handleAddInput = () => {
    setInputField([
      ...inputField,
      { inventoryId: "", productQuantityCount: "", productQuantity: 0, },
    ]);
  };
  const handleRemoveInput = (index) => {
    const list = [...inputField];
    list.splice(index, 1);
    setInputField(list);
  };

  useEffect(() => {
    props.getPurchaseApi();
    // props.resetPurchaseReducer();
  }, []);

  return (
    <>
      {props.isAddingIGP || props.isFetchingPurchase ? (
        <div className="loader-div">
          <Button variant="info" disabled>
            <Spinner
              animation="grow"
              size="sm"
              role="status"
              aria-hidden="true"
              variant="info"
            />
            {props.isAddingIGP || props.isFetchingPurchase ? "Saving" : "Loading"}
          </Button>
        </div>
      ) : (
        <Container fluid>
          <div className="main">
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b>Select Purchase Order *</b>
                  </Form.Label>
                  <Select
                    value={{ label: purchaseNameLabel }}
                    isDisabled={isEmpty(getPurchaseListFilter)}
                    placeholder="Select Purchase"
                    onChange={handleChangeGetPurchase}
                    options={isEmpty(getPurchaseListFilter) ? [] : getPurchaseListFilter.reverse()}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>
                    <b>Date *</b>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    placeholder="Date"
                    name="Date"
                    value={igpDate}
                    onChange={(e) => setIgpDate(moment(e.target.value).format("YYYY-MM-DD"))}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Manual GatePass Number *</b>
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Manual GatePass Number"
                    name="gatePass"
                    value={gatePass}
                    onChange={(e) => setGatePass(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xl="6" lg="6" md="6" sm="6">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Receiver Name </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Receiver Name"
                    name="receiverName"
                    value={receiverName}
                    onChange={(e) => setReceiverName(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group className="mb-3">
                  <Form.Label>
                    <b>Description </b>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Description"
                    name="igpDescription"
                    value={igpDescription}
                    onChange={(e) => setIGPDescription(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col xl="12" lg="12" md="12" sm="12">
                <Form.Group>
                  <Form.Label>
                    <b> Select IGP Type *</b>
                  </Form.Label>
                  <Select
                    value={{ label: ordersLabel }}
                    placeholder="Select IGP Type"
                    onChange={(e) => handleChangeOrderType(e)}
                    options={getOrderType}
                  />
                </Form.Group>
              </Col>
            </Row>

            {orders === "Partial" && inputField.map((item, i) => {
              return (
                <React.Fragment key={i}>
                  <Row>
                    <Col xl="6" lg="6" md="12" sm="12">
                      <Form.Group>
                        <Form.Label>
                          <b> Select Product *</b>
                        </Form.Label>
                        <Select
                          value={item.label}
                          isDisabled={isEmpty(inventory)}
                          placeholder="Select Product"
                          name="inventoryId"
                          onChange={(e) => handleChange(e, i, "intname")}
                          options={inventory}
                          isOptionDisabled={(option) => !isEmpty(find(inputField, (x) => x.inventoryId == option.value))}
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Quantity </b>
                        </Form.Label>
                        <Form.Control
                          placeholder="Quantity"
                          name="productQuantityCount"
                          value={item.productQuantityCount}
                          disabled
                        />
                      </Form.Group>
                    </Col>

                    <Col xl="3" lg="3" md="6" sm="6">
                      <Form.Group className="mb-3">
                        <Form.Label>
                          <b>Add Quantity *</b>
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Add Quantity"
                          min={0}
                          name="productQuantity"
                          value={item.productQuantity}
                          onChange={(e) => handleChange(e, i, "intquantity", item.inventoryId)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <FormGroup>
                    <Row>
                      <Col lg="12">
                        {inputField.length - 1 == i && (
                          <Button title="Click to Add IGP" onClick={handleAddInput} style={{ float: "right", /* color: "white", */ }} >
                            <AddIcon />
                          </Button>
                        )}

                        {inputField.length !== 1 && (
                          <Button title="Click to Remove IGP" onClick={() => handleRemoveInput(i)} style={{ float: "right", marginRight: "1%",/* color: "white", */ }}>
                            <RemoveIcon />
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </FormGroup>
                </React.Fragment>
              );
            })}

            <div className="sendDiv">
              {isEmpty(purchaseNameValue) || isEmpty(gatePass) || isEmpty(igpDate) || isEmpty(orders) || (orders == "Partial" && inputField[0].inventoryId == "") || (orders == "Partial" && inputField[0].productQuantity == "") ? (
                <Button variant="contained" endIcon={<SendIcon />} size="large" disabled> SAVE </Button>
              ) : (
                <Button variant="contained" endIcon={<SendIcon />} size="large" onClick={uploadIGP} > SAVE </Button>
              )}
            </div>

          </div>
        </Container>
      )}
    </>
  );
}
const mapDispatchToProps = (dispatch) => {
  return {
    getPurchaseApi: () => dispatch(getPurchaseApi()),
    getPurchaseWithIdApi: (id) => dispatch(getPurchaseWithIdApi(id)),
    addIGPApi: (data, handleVisible, handleDangerVisible) => dispatch(addIGPApi(data, handleVisible, handleDangerVisible)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  isAddingIGP: state.IGPReducer.isAddingIGP,
  purchaseList: state.PurchaseReducer.purchaseList,
  purchaseWithIdList: state.PurchaseReducer.purchaseWithIdList,
  isFetchingPurchase: state.PurchaseReducer.isFetchingPurchase,
});
export default connect(mapStateToProps, mapDispatchToProps)(AddIGP);