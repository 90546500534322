const initialState = {
  purchase: [],
  isAddingPurchase: false,
  isFetchingPurchase: false,
  purchaseList: [],
  isFetchingPurchaseWithID: false,
  purchaseWithIdList: [],
};

const PurchaseReducer = (state = initialState, action) => {
  switch (action.type) {

    case "REQUEST_ADD_PURCHASE":
      return { ...state, isAddingPurchase: true };
    case "SUCCESS_ADD_PURCHASE":
      return { ...state, purchase: action.payload, isAddingPurchase: false };
    case "ERROR_ADD_PURCHASE":
      return { ...state, isAddingPurchase: false };

    case "REQUEST_GET_PURCHASE":
      return { ...state, isFetchingPurchase: true };
    case "SUCCESS_GET_PURCHASE":
      return { ...state, purchaseList: action.payload.data, isFetchingPurchase: false, };
    case "ERROR_GET_PURCHASE":
      return { ...state, isFetchingPurchase: false };

    case "REQUEST_GET_PURCHASE_WITH_ID":
      return { ...state, isFetchingPurchaseWithID: true };
    case "SUCCESS_GET_PURCHASE_WITH_ID":
      return { ...state, purchaseWithIdList: action.payload.data, isFetchingPurchaseWithID: false, };
    case "ERROR_GET_PURCHASE_WITH_ID":
      return { ...state, isFetchingPurchaseWithID: false };

    default:
      return state;
  }
};
export default PurchaseReducer;
