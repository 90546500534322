import React, { useEffect, useState } from 'react'
import { Container, Form, Col, Row } from 'react-bootstrap'
import { connect } from "react-redux";
import Swal from 'sweetalert';
import _, { isEmpty } from 'lodash'
import Select from "react-select";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/material/Button';
import { setHeading } from '../../../accounts/account.actions';
import { addProductFile } from './Product.action';
import { getSubCategoryWithCategoryIdApi } from "../subCategory/SubCategory.action";
import { getCategoryWithWarehouseApi } from "../categories/Catagory.action";
import { getWarehouseApi } from "../Warehouse/Warehouse.action";
import { getRestaurantData } from "../AddResturant/Resturant.action";
import { getBrandApi } from "../Brand/Brand.action";


const UploadIssuance = (props) => {
  const [file, setProductFile] = useState();
  const [AlertVisible, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);
  const [retaurantId, setRestaurantId] = useState();
  const [restaurantName, setRestaurantName] = useState();
  const [CategoryId, setCategoryId] = useState();
  const [Categoryname, setCategoryName] = useState();
  const [subCategoryId, setSubCategoryId] = useState();
  const [subCategoryname, setSubCategoryName] = useState()
  const [warehouseId, setWarehouseId] = useState();
  const [warehouseName, setWarehouseName] = useState();
  const [brandId, setBrandId] = useState();
  const [brandName, setBrandName] = useState();


  const handleVisible = (msg) => {
    setAlertVisible(true)
    setTimeout(() => {
      setAlertVisible(false)
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  }
  const handleDangerVisible = (err) => {
    setDangerVisibleAlert(true)
    setTimeout(() => {
      setDangerVisibleAlert(false)
    }, 9000);
    Swal({
      title: "Error",
      text: err,
      icon: "error",
      button: "Ok",
    });
  }

  const handleGenerate = () => {
    props.addProductFile({
      file: file,
    },
      retaurantId,
      CategoryId,
      subCategoryId,
      warehouseId,
      brandId,
      handleVisible,
      handleDangerVisible
    );
    setRestaurantName("")
    setCategoryName("")
    setSubCategoryName("")
    setWarehouseName("")
    setBrandName("")
    setProductFile("")
  };

  const handleChangeRestaurant = (selectedOption) => {
    setRestaurantId(selectedOption.value);
    setRestaurantName(selectedOption.label);
    props.getCategoryWithWarehouseApi(selectedOption.value);
  };
  const getRetaurantList = !_.isEmpty(props.restaurantDataList) && props.restaurantDataList.map((x) => {
    let data = { value: x._id, label: x.restaurantName };
    return data;
  });

  const handleChangeCategory = (selectedOption) => {
    setCategoryId(selectedOption.value);
    setCategoryName(selectedOption.label);
    props.getSubCategoryWithCategoryIdApi(selectedOption.value);
  };
  const getCategoryList = !_.isEmpty(props.categoryListByWarehouseId.data) && props.categoryListByWarehouseId.data.map((x) => {
    let data = { value: x._id, label: x.name };
    return data;
  });

  const handleChangeSubCategory = (selectedOption) => {
    setSubCategoryId(selectedOption.value);
    setSubCategoryName(selectedOption.label);
  };
  const getSubCategoryList = !_.isEmpty(props.subCategoryListByCategoryId) && props.subCategoryListByCategoryId.map((x) => {
    let data = { value: x._id, label: x.subCategoryName };
    return data;
  });

  const handleChangeWarehouse = (selectedOption) => {
    setWarehouseId(selectedOption.value);
    setWarehouseName(selectedOption.label);
  };
  const getWarehouseList = !_.isEmpty(props.warehouseList.data) && props.warehouseList.data.map((x) => {
    let data = { value: x._id, label: x.businessName };
    return data;
  });

  const handleChangeBrand = (selectedOption) => {
    setBrandId(selectedOption.value);
    setBrandName(selectedOption.label);
  };
  const getBrandList = !_.isEmpty(props.brandDataList) && props.brandDataList.map((x) => {
    let data = { value: x._id, label: x.brandName };
    return data;
  });

  useEffect(() => {
    props.getWarehouseApi();
    props.getRestaurantData();
    props.getBrandApi();
    props.setHeading("Upload Product");
  }, []);

  return (
    <>
      <Container fluid>
        <div className="mainHr">
          <Row>
            <Col>
              <Form.Group className="mb-3 mt-3" >
                <Form.Label>
                  <b>Select Company *</b>
                </Form.Label>
                <Select
                  value={{ label: restaurantName }}
                  placeholder="Select Company"
                  onChange={handleChangeRestaurant}
                  options={getRetaurantList}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-3" >
                <Form.Label>
                  <b>Select Category *</b>
                </Form.Label>
                <Select
                  placeholder="Select Category"
                  isDisabled={_.isEmpty(props.categoryListByWarehouseId) || _.isEmpty(retaurantId)}
                  onChange={handleChangeCategory}
                  options={getCategoryList}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3 mt-3" >
                <Form.Label>
                  <b>Select Sub Category *</b>
                </Form.Label>
                <Select
                  placeholder="Select Sub Category"
                  value={{ label: subCategoryname }}
                  isDisabled={_.isEmpty(props.subCategoryListByCategoryId) || _.isEmpty(CategoryId)}
                  onChange={handleChangeSubCategory}
                  options={getSubCategoryList}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Business Location *</b>
                </Form.Label>
                <Select
                  isDisabled={_.isEmpty(props.warehouseList) || _.isEmpty(subCategoryId)}
                  placeholder="Select Business Location"
                  onChange={handleChangeWarehouse}
                  options={getWarehouseList}
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="garana">
                <Form.Label>
                  <b>Select Brand *</b>
                </Form.Label>
                <Select
                  // value={{ label: brandName }}
                  isDisabled={_.isEmpty(props.brandDataList) || _.isEmpty(subCategoryId)}
                  placeholder="Select Brand"
                  onChange={handleChangeBrand}
                  options={getBrandList}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg="12" md="12" xs="12">
              <Form.Group controlId="garana" className="mb-3">
                <Form.Label style={{ fontWeight: 'bold' }}>Upload Product File *</Form.Label>
                <Form.Control
                  type="file"
                  className="form-control"
                  onBlur={(e) => setProductFile(e.target.files[0])}
                // multiple
                />
              </Form.Group>
            </Col>
          </Row>

          <div className="sendDiv">
            {_.isEmpty(retaurantId) || _.isEmpty(CategoryId) || _.isEmpty(subCategoryId) || _.isEmpty(warehouseId) ? (
              <Button variant="contained" endIcon={<CloudUploadIcon />} size="large" disabled> Upload File </Button>
            ) : (
              <Button variant="contained" endIcon={<CloudUploadIcon />} size="large" onClick={() => { handleGenerate(); }}> Upload File </Button>
            )}
          </div>

        </div>
      </Container>
    </>
  )
}
const mapDispatchToProps = (dispatch) => {
  return {
    addProductFile: (file, retaurantId, CategoryId, subCategoryId, warehouseId, brandId, handleVisible, handleDangerVisible) => dispatch(addProductFile(file, retaurantId, CategoryId, subCategoryId, warehouseId, brandId, handleVisible, handleDangerVisible)),
    getWarehouseApi: () => dispatch(getWarehouseApi()),
    getRestaurantData: () => dispatch(getRestaurantData()),
    getCategoryWithWarehouseApi: (id) => dispatch(getCategoryWithWarehouseApi(id)),
    getSubCategoryWithCategoryIdApi: (id) => dispatch(getSubCategoryWithCategoryIdApi(id)),
    getBrandApi: () => dispatch(getBrandApi()),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({
  restaurantDataList: state.ResturantReducer.restaurantDataList,
  categoryListByWarehouseId: state.CategoryReducer.categoryListByWarehouseId,
  isFetchingCategoryByWarehouseId: state.CategoryReducer.isFetchingCategoryByWarehouseId,
  subCategoryListByCategoryId: state.SubCategoryReducer.subCategoryListByCategoryId,
  isFetchingSubCategoryListByCategoryId: state.SubCategoryReducer.isFetchingSubCategoryListByCategoryId,
  warehouseList: state.WarehouseReducer.warehouseList,
  isFetchingWarehouseList: state.WarehouseReducer.isFetchingWarehouseList,
  brandDataList: state.brandReducer.brandDataList,
  isFetchingBrand: state.brandReducer.isFetchingBrand,
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadIssuance);
