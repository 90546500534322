import { filter, isEmpty, toLower } from "lodash";

const initialState = {
  subCategoryList: [],
  subCategory: [],
  isAddingSubCategory: false,
  dataAddedSubCategory: false,
  dataNotAddedSubCategory: false,
  isFetchingSubCategory: false,
  subCategoryListByCategoryId: [],
  isFetchingSubCategoryListByCategoryId: false,
};

const SubCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_ADD_SUB_CATEGORY":
      return {
        ...state,
        isAddingSubCategory: true,
        dataAddedSubCategory: false,
        dataNotAddedSubCategory: false,
      };
    case "SUCCESS_ADD_SUB_CATEGORY":
      return {
        ...state,
        subCategory: action.payload,
        isAddingSubCategory: false,
        dataAddedSubCategory: true,
        dataNotAddedSubCategory: false,
      };
    case "ERROR_ADD_SUB_CATEGORY":
      return {
        ...state,
        isAddingSubCategory: false,
        dataAddedSubCategory: false,
        dataNotAddedSubCategory: true
      };


    case "REQUEST_GET_ALL_SUB_CATEGORY":
      return {
        ...state,
        isFetchingSubCategory: true,
        dataNotAddedSubCategory: false,
      };
    case "SUCCESS_GET_ALL_SUB_CATEGORY":
      return {
        ...state,
        subCategoryList: action.payload,
        isFetchingSubCategory: false,
        dataNotAddedSubCategory: false,
      };
    case "ERROR_GET_ALL_SUB_CATEGORY":
      return {
        ...state,
        isFetchingSubCategory: false,
      };


    case "REQUEST_GET_ALL_SUB_CATEGORY_WITH_CATEGORY_ID":
      return { ...state, isFetchingSubCategoryListByCategoryId: true, };
    case "SUCCESS_GET_ALL_SUB_CATEGORY_WITH_CATEGORY_ID":
      return {
        ...state,
        subCategoryListByCategoryId: action.payload,
        isFetchingSubCategoryListByCategoryId: false,
      };
    case "ERROR_GET_ALL_SUB_CATEGORY_WITH_CATEGORY_ID":
      return { ...state, isFetchingSubCategoryListByCategoryId: false };


    case "DELETE_REQUEST_SUB_CATEGORY":
      return { ...state };
    case "DELETE_SUCCESS_SUB_CATEGORY":
      return {
        ...state,
        subCategoryList: state.subCategoryList.filter(item => item.subCategoryId !== action.subCategoryId)
      };
    case "DELETE_ERROR_SUB_CATEGORY":
      return { ...state };


    case "UPDATE_REQUEST_SUB_CATEGORY":
      return { ...state };
    case "UPDATE_SUCCESS_SUB_CATEGORY":
      return {
        ...state,
        subCategory: state.subCategory.map(x => action.payload.find(({ subCategoryId }) => subCategoryId === x.subCategoryId) || x)
      };
    case "UPDATE_ERROR_SUB_CATEGORY":
      return { ...state };

    case "RESET_SUB_CATEGORY_URL":
      return { ...state, subCategory: [] }

    default:
      return state;
  }
};
export default SubCategoryReducer;