import API from "../../../../../global/api";
import jsPDF from "jspdf";
import logo from "../../../../../../src/Images/logo.png";
import moment from "moment";
import { isEmpty, isNull, sortBy, sumBy } from "lodash";
import { handleNoDataVisible } from "../../../../../global/globalFunctions";
export const requestGetDailySaleReport = () => {
  return {
    type: "REQUEST_GET_DAILY_SALE",
  };
};
export const successGetDailySaleReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_SALE",
    payload: data,
  };
};
export const errorGetDailySaleReport = () => {
  return {
    type: "ERROR_GET_DAILY_SALE",
  };
};

export const getDateWiseSaleReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailySaleReport());
    API.post(`/reports/daily_sale_report`, data)
      .then((res) => {
        let getData = res.data;
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        console.log(getData, getData.data, "check");
        const Dataday = getData.data.length;
        console.log(Dataday, "check2");
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        const title = "Daily Sale Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 60, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 80, 0, 20);
        doc.text(companyNtn, 150, 100, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(11);
        let yAxis = 150;
        console.log(yAxis, "aaaaaaaaaaaaaaa");
        for (let i = 0; i < getData.data.length; i++) {
          const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
          const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
          const cashinhand = getData.data[i].dayOpen.cashInHand;
          const closecash = getData.data[i].dayOpen.closingCash;
          const todaysOrderAmount = getData.data[i].dayOpen.todaysOrderAmount;
          const todaysOrderLength = getData.data[i].dayOpen.todaysOrderLength;
          const dayopenname = getData.data[i].dayOpen.user.firstName;
          const status = getData.data[i].dayOpen.status;
          const headers = [
            [
              "Order No.",
              "Date",
              "Status",
              "Sub Total",
              "Discount",
              "Tax",
              "Grand Total",
            ],
          ];
          // // console.log(getData[0].booking, "pppppp")

          const data = getData.data[i].orders.map((elt, index) => [
            elt.number,
            moment(elt.createdAt).format("YYYY-MM-DD"),
            elt.status,
            elt.subTotal,
            elt.discount,
            Math.round(elt.tax*100)/100
            ,
            elt.grandTotal,
          ]);

          let content = {
            startY: yAxis + 80,
            startX: 10,
            head: headers,
            body: data,
          };

          doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
          doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
          doc.text(
            `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
            marginLeft,
            yAxis + 20,
            0,
            20
          );
          doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
          doc.text(
            `Cash In Hand: ${cashinhand}`,
            marginLeft,
            yAxis + 40,
            0,
            20
          );
          doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
          doc.text(
            `Today Order Amount: ${todaysOrderAmount}`,
            marginLeft,
            yAxis + 60,
            0,
            20
          );
          doc.text(
            `Today Order Length: ${todaysOrderLength}`,
            350,
            yAxis + 60,
            0,
            20
          );

          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 40;
          //doc.addPage();
        }
        doc.setProperties({
          title: `Daily Sale Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });

        doc.save(
          `Daily Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );}
        //  doc.output('dataurlnewwindow')
        dispatch(successGetDailySaleReport(getData));
  })
      .catch((error) => {
        dispatch(errorGetDailySaleReport());
        handleDangerVisible();
      });
  };
};
//Discout
export const requestGetDailyDiscountReport = () => {
  return {
    type: "REQUEST_GET_DAILY_DISCOUNT",
  };
};
export const successGetDailyDiscountReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_DISCOUNT",
    payload: data,
  };
};
export const errorGetDailyDiscountReport = () => {
  return {
    type: "ERROR_GET_DAILY_DISCOUNT",
  };
};

export const getDateWiseDiscountReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailyDiscountReport());
    API.post(`/reports/daily_discount_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        //  doc.setFontSize(13);
        //  //doc.setFont('TimesNewRoman', 'Normal')
        const title = "Daily Discount Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 200, 120);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        let yAxis = 150;
        console.log(yAxis, "aaaaaaaaaaaaaaa");
        for (let i = 0; i < getData.data.length; i++) {
          const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
          const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
          const cashinhand = getData.data[i].dayOpen.cashInHand;
          const closecash = getData.data[i].dayOpen.closingCash;
          const todaysOrderAmount =
            getData.data[i].dayOpen.todaysDiscountAmount;
          const todaysOrderLength =
            getData.data[i].dayOpen.todaysDiscountOrderLength;
          const dayopenname = getData.data[i].dayOpen.user.firstName;
          const status = getData.data[i].dayOpen.status;
          const headers = [
            [
              "Sr",
              "Date",
              "Order No.",
              "Sub Total",
              "Discount",
              "Tax",
              "Grand Total",
            ],
          ];
          // // console.log(getData[0].booking, "pppppp")

          const data = getData.data[i].orders.map((elt, index) => [
            index + 1,
            moment(elt.createdAt).format("YYYY-MM-DD"),
            elt.number,
            elt.subTotal,
            elt.discount,
            elt.tax,
            elt.grandTotal,
          ]);

          let content = {
            startY: yAxis + 80,
            startX: 10,
            head: headers,
            body: data,
          };

          doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
          doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
          doc.text(
            `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
            marginLeft,
            yAxis + 20,
            0,
            20
          );
          doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
          doc.text(
            `Cash In Hand: ${cashinhand}`,
            marginLeft,
            yAxis + 40,
            0,
            20
          );
          doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
          doc.text(
            `Today Discount Amount: ${todaysOrderAmount}`,
            marginLeft,
            yAxis + 60,
            0,
            20
          );
          // doc.text(`Today Order Length: ${todaysOrderLength}`, 350, yAxis+60, 0, 20);

          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 40;
          //doc.addPage();
        }

        doc.setProperties({
          title: `Daily Discount Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Daily Discount Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
       
      }  
      dispatch(successGetDailyDiscountReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetDailyDiscountReport());
        handleDangerVisible();
      });
  };
};
//INVOICE
export const requestGetDailyInvoiceReport = () => {
  return {
    type: "REQUEST_GET_DAILY_INVOICE",
  };
};
export const successGetDailyInvoiceReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_INVOICE",
    payload: data,
  };
};
export const errorGetDailyInvoiceReport = () => {
  return {
    type: "ERROR_GET_DAILY_INVOICE",
  };
};

export const getDateWiseInvoiceReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetDailyInvoiceReport());
    API.post(`/reports/daily_discount_report`, data)
      .then((res) => {
        dispatch(successGetDailyInvoiceReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDailyInvoiceReport());
      });
  };
};
//order Billed

export const requestGetDailyOrderBilledReport = () => {
  return {
    type: "REQUEST_GET_DAILY_ORDER_BILLED",
  };
};
export const successGetDailyOrderBilledReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_ORDER_BILLED",
    payload: data,
  };
};
export const errorGetDailyOrderBilledReport = () => {
  return {
    type: "ERROR_GET_DAILY_ORDER_BILLED",
  };
};

export const getDateWiseOrderBilledReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetDailyOrderBilledReport());
    API.post(`/reports/daily_discount_report`, data)
      .then((res) => {
        dispatch(successGetDailyOrderBilledReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDailyOrderBilledReport());
      });
  };
};
//order cancel
export const requestGetDailyOrderCancelReport = () => {
  return {
    type: "REQUEST_GET_DAILY_ORDER_CANCEL",
  };
};
export const successGetDailyOrderCancelReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_ORDER_CANCEL",
    payload: data,
  };
};
export const errorGetDailyOrderCancelReport = () => {
  return {
    type: "ERROR_GET_DAILY_ORDER_CANCEL",
  };
};

export const getDateWiseOrderCancelReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailyOrderCancelReport());
    API.post(`/reports/daily_order_cancel_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        //  doc.setFontSize(13);
        //  //doc.setFont('TimesNewRoman', 'Normal')
        const title = "Daily Order cancel Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 200, 120);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        let yAxis = 150;
        for (let i = 0; i < getData.data.length; i++) {
          const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
          const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
          const cashinhand = getData.data[i].dayOpen.cashInHand;
          const closecash = getData.data[i].dayOpen.closingCash;
          const todaysOrderAmount =
            getData.data[i].dayOpen.todaysCancelledAmount;
          const todaysOrderLength =
            getData.data[i].dayOpen.todaysCancelledOrderLength;
          const dayopenname = getData.data[i].dayOpen.user.firstName;
          const status = getData.data[i].dayOpen.status;
          const headers = [
            [
              "Sr",
              "Date",
              "Order No.",
              "Sub Total",
              "Discount",
              "Tax",
              "Grand Total",
            ],
          ];
          // // console.log(getData[0].booking, "pppppp")

          const data = getData.data[i].orders.map((elt, index) => [
            index + 1,
            moment(elt.createdAt).format("YYYY-MM-DD"),
            elt.number,
            elt.subTotal,
            elt.discount,
            elt.tax,
            elt.grandTotal,
          ]);

          let content = {
            startY: yAxis + 100,
            startX: 10,
            head: headers,
            body: data,
          };

          doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
          doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
          doc.text(
            `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
            marginLeft,
            yAxis + 20,
            0,
            20
          );
          doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
          doc.text(
            `Cash In Hand: ${cashinhand}`,
            marginLeft,
            yAxis + 40,
            0,
            20
          );
          doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
          doc.text(
            `Today Cancel Order Amount: ${todaysOrderAmount}`,
            marginLeft,
            yAxis + 60,
            0,
            20
          );
          doc.text(
            `Today Cancel Order Length: ${todaysOrderLength}`,
            350,
            yAxis + 60,
            0,
            20
          );

          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 20;
          //doc.addPage();
        }
        doc.setProperties({
          title: `Daily Order cancel Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Daily Order cancel Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
          }
        dispatch(successGetDailyOrderCancelReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDailyOrderCancelReport());
        handleDangerVisible();
      });
  };
};
//promo
export const requestGetDailyPromoReport = () => {
  return {
    type: "REQUEST_GET_DAILY_PROMO",
  };
};
export const successGetDailyPromoReport = (data) => {
  return {
    type: "SUCCESS_GET_DAILY_PROMO",
    payload: data,
  };
};
export const errorGetDailyPromoReport = () => {
  return {
    type: "ERROR_GET_DAILY_PROMO",
  };
};

export const getDailyPromoReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDailyPromoReport());
    API.post(`/reports/daily_promo_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "checkrrr");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);

        //  doc.setFontSize(13);
        //  //doc.setFont('TimesNewRoman', 'Normal')
        const title = "Daily Promo Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.data[0].dayOpen.restaurant.image;
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        let yAxis = 150;
        console.log(yAxis, "aaaaaaaaaaaaaaa");
        for (let i = 0; i < getData.data.length; i++) {
          const Date1 = getData.data[i].dayOpen.createdAt; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
          const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
          const cashinhand = getData.data[i].dayOpen.cashInHand;
          const closecash = getData.data[i].dayOpen.closingCash;
          // const todaysOrderAmount = getData.data[i].dayOpen.todaysOrderAmount;
          // const todaysOrderLength= getData.data[i].dayOpen.todaysOrderLength;
          const dayopenname = getData.data[i].dayOpen.user.firstName;
          const status = getData.data[i].dayOpen.status;
          const headers = [
            [
              "Sr",
              "Date",
              "Order Number",
              "Coupon Name",
              "Coupon Type",
              "Coupon Amount",
            ],
          ];
          // // console.log(getData[0].booking, "pppppp")

          const data = getData.data[i].orders.map((elt, index) => [
            index + 1,
            moment(elt.createdAt).format("YYYY-MM-DD"),
            elt.number,
            elt.coupon.name,
            elt.coupon.type,
            elt.discount,
          ]);

          let content = {
            startY: yAxis + 70,
            startX: 10,
            head: isEmpty(getData.data[i].orders) ? "" : headers,
            body: data,
          };

          doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
          doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
          doc.text(
            `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
            marginLeft,
            yAxis + 20,
            0,
            20
          );
          doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
          doc.text(
            `Cash In Hand: ${cashinhand}`,
            marginLeft,
            yAxis + 40,
            0,
            20
          );
          doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
          //  doc.text(`Today Order Amount: ${todaysOrderAmount}`, marginLeft, yAxis+60, 0, 20);
          //  doc.text(`Today Order Length: ${todaysOrderLength}`, 350, yAxis+60, 0, 20);

          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 40;
          //doc.addPage();
        }

        doc.setProperties({
          title: `Daily Promo Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Daily Promo Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );}
        dispatch(successGetDailyPromoReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetDailyPromoReport());
        handleDangerVisible();
      });
  };
};
//Monthly
//Sale
export const requestGetMonthlySaleReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_SALE",
  };
};
export const successGetMonthlySaleReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_SALE",
    payload: data,
  };
};
export const errorGetMonthlySaleReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_SALE",
  };
};

export const getMonthWiseSaleReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlySaleReport());
    API.post(`/reports/monthly_sale_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Sale Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;

        const Date1 = getData.startDate;
        const Date2 = getData.endDate;
        const restaurant = getData.data[0].restaurant.restaurantName;
        const cashinhand = getData.totalCashInHand;
        const closecash = getData.totalClosingCash;
        const todaysOrderAmount = getData.totalOrdersAmount;
        const todaysOrderLength = getData.totalOrdersLength;
        const logo1 = getData.restaurantImage;
        const headers = [
          [
            "Sr",
            "Day Open Date",
            "Day Open Name",
            "Cash In Hand",
            "Closing Cash",
            "Order Amount",
            "Order Length",
          ],
        ];
        const data = getData.data.map((elt, index) => [
          index + 1,
          moment(elt.dayOpenDate).format("YYYY-MM-DD"),
          elt.user.firstName,
          elt.cashInHand,
          elt.closingCash,
          elt.todaysOrderAmount,
          elt.todaysOrderLength,
        ]);
        let content = {
          startY: 240,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
        //doc.text(`Status: ${status}`, 350, 150, 0, 20);

        doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
        doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
        doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
        doc.text(
          `Total Order Amount: ${todaysOrderAmount}`,
          marginLeft,
          210,
          0,
          20
        );
        doc.text(`Total Order Length: ${todaysOrderLength}`, 350, 210, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
            Date2
          ).format("YYYY-MM-DD")}`,
          185,
          140
        );
        doc.setFontSize(11);
        doc.autoTable(content);
        doc.setProperties({
          title: `Date Wise Sale Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Date Wise Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );
        }
        dispatch(successGetMonthlySaleReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlySaleReport());
        handleDangerVisible();
      });
  };
};
//DISCOUNT
export const requestGetMonthlyDiscountReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_DISCOUNT",
  };
};
export const successGetMonthlyDiscountReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_DISCOUNT",
    payload: data,
  };
};
export const errorGetMonthlyDiscountReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_DISCOUNT",
  };
};

export const getMonthWiseDiscountReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlyDiscountReport());
    API.post(`/reports/monthly_discount_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
        const logo1 = getData.restaurantImage;
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Discount Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const Date1 = getData.startDate;
        const Date2 = getData.endDate;
        const restaurant = getData.data[0].restaurant.restaurantName;
        const cashinhand = getData.totalCashInHand;
        const closecash = getData.totalClosingCash;
        const todaysOrderAmount = getData.totalDiscountAmount;
        const todaysOrderLength = getData.totalDiscountOrderLength;
        const headers = [
          [
            "Sr",
            "Day Open Date",
            "Day Open Name",
            "Cash In Hand",
            "Closing Cash",
            "Discount Amount",
            "No of Discount Orders",
          ],
        ];
        const data = getData.data.map((elt, index) => [
          index + 1,
          moment(elt.dayOpenDate).format("YYYY-MM-DD"),
          elt.user.firstName,
          elt.cashInHand,
          elt.closingCash,
          elt.todaysDiscountAmount,
          elt.todaysDiscountOrderLength,
        ]);
        let content = {
          startY: 220,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
        //doc.text(`Status: ${status}`, 350, 150, 0, 20);
        doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
        //  doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
        //  doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
        doc.text(
          `Total Discount Amount: ${todaysOrderAmount}`,
          marginLeft,
          190,
          0,
          20
        );
        doc.text(
          `Total Discount Length: ${todaysOrderLength}`,
          350,
          190,
          0,
          20
        );
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
            Date2
          ).format("YYYY-MM-DD")}`,
          185,
          140
        );
        doc.setFontSize(11);
        doc.autoTable(content);
        doc.setProperties({
          title: `Date Wise Discount Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Date Wise Discount Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
          }
        dispatch(successGetMonthlyDiscountReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlyDiscountReport());
        handleDangerVisible();
      });
  };
};
//ordercacnel
export const requestGetMonthlyOrderCancelReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_ORDER_CANCEL",
  };
};
export const successGetMonthlyOrderCancelReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_ORDER_CANCEL",
    payload: data,
  };
};
export const errorGetMonthlyOrderCancelReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_ORDER_CANCELT",
  };
};

export const getMonthWiseOrderCancelReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlyOrderCancelReport());
    API.post(`/reports/monthly_order_cancel_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log("abcdefghijklm");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Order Cancel Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const Date1 = getData.startDate;
        const Date2 = getData.endDate;
        const restaurant = getData.data[0].restaurant.restaurantName;
        const cashinhand = getData.data[0].cashInHand;
        const closecash = getData.data[0].closingCash;
        const todaysOrderAmount = getData.totalCanceledOrderAmount;
        const todaysOrderLength = getData.totalCanceledOrderLength;
        const logo1 = getData.restaurantImage;
        const headers = [
          [
            "Sr",
            "Day Open Date",
            "Day Open Name",
            "Cash In Hand",
            "Closing Cash",
            "Order Cancel Amount",
            "No of Order Cancel",
          ],
        ];
        const data = getData.data.map((elt, index) => [
          index + 1,
          moment(elt.dayOpenDate).format("YYYY-MM-DD"),
          elt.user.firstName,
          elt.cashInHand,
          elt.closingCash,
          elt.todaysCancelledAmount,
          elt.todaysCancelledOrderLength,
        ]);
        let content = {
          startY: 240,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
        //doc.text(`Status: ${status}`, 350, 150, 0, 20);
        doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
        // doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
        // doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
        doc.text(
          `Total Cancel Order Amount: ${todaysOrderAmount}`,
          marginLeft,
          190,
          0,
          20
        );
        doc.text(`Total Cancel Order Length: ${todaysOrderLength}`, 350, 190, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
            Date2
          ).format("YYYY-MM-DD")}`,
          185,
          140
        );
        doc.setFontSize(11);
        doc.autoTable(content);
        doc.setProperties({
          title: `Date Wise Order Cancel Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        //  doc.output('dataurlnewwindow')
        doc.save(
          `Date Wise Order Cancel Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
          }
        dispatch(successGetMonthlyOrderCancelReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlyOrderCancelReport());
        handleDangerVisible();
      });
  };
};
//promo
export const requestGetMonthlyPromoReport = () => {
  return {
    type: "REQUEST_GET_MONTHLY_PROMO",
  };
};
export const successGetMonthlyPromoReport = (data) => {
  return {
    type: "SUCCESS_GET_MONTHLY_PROMO",
    payload: data,
  };
};
export const errorGetMonthlyPromoReport = () => {
  return {
    type: "ERROR_GET_MONTHLY_PROMO",
  };
};

export const getMonthWisePromoReport = (data, handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetMonthlyPromoReport());
    API.post(`/reports/monthly_promo_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "check");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Promo Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const Date1 = getData.startDate;
        const Date2 = getData.endDate;
        // const restaurant = getData.data[0].restaurant.restaurantName;
        //const cashinhand = getData.totalCashInHand;
        //const closecash = getData.totalClosingCash;
        const todaysOrderAmount = getData.couponDiscountAmount;
        const todaysOrderLength = getData.couponDiscountLength;
        const logo1 = getData.restaurantImage;
        const headers = [
          ["Order Number", "Order Amount", "Promo Amount", "Order Status"],
        ];
        const data = getData.data.map((elt) => [
          elt.number,
          elt.grandTotal,
          elt.discount,
          elt.status,
        ]);
        let content = {
          startY: 190,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
        //doc.text(`Status: ${status}`, 350, 150, 0, 20);
        // doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
        //  doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
        //  doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
        doc.text(
          `Total Promo Amount: ${todaysOrderAmount}`,
          marginLeft,
          160,
          0,
          20
        );
        doc.text(`Total Promo Length: ${todaysOrderLength}`, 350, 160, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
            Date2
          ).format("YYYY-MM-DD")}`,
          185,
          140
        );
        doc.setFontSize(11);
        doc.autoTable(content);
        doc.setProperties({
          title: `Date Wise Promo Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Date Wise Promo Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
          }
        dispatch(successGetMonthlyPromoReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetMonthlyPromoReport());
        handleDangerVisible();
      });
  };
};
export const requestData = () => {
  return {
    type: "REQUEST_DATA",
  };
};
export const successData = (data) => {
  return {
    type: "SUCCESS_DATA",
    payload: data,
  };
};
export const errorData = () => {
  return {
    type: "ERROR_DATA",
  };
};
export const getData = () => {
  return (dispatch) => {
    dispatch(requestData());
    API.get(`/reports/reports_heading`)
      .then((res) => {
        let data1 = res.data;
        dispatch(successData(data1));
      })
      .catch((error) => {
        dispatch(errorData());
      });
  };
};
//order reprit
export const requestGetOrderPrintReport = () => {
  return {
    type: "REQUEST_GET_ORDER_PRINT",
  };
};
export const successGetOrderPrintReport = (data) => {
  return {
    type: "SUCCESS_GET_ORDER_PRINT",
    payload: data,
  };
};
export const errorGetOrderPrintReport = () => {
  return {
    type: "ERROR_GET_ORDER_PRINT",
  };
};
export const getOrderPdfReport = (id) => {
  return (dispatch) => {
    dispatch(requestGetOrderPrintReport());
    API.get(`/orders/order_pdf/${id}`)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "kkkkkkk");
        if(isEmpty(getData)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Order Reprint";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        const Date1 = getData.orderDate;
        // const Date2 = getData.endDate;
        // const restaurant = getData.data[0].restaurant.restaurantName;
        const DisName = isNull(getData.reprintOrderResult.coupon)
          ? "Null"
          : getData.reprintOrderResult.coupon.name;
        const DiscountAmount = getData.reprintOrderResult.discount;
        //  const closecash = getData.totalClosingCash;
        const totalOrderAmount = getData.reprintOrderResult.grandTotal;
        const Customer = getData.reprintOrderResult.customer.name;
        const headers = [
          [
            "Sr",
            "Product Name",
            "Product Selling Price",
            "Product Quantity",
            "Order Total Amount",
          ],
        ];
        const data = getData.reprintOrderResult.items.map((elt, index) => [
          index + 1,
          elt.productLabel,
          elt.productSellingPrice,
          elt.quantity,
          elt.productTotalAmount,
        ]);
        let content = {
          startY: 240,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
        //doc.text(`Status: ${status}`, 350, 150, 0, 20);
        doc.text(`Customer Name: ${Customer}`, marginLeft, 170, 0, 20);
        doc.text(`Coupon: ${DisName}`, 350, 170, 0, 20);
        doc.text(`Discount Amount: ${DiscountAmount}`, marginLeft, 190, 0, 20);
        doc.text(`Total Order Amount: ${totalOrderAmount}`, 350, 190, 0, 20);
        // doc.text(`Total Order Length: ${todaysOrderLength}`, 350, 210, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(`Date :${moment(Date1).format("YYYY-MM-DD")}`, 210, 140);
        doc.setFontSize(11);
        doc.autoTable(content);
        doc.setProperties({
          title: `Order Reprint${moment(new Date()).format("YYYY-MM-DD")}.pdf`,
        });
        //  doc.output('dataurlnewwindow')
        doc.save(
          `Order Reprint ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );}
        dispatch(successGetOrderPrintReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetOrderPrintReport());
        console.log(error, "error");
      });
  };
};
export const getOrderPrintReport = (id) => {
  return (dispatch) => {
    dispatch(requestGetOrderPrintReport());
    API.get(`/orders/reprint_order/${id}`)
      .then((res) => {
        let getData = res.data;
        dispatch(successGetOrderPrintReport(getData));
      })
      .catch((error) => {
        dispatch(errorGetOrderPrintReport());
        console.log(error, "error");
      });
  };
};
//Specific product sale report
export const requestGetSpecificProductSaleReport = () => {
  return {
    type: "REQUEST_GET_SPECIFIC_PRODUCT_SALE_REPORT",
  };
};
export const successGetSpecificProductSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_SPECIFIC_PRODUCT_SALE_REPORT",
    payload: data,
  };
};
export const errorGetSpecificProductSaleReport = () => {
  return {
    type: "ERROR_GET_SPECIFIC_PRODUCT_SALE_REPORT",
  };
};

export const getSpecificProductSaleReport = (
  data,
  startDate,
  endDate,
  productName,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetSpecificProductSaleReport());
    API.post(`/reports/single_product_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData.data, "abcdefghijklm");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape

        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Specific Product Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const Date1 = startDate;
        const Date2 = endDate;
        const restaurant = getData.data[0].dayOpen.restaurant.restaurantName;
        const logo1 = getData.restaurantImage;
        //  const productNam = productName;

        const TotalProductSale = sumBy(getData.data, "quantity");
        const headers = [
          [
            "Sr",
            "Day Open Date",
            "Day Open Name",
            "Cash In Hand",
            "Closing Cash",
            "Product Sale Quantity",
          ],
        ];
        const data = getData.data.map((elt, index) => [
          index + 1,
          moment(elt.dayOpen.dayOpenDate).format("YYYY-MM-DD"),
          elt.dayOpen.user.firstName,
          elt.dayOpen.cashInHand,
          elt.dayOpen.closingCash,
          elt.quantity,
        ]);
        let content = {
          startY: 240,
          startX: 10,
          head: headers,
          body: data,
        };
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
        doc.text(`Product Name: ${productName}`, marginLeft, 190, 0, 20);
        doc.text(
          `Total Product Sale: ${TotalProductSale}`,
          marginLeft,
          210,
          0,
          20
        );
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 215, 120);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
            Date2
          ).format("YYYY-MM-DD")}`,
          200,
          140
        );
        doc.setFontSize(11);
        doc.autoTable(content);
        doc.setProperties({
          title: `Specific Product Sale Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Specific Product Sale Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`
        );
          }
        dispatch(successGetSpecificProductSaleReport(res.data));
        // handleVisible();
      })
      .catch((error) => {
        console.log(error,"error")
        dispatch(errorGetSpecificProductSaleReport());
        handleDangerVisible();
      });
  };
};
//product sale report
export const requestGetProductSaleReport = () => {
  return {
    type: "REQUEST_GET_PRODUCT_SALE_REPORT",
  };
};
export const successGetProductSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_PRODUCT_SALE_REPORT",
    payload: data,
  };
};
export const errorGetProductSaleReport = () => {
  return {
    type: "ERROR_GET_PRODUCT_SALE_REPORT",
  };
};

export const getProductSaleReport = (
  data,
  startDate,
  endDate,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestGetProductSaleReport());
    API.post(`/reports/all_Products_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "abcdefghijklm");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Product Sale Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 200, 120);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(
          `Date From: ${moment(startDate).format("YYYY-MM-DD")} to ${moment(
            endDate
          ).format("YYYY-MM-DD")}`,
          200,
          140
        );
        let yAxis = 150;
        console.log(yAxis, "aaaaaaaaaaaaaaa");
        for (let i = 0; i < getData.data.length; i++) {
          const Date1 = getData.data[i].dayOpen.dayOpenDate; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
          const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
          const cashinhand = getData.data[i].dayOpen.cashInHand;
          const closecash = getData.data[i].dayOpen.closingCash;
          // const todaysOrderAmount = getData.data[i].dayOpen.todaysDiscountAmount;
          // const todaysOrderLength= getData.data[i].dayOpen.todaysDiscountOrderLength;
          const dayopenname = getData.data[i].dayOpen.user.firstName;
          const status = getData.data[i].dayOpen.status;
          const headers = [
            ["Sr", "Product Name","Product Variation", "Product Quantity", "UOM Name"],
          ];
          // // console.log(getData[0].booking, "pppppp")

          const data = getData.data[i].ProductData.map((elt, index) => [
            index + 1,
            elt.productLabel,
            elt.productVariationLabel,
            elt.quantity,
            elt.uomLabel,
          ]);

          let content = {
            startY: yAxis + 80,
            startX: 10,
            head: headers,
            body: data,
          };

          doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
          doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
          doc.text(
            `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
            marginLeft,
            yAxis + 20,
            0,
            20
          );
          doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
          doc.text(
            `Cash In Hand: ${cashinhand}`,
            marginLeft,
            yAxis + 40,
            0,
            20
          );
          doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
          //  doc.text(`Today Discount Amount: ${todaysOrderAmount}`, marginLeft, yAxis+60, 0, 20);
          // doc.text(`Today Order Length: ${todaysOrderLength}`, 350, yAxis+60, 0, 20);

          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 40;
          //doc.addPage();
        }
        doc.setProperties({
          title: `Product Sale Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Product Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );}
        dispatch(successGetProductSaleReport(res.data));
        // handleVisible();
      })
      .catch((error) => {
        dispatch(errorGetProductSaleReport());
        handleDangerVisible();
      });
  };
};
//hot sellig report
export const requestHotSellingReport = () => {
  return {
    type: "REQUEST_HOT_SELLING_REPORT",
  };
};
export const successHotSellingReport = (data) => {
  return {
    type: "SUCCESS_HOT_SELLING_REPORT",
    payload: data,
  };
};
export const errorHotSellingReport = () => {
  return {
    type: "ERROR_HOT_SELLING_REPORT",
  };
};

export const getHotSellingReport = (
  data,
  handleVisible,
  handleDangerVisible
) => {
  return (dispatch) => {
    dispatch(requestHotSellingReport());
    API.post(`/reports/all_Products_report`, data)
      .then((res) => {
        let getData = res.data;
        console.log(getData, "abcdefghijklm");
        if(isEmpty(getData.data)){
          handleNoDataVisible()
        }
        else{
        const unit = "pt";
        const size = "A4"; // Use A1, A2, A3 or A4
        const orientation = "potrait"; // portrait or landscape
        const marginLeft = 40;
        const doc = new jsPDF(orientation, unit, size);
        const title = "Hot Selling Report";
        const companyName = `${getData.resImage.restaurantName}`;
        const companyAddress = `${getData.resImage.restaurantLocation}`;
        const companyNtn = `${getData.resImage.ntn}`;
        const logo1 = getData.restaurantImage;
        doc.addImage(logo1, "PNG", 40, 15, 100, 100);
        doc.setFontSize(20);
        doc.setFont(undefined, "bold");
        doc.text(companyName, 150, 50, 0, 20);
        doc.setFontSize(13);
        doc.setFont("TimesNewRoman", "Normal");
        doc.text(companyAddress, 150, 70, 0, 20);
        doc.text(companyNtn, 150, 90, 0, 20);
        doc.setFontSize(18);
        doc.setFont("TimesNewRoman", "bold");
        doc.text(title, 200, 120);
        doc.setFontSize(11);
        doc.setFont("TimesNewRoman", "Normal");
        // doc.text(`Date From: ${moment(startDate).format("YYYY-MM-DD")} to ${moment(endDate).format("YYYY-MM-DD")}`, 200,140);
        let yAxis = 150;
        console.log(yAxis, "aaaaaaaaaaaaaaa");
        for (let i = 0; i < getData.data.length; i++) {
          const Date1 = getData.data[i].dayOpen.dayOpenDate; //moment(getData.data[0].createdAt                ).format("YYYY-MM-DD");
          const restaurant = getData.data[i].dayOpen.restaurant.restaurantName;
          const cashinhand = getData.data[i].dayOpen.cashInHand;
          const closecash = getData.data[i].dayOpen.closingCash;
          // const todaysOrderAmount = getData.data[i].dayOpen.todaysDiscountAmount;
          // const todaysOrderLength= getData.data[i].dayOpen.todaysDiscountOrderLength;
          const dayopenname = getData.data[i].dayOpen.user.firstName;
          const status = getData.data[i].dayOpen.status;
          const headers = [
            ["Sr", "Product Name", "Product Quantity", "UOM Name"],
          ];
          // // console.log(getData[0].booking, "pppppp")

          const data1 = sortBy(
            getData.data[i].ProductData,
            "quantity"
          ).reverse();
          console.log(data1, "checksort");
          const data = data1.map((elt, index) => [
            index + 1,
            elt.productLabel,
            elt.quantity,
            elt.uomLabel,
          ]);

          let content = {
            startY: yAxis + 80,
            startX: 10,
            head: headers, 
            body: data, 
          };

          doc.text(`Day Open Name: ${dayopenname}`, marginLeft, yAxis, 0, 20);
          doc.text(`Status: ${status}`, 350, yAxis, 0, 20);
          doc.text(
            `Date: ${moment(Date1).format("YYYY-MM-DD")}`,
            marginLeft,
            yAxis + 20,
            0,
            20
          );
          doc.text(`Restaurant Name: ${restaurant}`, 350, yAxis + 20, 0, 20);
          doc.text(
            `Cash In Hand: ${cashinhand}`,
            marginLeft,
            yAxis + 40,
            0,
            20
          );
          doc.text(`Closing Cash: ${closecash}`, 350, yAxis + 40, 0, 20);
          //  doc.text(`Today Discount Amount: ${todaysOrderAmount}`, marginLeft, yAxis+60, 0, 20);
          // doc.text(`Today Order Length: ${todaysOrderLength}`, 350, yAxis+60, 0, 20);

          doc.autoTable(content);
          yAxis = doc.lastAutoTable.finalY + 40;
          //doc.addPage();
        }
        doc.setProperties({
          title: `Hot Selling Report ${moment(new Date()).format(
            "YYYY-MM-DD"
          )}.pdf`,
        });
        // doc.output("dataurlnewwindow");
        doc.save(
          `Hot Selling Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
        );}
        dispatch(successHotSellingReport(res.data));
        // handleVisible();
      })
      .catch((error) => {
        dispatch(errorHotSellingReport());
        handleDangerVisible();
      });
  };
};
///day open record
export const requestGetDayOpenReport = () => {
  return {
    type: "REQUEST_GET_DAY_OPEN_REPORT",
  };
};
export const successGetDayOpenReport = (data) => {
  return {
    type: "SUCCESS_GET_DAY_OPEN_REPORT",
    payload: data,
  };
};
export const errorGetDayOpenReport = () => {
  return {
    type: "ERROR_GET_DAY_OPEN_REPORT",
  };
};

export const getDayOpenReport = (data) => {
  return (dispatch) => {
    dispatch(requestGetDayOpenReport());
    API.post(`/dayOpen/dayOpen_record`, data)
      .then((res) => {
        dispatch(successGetDayOpenReport(res.data));
      })
      .catch((error) => {
        dispatch(errorGetDayOpenReport());
      });
  };
};
export const requestGetDayOpenSaleReport = () => {
  return {
    type: "REQUEST_GET_DAY_OPEN_SALE",
  };
};
export const successGetDayOpenSaleReport = (data) => {
  return {
    type: "SUCCESS_GET_DAY_OPEN_SALE",
    payload: data,
  };
};
export const errorGetDayOpenSaleReport = () => {
  return {
    type: "ERROR_GET_DAY_OPEN_SALE",
  };
};

export const getDayOpenSaleReport = (handleDangerVisible) => {
  return (dispatch) => {
    dispatch(requestGetDayOpenSaleReport());
    API.get(`/reports/sale_report_without_dayopen`)
    .then((res) => {
      let getData = res.data;
      console.log(getData, "check");
      const unit = "pt";
      const size = "A4"; // Use A1, A2, A3 or A4
      const orientation = "potrait"; // portrait or landscape

      const marginLeft = 40;
      const doc = new jsPDF(orientation, unit, size);
      const title = "Day Open Sale Report";
      const companyName = `${getData.resImage.restaurantName}`;
      const companyAddress = `${getData.resImage.restaurantLocation}`;
      const companyNtn = `${getData.resImage.ntn}`;

      // const Date1 = getData.startDate;
      // const Date2 = getData.endDate;
      // const restaurant = getData.data[0].restaurant.restaurantName;
      // const cashinhand = getData.totalCashInHand;
      // const closecash = getData.totalClosingCash;
      const todaysOrderAmount = getData.data[0].orderTotal;
      const todaysOrderLength = getData.data[0].orderLength;
      const logo1 = getData.restaurantImage;
      const headers = [
        [
          "Sr",
          "Product Name",
          "Quantity",
          "Pricce"
        ],
      ];
      const data = getData.data[0].ProductData.map((elt, index) => [
        index + 1,
        elt.productLabel,
        elt.quantity,
        elt.productSellingPrice * elt.quantity
      ]);
      let content = {
        startY: 200,
        startX: 10,
        head: headers,
        body: data,
      };
      doc.addImage(logo1, "PNG", 40, 15, 100, 100);
      doc.setFontSize(20);
      doc.setFont(undefined, "bold");
      doc.text(companyName, 150, 50, 0, 20);
      doc.setFontSize(13);
      doc.setFont("TimesNewRoman", "Normal");
      doc.text(companyAddress, 150, 70, 0, 20);
      doc.text(companyNtn, 150, 90, 0, 20);
      // doc.text(`Day Open Name: ${dayopenname}`, marginLeft, 150, 0, 20);
      //doc.text(`Status: ${status}`, 350, 150, 0, 20);

      // doc.text(`Restaurant Name: ${restaurant}`, marginLeft, 170, 0, 20);
      // doc.text(`Cash In Hand: ${cashinhand}`, marginLeft, 190, 0, 20);
      // doc.text(`Closing Cash: ${closecash}`, 350, 190, 0, 20);
      doc.text(
        `Total Order Amount: ${todaysOrderAmount}`,
        marginLeft,
        180,
        0,
        20
      );
      doc.text(`No Of Orders: ${todaysOrderLength}`, 350, 180, 0, 20);
      doc.setFontSize(18);
      doc.setFont("TimesNewRoman", "bold");
      doc.text(title, 215, 120);
      doc.setFontSize(13);
      doc.setFont("TimesNewRoman", "Normal");
      // doc.text(
      //   `Date From: ${moment(Date1).format("YYYY-MM-DD")} to ${moment(
      //     Date2
      //   ).format("YYYY-MM-DD")}`,
      //   185,
      //   140
      // );
      doc.setFontSize(11);
      doc.autoTable(content);
      doc.setProperties({
        title: `Day Open Sale Report ${moment(new Date()).format(
          "YYYY-MM-DD"
        )}.pdf`,
      });
      // doc.output("dataurlnewwindow");
      doc.save(
        `Day Open Sale Report ${moment(new Date()).format("YYYY-MM-DD")}.pdf`
      );
        //  doc.output('dataurlnewwindow')
        dispatch(successGetDayOpenSaleReport(getData));
      })
      .catch((error) => {
        console.log(error,"reportError")
        dispatch(errorGetDayOpenSaleReport());
        handleDangerVisible();
      });
  };
};
