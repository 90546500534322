import React, { useState, useEffect } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import Swal from "sweetalert";
import { getBackUp } from "./BackUp.action";
import { setHeading } from "../../accounts/account.actions";


const UserBackUp = (props) => {
  const [visibleAlert, setAlertVisible] = useState(false);
  const [dangerVisibleAlert, setDangerVisibleAlert] = useState(false);

  const handleVisible = (msg) => {
    setAlertVisible(true);
    setTimeout(() => {
      setAlertVisible(false);
    }, 9000);
    Swal({
      title: "Success",
      text: msg,
      icon: "success",
      button: "Ok",
    });
  };
  const handleDangerVisible = () => {
    setDangerVisibleAlert(true);
    setTimeout(() => {
      setDangerVisibleAlert(false);
    }, 9000);
    Swal({
      title: "Error",
      text: "Error",
      icon: "error",
      button: "Ok",
    });
  };

  useEffect(() => {
    props.setHeading("Backup");
  }, []);

  return (
    <>
      <Container fluid>
        <Form>
          <div className="sendDiv" style={{ marginTop: '5%' }}>
            <Button
              style={{ width: '20%' }}
              onClick={() => { props.getBackup(handleVisible, handleDangerVisible) }}
            >
              Back Up
            </Button>
          </div>
        </Form>
      </Container>
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBackup: (handleVisible, handleDangerVisible) => dispatch(getBackUp(handleVisible, handleDangerVisible)),
    setHeading: (data) => dispatch(setHeading(data)),
  };
};
const mapStateToProps = (state, ownProps) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UserBackUp);